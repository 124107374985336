import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILogEventButtonPressed } from 'src/app/core/interfaces/ilog-event-button-pressed';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @Input('base64src') base64src: string;
  @Input('enableSidebar') enableSidebar: boolean = true;
  @Input('enableZoom') enableZoom: boolean = true;
  @Input('enableFind') enableFind: boolean = false;
  @Input('enableOpenFile') enableOpenFile: boolean = false;
  @Input('enableDownload') enableDownload: boolean = false;
  @Input('showDownload') showDownload: boolean = true;
  @Input('enableBookmark') enableBookmark: boolean = false;
  @Input('enableSecondaryToolbar') enableSecondaryToolbar: boolean = false;
  @Input('enablePrint') enablePrint: boolean = false;
  @Input('showPrint') showPrint: boolean = true;
  @Input('enableRotate') enableRotate: boolean = false;

  @Output('logEventButtonPressed') logEventButtonPressed: EventEmitter<ILogEventButtonPressed> = new EventEmitter();

  displayDocument: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  downloadEventEmitter(event_) {
    event_.preventDefault();
    let event: ILogEventButtonPressed = {
      data: null,
      type: 'Download',
      pressed: true
    };
    this.logEventButtonPressed.emit(event);
  }

  printEventEmitter(event_) {
    event_.preventDefault();
    let event: ILogEventButtonPressed = {
      data: null,
      type: 'Print',
      pressed: true
    };
    this.logEventButtonPressed.emit(event);
  }

}
