import { createSelector, createFeatureSelector } from "@ngrx/store";
import { IChatbotState } from "./interfaces/ichatbot-status";


export const selectApplicationState = createFeatureSelector<IChatbotState>('chatbot');

export const getState = createSelector(selectApplicationState, (state: IChatbotState) => state);
export const getStateAvailable = createSelector(selectApplicationState, (state: IChatbotState) => state.active);


