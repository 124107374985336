import { Routes } from "@angular/router";
import { RequestPaymentComponent } from "./pages/request-payment/request-payment.component";

export const FLYWIRE_ROUTES: Routes = [
  {
    path: '',
    data: {
      title: 'Flywire',
    },
    component: RequestPaymentComponent
  }
];
