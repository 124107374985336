import { createReducer, on } from '@ngrx/store';
import { IBillBoxData, IBillingData, IBillingMessages, IBillingState, IRebillingData } from '../interfaces/ibilling-status';
import {
  SET_BILLBOX, SET_BILLBOX_FILTERS, SET_BILLBOX_BILLS, SET_BILLBOX_TRNS_ID, REMOVE_BILLBOX,
  SET_BILLINGDATA, REMOVE_BILLINGDATA, SET_BILLINGDATA_ACTIVE, SET_BILLINGDATA_DATA,
  SET_BILLINGDATA_TRNS_ID,
  SET_REBILLING, SET_REBILLING_ACTIVE, SET_REBILLING_DATA, SET_REBILLING_FOLIO,
  SET_REBILLING_UUID, SET_REBILLING_ADDITIONALDATA, SET_REBILLING_TRNS_ID, REMOVE_REBILLING,
  SET_BILLING_MESSAGE,
  REMOVE_BILLING_MESSAGE
} from './billing.actions';
import { BILLING_STATE } from './billing.state';


const _billingReducer = createReducer(BILLING_STATE,
  on(SET_BILLBOX, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...payload } };
  }),
  on(SET_BILLBOX_FILTERS, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...state.billbox, filters: payload } };
  }),
  on(SET_BILLBOX_BILLS, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...state.billbox, bills: payload } };
  }),
  on(SET_BILLBOX_TRNS_ID, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...state.billbox, transactionId: payload } };
  }),
  on(REMOVE_BILLBOX, (state: IBillingState, { payload }) => {
    return { ...state, billbox: {} as IBillBoxData };
  }),


  on(SET_BILLINGDATA, (state: IBillingState, { payload }) => {
    return { ...state, billingData: { ...payload } };
  }),
  on(SET_BILLINGDATA_DATA, (state: IBillingState, { payload }) => {
    return { ...state, billingData: { ...state.billingData, data: payload } };
  }),
  on(SET_BILLINGDATA_ACTIVE, (state: IBillingState, { payload }) => {
    return { ...state, billingData: { ...state.billingData, active: payload } };
  }),

  on(SET_BILLINGDATA_TRNS_ID, (state: IBillingState, { payload }) => {

    return { ...state, billingData: { ...state.billingData, transactionId: payload } };
  }),
  on(REMOVE_BILLINGDATA, (state: IBillingState, { payload }) => {
    return { ...state, billingData: {} as IBillingData };
  }),


  on(SET_REBILLING, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...payload } };
  }),
  on(SET_REBILLING_FOLIO, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...state.rebilling, folio: payload } };
  }),
  on(SET_REBILLING_UUID, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...state.rebilling, uuid: payload } };
  }),
  on(SET_REBILLING_ACTIVE, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...state.rebilling, active: payload } };
  }),
  on(SET_REBILLING_DATA, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{ ...state.rebilling, data: payload };
    return { ...state, rebilling };
  }),
  on(SET_REBILLING_ADDITIONALDATA, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{ ...state.rebilling, additionalData: payload };
    return { ...state, rebilling };
  }),
  on(SET_REBILLING_TRNS_ID, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{ ...state.rebilling, transactionId: payload };
    return { ...state, rebilling };
  }),
  on(REMOVE_REBILLING, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{};
    return { ...state, rebilling };
  }),

  on(SET_BILLING_MESSAGE, (state: IBillingState, { payload }) => {
    const billingMessages = <IBillingMessages>{ ...payload };
    return { ...state, billingMessages };
  }),
  on(REMOVE_BILLING_MESSAGE, (state: IBillingState, { payload }) => {
    const billingMessages = <IBillingMessages>{};
    return { ...state, billingMessages };
  }),
);

export function BillingReducer(state, action) {
  return _billingReducer(state, action);
}
