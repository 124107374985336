import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { interval } from 'rxjs';
import { ILogEventButtonPressed } from 'src/app/core/interfaces/ilog-event-button-pressed';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ngx-extended-pdf-viewer-noprint',
  templateUrl: './ngx-extended-pdf-viewer-noprint.component.html',
  styleUrls: ['./ngx-extended-pdf-viewer-noprint.component.scss']
})
export class NgxExtendedPdfViewerNoprintComponent implements OnInit {

  @Input('src') src?: any;
  @Input('delayFirstView') delayFirstView?: number;
  @Input('useBrowserLocale') useBrowserLocale?: boolean;
  @Input('showSidebarButton') showSidebarButton?: boolean;
  @Input('showZoomButtons') showZoomButtons?: boolean;
  @Input('showFindButton') showFindButton?: boolean;
  @Input('showOpenFileButton') showOpenFileButton?: boolean;
  @Input('showDownloadButton') showDownloadButton?: boolean;
  @Input('showBookmarkButton') showBookmarkButton?: boolean;
  @Input('showSecondaryToolbarButton') showSecondaryToolbarButton?: boolean;
  @Input('showPrintButton') showPrintButton?: boolean;
  @Input('BlockPrintEvent') BlockPrintEvent?: boolean;
  @Input('height') height?: string;

  @Output('logEventButtonPressed') logEventButtonPressed: EventEmitter<ILogEventButtonPressed> = new EventEmitter();

  PrintIsEnabled: boolean = true;

  constructor() { }

  ngOnInit() {

    if (this.delayFirstView == null)
      this.delayFirstView = 1500;

    const Delay = interval(100).pipe(untilDestroyed(this)).subscribe(() => {
      let Print = document.getElementById("print");
      let Download = document.getElementById("download");
      if (Print !== null && Download !== null) {
        if (!this.showPrintButton)
          Print.parentNode.removeChild(Print);

        if (!this.showDownloadButton)
          Download.parentNode.removeChild(Download)

        setTimeout(() => {
          Delay.unsubscribe();
        }, 100);
      }
    });

    if (this.BlockPrintEvent) {
      setTimeout(() => {
        this.DisablePrintEvent();
      }, this.delayFirstView + 1000);
    }
  }

  EnablePrintEvent() {
    if (!this.PrintIsEnabled) {
      document.getElementById('printContainer').id = 'printContainer2'
      document.getElementById('printContainer3').id = 'printContainer'
      this.PrintIsEnabled = true;
    }
  }

  DisablePrintEvent() {
    if (this.PrintIsEnabled) {
      document.getElementById('printContainer').id = 'printContainer3'
      document.getElementById('printContainer2').id = 'printContainer'
      this.PrintIsEnabled = false;
    }
  }

  onEventButtonPressed(event: ILogEventButtonPressed) {
    console.log('EVENT ARRIVED WITH', event);
    this.logEventButtonPressed.emit(event);
  }

}
