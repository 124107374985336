import { createReducer, on } from '@ngrx/store';
import { DOCUMENT_STATE } from './document.state';
import { IDocumentState } from '../interfaces/idocument-status';
import { SET_DOCUMENT, SET_METADATA, SET_DOCUMENT_ID, SET_DOCUMENT_TYPE, SET_DOCUMENT_REGULATION, SET_DOCUMENTS, SET_SESSION, REMOVE_DOCUMENT } from './document.actions';

const _documentReducer = createReducer(DOCUMENT_STATE,
  on(SET_DOCUMENT, (state: IDocumentState, { payload }) => {
    return { ...payload };
  }),
  on(SET_METADATA, (state: IDocumentState, { payload }) => {
    return { ...state, metadata: { ...payload } };
  }),
  on(SET_DOCUMENT_ID, (state: IDocumentState, { payload }) => {
    return { ...state, idDocument: payload.documentId };
  }),
  on(SET_DOCUMENT_TYPE, (state: IDocumentState, { payload }) => {
    return { ...state, documentType: payload.type };
  }),
  on(SET_DOCUMENT_REGULATION, (state: IDocumentState, { payload }) => {
    return { ...state, regulation: payload };
  }),
  on(SET_DOCUMENTS, (state: IDocumentState, { payload }) => {
    return { ...state, documents: [...payload] };
  }),
  on(SET_SESSION, (state: IDocumentState, { payload }) => {
    return { ...state, idSession: payload.sessionId, sessionActive: payload.sessionActive };
  }),
  on(REMOVE_DOCUMENT, (state: IDocumentState, { payload }) => DOCUMENT_STATE),
);

export function DocumentReducer(state, action) {
  return _documentReducer(state, action);
}
