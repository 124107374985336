import { createAction, props } from '@ngrx/store';
import { ICalculatorResponse } from 'src/app/core/interfaces/early-enrollment/iearly-enrollment';
import { IEarlyEnrollmentState } from '../interfaces/iearly-enrollment-status';

export const SET_ENROLLMENT_DATA = createAction('[EARLYENROLLMENT] set SET_ENROLLMENT_DATA', props<{ payload: IEarlyEnrollmentState }>());

export const SET_ACTIVE = createAction('[EARLYENROLLMENT] set ACTIVE data', props<{ payload: boolean }>());

export const SET_STUDENT_DATA = createAction('[EARLYENROLLMENT] set STUDENT_DATA data', props<{ payload: any }>());

export const SET_SUBJECTS_DATA = createAction('[EARLYENROLLMENT] set SUBJECTS_DATA data', props<{ payload: any }>());

export const SET_PAYMENT_RESUME_DATA = createAction('[EARLYENROLLMENT] set PAYMENT_RESUME_DATA data', props<{ payload: ICalculatorResponse }>());

export const SET_PAYMENT_DATA = createAction('[EARLYENROLLMENT] set PAYMENT_DATA data', props<{ payload: any }>());

export const REMOVE_DATA = createAction('[EARLYENROLLMENT] REMOVE DATA data', props<{ payload: boolean }>());
