import { Injectable } from "@angular/core";
import { Endpoint } from "../../http/models/endpoint/endpoint";
import { AppHttp } from "../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../http/services/endpoints/endpoints.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    notificationEndpoint: {
        echo: Endpoint
    }

    constructor(
        private http: AppHttp,
        private endpoints: EndpointsService,
    ) {
        this.notificationEndpoint = this.endpoints.notification;
    }

    public echo(): Observable<any> {
        return this.http
            .get(this.notificationEndpoint.echo)
            .pipe(map((response) => response.data));
    };

}
