import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationFacade } from 'src/app/core/data/confirmation/facades/confirmation-facade';
import { IConfirmationState } from 'src/app/core/data/confirmation/interfaces/iconfirmation-status';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-confirmation-token-page',
  templateUrl: './confirmation-token-page.component.html',
  styleUrls: ['./confirmation-token-page.component.scss']
})
export class ConfirmationTokenPageComponent extends BaseComponent implements OnInit, OnDestroy {

  confirmationFinished: Subscription;
  accessToken: Subscription;
  timer: NodeJS.Timer;

  constructor(
    private confirmationFacade: ConfirmationFacade
  ) {
    super();
    this.confirmationFinished = this.confirmationFacade.finished$.pipe(untilDestroyed(this)).subscribe((finished: boolean) => {
      if (finished) {
        this.accessToken = confirmationFacade.accessToken$.pipe(untilDestroyed(this)).subscribe(token => {
          this.close();
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.confirmationFinished)
      this.confirmationFinished.unsubscribe();

    if (this.accessToken)
      this.accessToken.unsubscribe();

    clearTimeout(this.timer);
  }

  ngOnInit() {
    this.timer = setTimeout(()=>{
      console.log('ERROR: timeout');
      this.confirmationFacade.setError({
        active: true,
        error: 'Error al validar la contraseña',
        errorDescription: 'No fué posible validar tu contraseña, por favor intenta nuevamente'
      },
      true);
      this.close();
    }, 150000);
  }

  close() {
    window.close();
    window.parent.close();
  }
}
