import { createReducer, on } from '@ngrx/store';
import { ITitleRequestState } from '../interfaces/ititle-request-status';
import {
  REMOVE_DATA,
  SET_KEY_TIMBRADOS_DATA,
  SET_TIMBRADOS_DATA,
  SET_TOKEN_DATA,
} from './title-request.actions';
import { TITLE_REQUEST_STATE } from './title-request.state';

const _titleRequestReducer = createReducer(
  TITLE_REQUEST_STATE,
  on(SET_TOKEN_DATA, (state: ITitleRequestState, { payload }) => ({
    ...state,
    token: payload,
  })),
  on(SET_KEY_TIMBRADOS_DATA, (state: ITitleRequestState, { payload }) => ({
    ...state,
    apiKeyTimbrados: payload,
  })),
  on(SET_TIMBRADOS_DATA, (state: ITitleRequestState, { payload }) => ({
    ...state,
    active: true,
    createdAt:  new Date().getTime(),
    data: payload,
  })),
  on(REMOVE_DATA, (state: ITitleRequestState, { payload }) => ({
    ...TITLE_REQUEST_STATE,
    active: false,
  }))
);

export function TitleRequestReducer(state, action) {
  return _titleRequestReducer(state, action);
}
