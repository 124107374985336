import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/core/services/message/message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-document-viewer-html',
  templateUrl: './document-viewer-html.component.html',
  styleUrls: ['./document-viewer-html.component.scss']
})
export class DocumentViewerHtmlComponent implements OnInit {

  @Input('htmlContent') htmlContent: string;
  @Input('zoom') zoom: boolean = false;
  @Input('index') index: number = 0;
  @Input('message') message: string = '';
  @Input('detailMessage') detailMessage: any[] = [];
  @Output('onFileNotAvaible') onFileNotAvaible: EventEmitter<boolean> = new EventEmitter();

  id: string = 'htmlContent';
  showView: boolean = false;

  timeBeforeRender: number = 1500;
  HtmlElement: HTMLElement;

  ZoomInit = 0.9;

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    console.log(this.detailMessage);
    if (this.htmlContent != null && typeof this.htmlContent !== 'undefined' && this.htmlContent != '') {
      if (this.index) {
        this.id = `${this.id}-${this.index}`;
      }
      this.showView = true;
      setTimeout(() => {
        this.HtmlElement = document.getElementById(this.id);
        console.log(this.HtmlElement);
      }, this.timeBeforeRender + 100);
      return;
    }
    this.messageService.warning(((this.message != null && typeof this.message !== 'undefined' && this.message != '') ? this.message : 'No es posible mostrar el documentos solicitado'),
    this.detailMessage, () => this.onFileNotAvaible.emit(true));
  }

  ZoomInTest() {
    if (this.ZoomInit < 2) {
      this.ZoomInit = this.ZoomInit + 0.1;
      this.HtmlElement.style['zoom'] = '' + this.ZoomInit;
    }
  }

  ZoomOutTest() {
    if (this.ZoomInit > 0.1) {
      this.ZoomInit = this.ZoomInit - 0.1;
      this.HtmlElement.style['zoom'] = '' + this.ZoomInit;
    }
  }

}
