import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentFacade } from './facades/payment-facade';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    PaymentFacade
  ]
})
export class PaymentStoreModule { }
