import { IReenrollmentState } from '../interfaces/ireenrollment-status';

export const REENROLLMENT_STATE: IReenrollmentState = {
    tokenData: '',
    apiKeyCemdi: '',
    paymentData: {},
    paymentResumeData: {},
    studentData: {},
    subjectsData: {},
    active: false
};
