import { createReducer, on } from '@ngrx/store';
import { SET_TITLE, INIT, SET_IP } from "./application.actions";
import { APPLICATION_STATE, IApplicationState } from './application.state';

const _applicationReducer = createReducer(APPLICATION_STATE,
    on(INIT, (state: IApplicationState) => ({ ...state, init: true })),
    on(SET_TITLE, (state: IApplicationState, { payload }) => ({ ...state, title: payload })),
    on(SET_IP, (state: IApplicationState, { payload }) => ({ ...state, ip: payload }))
);

export function ApplicationReducer(state, action) {
    return _applicationReducer(state, action);
}
