import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-waiting-messages-loader',
  templateUrl: './waiting-messages-loader.component.html',
  styleUrls: ['./waiting-messages-loader.component.scss']
})
export class WaitingMessagesLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
