export function isRouteCorrect(documentId: string, currentUrl: string): { isCorrect: boolean, redirect: string } {
  let toReturn = { isCorrect: false, redirect: '' };
  switch (documentId) {
    case 'academic-historial':
    case 'email-template':
    case 'bank-reference':
    case 'document-feature':
      toReturn.isCorrect = true;
      break;
    case 'report-card':
      if (currentUrl.includes('regulation/report-card')) {
        toReturn.isCorrect = true;
      }
      else {
        toReturn.redirect = '/service-window/regulation';
        toReturn.isCorrect = false;
      }
      break;
    default:
      toReturn.isCorrect = true;
      break;
  }

  return toReturn;
}
