import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/core/services/message/message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-document-viewer-word',
  templateUrl: './document-viewer-word.component.html',
  styleUrls: ['./document-viewer-word.component.scss']
})
export class DocumentViewerWordComponent implements OnInit {

  @Input('name') name: string;
  @Input('wordContent') wordContent: string;
  @Input('download') download: boolean;
  @Output('onWordDownloaded') onWordDownloaded: EventEmitter<boolean> = new EventEmitter();
  @Input('message') message: string = '';
  @Input('detailMessage') detailMessage: any[] = [];
  @Output('onFileNotAvaible') onFileNotAvaible: EventEmitter<boolean> = new EventEmitter();

  showView: boolean = false;

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit() {
    console.log(this.detailMessage);
    if (this.wordContent != null && typeof this.wordContent !== 'undefined' && this.wordContent != '') {
      this.showView = true;
      return;
    }
    this.messageService.warning(((this.message != null && typeof this.message !== 'undefined' && this.message != '') ? this.message : 'No es posible mostrar el documentos solicitado')
    , this.detailMessage, () => this.onFileNotAvaible.emit(true));
  }

  downloadWord() {
    this.onWordDownloaded.emit(true);
  }

}
