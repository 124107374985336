import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeAccents'
})
export class RemoveAccentsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!!value) {
      value = value.replace(new RegExp(/[àáâãäåÀÁÂÃÄÅ]/g), 'a');
      value = value.replace(new RegExp(/æ/g), 'ae');
      value = value.replace(new RegExp(/ç/g), 'c');
      value = value.replace(new RegExp(/[èéêëÈÉÊË]/g), 'e');
      value = value.replace(new RegExp(/[ìíîïÌÍÎÏ]/g), 'i');
      value = value.replace(new RegExp(/[òóôõöÒÓÔÕÖ]/g), 'o');
      value = value.replace(new RegExp(/œ/g), 'oe');
      value = value.replace(new RegExp(/[ùúûüÙÚÛÜ]/g), 'u');
      value = value.replace(new RegExp(/[ýÿÝ]/g), 'y');
    }
    return value;
  }

}
