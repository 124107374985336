import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { PermissionsService } from './services/permissions/permissions.service';
import { ServerAvailability } from './services/server-availability/server-availability.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    AuthService,
    PermissionsService,
    ServerAvailability,
  ]
})
export class SessionModule { }
