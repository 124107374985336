import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Modules
 */
import { RouterModule } from '@angular/router';
import { appRoutes } from './app-routing.routes';
import { ImpersonateResolve } from '../data/resolver/impersonate/impersonate.resolve';

/**
 * Services
 */

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, /*{ enableTracing: true }*/),
  ],
  exports: [
    RouterModule
  ],
  providers: [
    ImpersonateResolve
  ]
})
export class AppRoutingModule { }
