import { WildcardHandler } from '../wildcard-handler/wildcard-handler';
import { IEndpointConfig } from '../../interfaces/iendpoint-config';

export const DEFAULT_ENDPOINT_CONFIG: IEndpointConfig = {
  authorization: true,
  confirmation: false,
  cache: 0,
  reenrollmentAuthorization: false,
  reportReenrollmentAuthorization: false,
  timbradosAuthorization: false
};

export class Endpoint {
  private _config: IEndpointConfig;
  private wildcardResolver: WildcardHandler;
  /**
   * @type {{}}
   * @private
   */
  private _wildcardParams: {[name: string]: any} = {};

  /*private params: {[name: string]: any} = {};
  /**
   * Endpoint constructor
   * @param {string} _host
   * @param {string} _path
   * @param {number} _cache ttl seconds
   */
  constructor(
    private _host: string,
    private _path: string,
    private _defaultConfig?: IEndpointConfig,
    ) {
    this._config = { ...DEFAULT_ENDPOINT_CONFIG, ...this._defaultConfig };
    this.wildcardResolver = new WildcardHandler();
  }


  /**
   * host getter
   * @returns {string}
   */
  get host() {
    return this._host;
  }

  /**
   * path getter
   * @returns {string}
   */
  get path() {
    return this._path;
  }

  /**
   * cache getter
   * @returns {boolean}
   */
  get cache() {
    return this._config.cache;
  }

  /**
   * set cache ttl in seconds
   * @param {number} ttl
   */
  setCache(ttl: number) {
    this._config.cache = ttl;
  }


  /**
   * get authorization enabled config
   * @returns {boolean}
   */
  get authorization() {
    return this._config.authorization;
  }

  /**
   * @param {boolean} enable
   */
  setAuthorization(enable: boolean) {
    this._config.authorization = enable;
  }


  /**
   * get confirmation enabled config
   * @returns {boolean}
   */
  get confirmation() {
    return this._config.confirmation;
  }

  /**
   * @param {boolean} enable
   */
  setConfirmation(enable: boolean) {
    this._config.confirmation = enable;
  }

  get reenrollmentAuthorization() {
    return this._config.reenrollmentAuthorization;
  }

  setReenrollmentAuthorization(enable: boolean) {
    this._config.reenrollmentAuthorization = enable;
  }

  get reportReenrollmentAuthorization() {
    return this._config.reportReenrollmentAuthorization;
  }

  setReportReenrollmentAuthorization(enable: boolean) {
    this._config.reportReenrollmentAuthorization = enable;
  }

  get timbradosAuthorization() {
    return this._config.timbradosAuthorization;
  }

  setTimbradosAuthorization(enable: boolean) {
    this._config.timbradosAuthorization = enable;
  }

  get timbradosApiKey() {
    return this._config.timbradosApiKey;
  }

  setTimbradosApiKey(enable: boolean) {
    this._config.timbradosApiKey = enable;
  }

  /**
   * return full url
   * @returns {string}
   */
  get url() {
    const host = `${this.host}`.replace(/\/+$/, '');
    const separator = host.length ? '/' : '';
    const path = `${separator}${this.path}`.replace(/\/{2,}/g, '/');
    return this.wildcardResolver.replaceAllWildcards(`${host}${path}`, this.wildcardParams);
  }

  get wildcardParams() {
    return this._wildcardParams;
  }

  /**
   * Set params to replace wildcards
   * @returns {Endpoint}
   * @param params
   */
  useWildcardParams(params: {[name: string]: any}): Endpoint {
    // tslint:disable-next-line:forin
    for (const key in params) {
      params[key] = encodeURI(params[key]);
    }
    this._wildcardParams = params;
    return this;
  }

  restore() {
    this._wildcardParams = {};
    this._config = { ...DEFAULT_ENDPOINT_CONFIG, ...this._defaultConfig };
  }
}
