import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface IPdfDialogData {
  title: string;
  content: string;
  blob: boolean;
}import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.scss']
})
export class PdfDialogComponent implements OnInit {

  downloadUrl: SafeUrl;
  downloadName: string;

  constructor(
    private dialog: MatDialogRef<PdfDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: IPdfDialogData
  ) { }

  ngOnInit() {
    this.downloadUrl = this.data.blob
      ? this.generateBlobUrl(this.data.content)
      : this.generateSafeUrl(this.data.content);
    this.downloadName = `${this.data.title.replace(' ', '_')}.pdf`;
  }

  generateBlobUrl(data) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  generateSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  close() {
    this.dialog.close();
  }
}
