import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-loading-item',
  templateUrl: './loading-item.component.html',
  styleUrls: ['./loading-item.component.scss']
})
export class LoadingItemComponent implements OnInit {

  loadingMessage: string = 'Descargando datos...'

  loadingMessages: Array<string> = [
    'Descargando datos... por favor espera',
    'Continuamos trabajando en tu solicitud',
    'El proceso esta demorando más de los esperado, por favor espera',
    'Solo un momento más por favor',
    'Continuamos trabajando espera por favor'
  ];

  indexMessage = 0;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.startTimer();
    }, 10000);

  }

  startTimer() {
    this.loadingMessage = this.loadingMessages[this.indexMessage];
    if (this.indexMessage < this.loadingMessages.length) {
      setTimeout(() => {
        this.indexMessage++;
        this.startTimer();
      }, 15000);
    } else {
      setTimeout(() => {
        this.indexMessage = 0;
        this.startTimer();
      }, 15000);
    }
  }

}
