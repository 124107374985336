import { ISupportingDocumentSectionModel, SupportingDocumentSectionTypes } from '../interfaces/isupporting-document-section';
import { ISupportingDocumentParam } from '../interfaces/isupporting-document-param';

export class SupportingDocumentSection implements ISupportingDocumentSectionModel {
  content: string;
  name: string;
  parameters: ISupportingDocumentParam[];
  sectionId: number;
  type: SupportingDocumentSectionTypes;
  order: number;

  hasParam(id: string) {
    return !!this.parameters.find(param => param.parameterId === id);
  }

  isOptional() {
    return this.type.toLowerCase() === SupportingDocumentSectionTypes.optional;
  }

  isFixed() {
    return this.type.toLowerCase() === SupportingDocumentSectionTypes.fixed;
  }

  isExclude() {
    return this.type.toLowerCase() === SupportingDocumentSectionTypes.exclude;
  }
}
