import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {AssetsService} from '../../../../ui/services/assets/assets.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {

  styles: any;
  welcomeMessage$ = this.assets.getWelcomeMessage();
  constructor(
    private dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private assets: AssetsService,
  ) {
  }

  ngOnInit() {
    this.styles = {
      'background-image':  `url('` + this.assets.getWelcomeImageURL() + `')`
    };
  }

  close() {
    this.dialogRef.close();
  }
}
