export function getDocumentIdFunction(documentType: string): number {
  let idDocument = 0;
  switch (documentType) {
    case 'academic-historial':
      idDocument = 2;
      break;
    case 'document-supporting-document':
      idDocument = 1;
      break;
    case 'report-card':
      idDocument = 3;
      break;
    case 'email-template':
      idDocument = 4;
      break;
    case 'bank-reference':
      idDocument = 5;
      break;
    case 'attendance':
      idDocument = 10;
      break;
    default:
      idDocument = 0;
      break;
  }

  return idDocument;
}
