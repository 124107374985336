import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { StringService } from 'src/app/core/services/string/string.service';

@Pipe({
  name: 'safePdf',
})
export class SafePdfPipe implements PipeTransform {

  constructor(private stringService: StringService){}

  transform(base64PDF: string) {
    return this.stringService.base64ToUint8Array(base64PDF);
  }

}