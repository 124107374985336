import { Component, Inject, OnInit } from '@angular/core';
import { CacheService } from '../../../../core/cache/services/cache.service';
import { AuthService } from '../../../../core/session/services/auth/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from '../../../../core/config/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-change-user-page',
  templateUrl: './change-user-page.component.html',
  styleUrls: ['./change-user-page.component.scss']
})
export class ChangeUserPageComponent extends BaseComponent implements OnInit {

  constructor(
    private cacheService: CacheService,
    private authServiceLegacy: AuthService,
    private config: ConfigService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) { 
    super();
  }

  ngOnInit() {
    //this.authServiceLegacy.clearCache();
    this.sessionStorage.clear();
    this.localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    this.cacheService.clearAll();

    setTimeout(() => {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          prompt: 'select_account'
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }, 4000);
  }

}
