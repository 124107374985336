import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IUserState } from "../interfaces/iuser-state";

export const selectUserState = createFeatureSelector<IUserState>('user');

export const getSatate = createSelector(selectUserState, (state: IUserState) => state);

export const getSession = createSelector(selectUserState, (state: IUserState) => state.auth);

export const getQueue = createSelector(selectUserState, (state: IUserState) => state.auth);

export const getProfile = createSelector(selectUserState, (state: IUserState) => state.profile);

export const getPicture = createSelector(selectUserState, (state: IUserState) => state.picture);

export const SSELToken = createSelector(selectUserState, (state: IUserState) => state.SSELToken);
