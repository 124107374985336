import { createReducer, on } from '@ngrx/store';
import { NOTIFICATIONS_STATE } from './notification.state';
import { INotificationState } from '../interfaces/inotification-state';
import { ENABLE, DISABLE, OPEN_CONTAINER, CLOSE_CONTAINER, ADD, REMOVE } from './notification.actions';

const _notificationReducer = createReducer(NOTIFICATIONS_STATE,
    on(ENABLE, (state: INotificationState) => ({ ...state, enabled: true })),
    on(DISABLE, (state: INotificationState) => ({ ...state, enabled: false })),
    on(OPEN_CONTAINER, (state: INotificationState) => ({ ...state, unread: 0, containerIsActive: true })),
    on(CLOSE_CONTAINER, (state: INotificationState) => ({ ...state, unread: 0, containerIsActive: false })),
    on(ADD, (state: INotificationState, { payload }) => {
        const unread = state.containerIsActive ? 0 : (payload.notificationType != 'alert' ? state.unread + 1 : state.unread);
        const notifications = [...state.notifications, payload];
        return { ...state, notifications, unread };
    }),
    on(REMOVE, (state: INotificationState, { payload }) => {
        const notifications = [...state.notifications];
        const index = notifications.indexOf(payload);
        notifications.splice(index, 1);
        return { ...state, notifications };
    })
);

export function NotificationReducer(state, action) {
    return _notificationReducer(state, action);
}