import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { SocialServiceCareer } from '../../interfaces/social-service/social-service-career.interface';
import { SocialServiceSector } from '../../interfaces/social-service/social-service-sector.interface';
import { SocialServiceProgram } from '../../interfaces/social-service/social-service-program.interface';


@Injectable({
  providedIn: 'root'
})
export class SocialServiceService {

  constructor(
    private http: AppHttp,
    private endpointsService: EndpointsService) {
    }
    ngOnInit() {
    }

    getPdfUrl(): string {
      return this.endpointsService.socialService.pdf.url;
    }
    getLogoUrl(): string {
      return this.endpointsService.socialService.logo.url;
    }

    getCareers(): Observable<SocialServiceCareer[]> {
      var observable = new Observable<SocialServiceCareer[]>(subscribe =>{
        this.http.get<IResponse<SocialServiceCareer[]>>(this.endpointsService.socialService.careers)
        .pipe(catchError(response => this.http.handleError(response, 'No fué posible cargar las carreras.')))
        .subscribe((data: SocialServiceCareer[]) => {
          subscribe.next(data);
          subscribe.complete();
        });
      });
      return observable;
    }


    getSectors(): Observable<SocialServiceSector[]> {
        var observable = new Observable<SocialServiceSector[]>(subscribe =>{
          this.http.get<IResponse<SocialServiceSector[]>>(this.endpointsService.socialService.sectors)
          .pipe(catchError(response => this.http.handleError(response, 'No fué posible cargar los sectores.')))
          .subscribe((data: SocialServiceSector[]) => {
            subscribe.next(data);
            subscribe.complete();
          });
        });      
        return observable;
    }
    getPrograms(career, sector): Observable<SocialServiceProgram[]> {
      var observable = new Observable<SocialServiceProgram[]>(subscribe =>{
        this.endpointsService.socialService.programs.useWildcardParams({career: career, sector: sector});
        this.http.get<IResponse<SocialServiceProgram[]>>(this.endpointsService.socialService.programs)
        .subscribe(
          (data: SocialServiceProgram[]) => {
            subscribe.next(this.deserializeFields(data, ['Activities','Approaches','Assistance','Competences','Contactos','Places']));
            subscribe.complete();
          }
          , error => {
            subscribe.next([]);
            subscribe.complete();
          }
        );
      });      
      return observable;
    }

    deserializeFields<Type = Object[]>(list: Type, fieldNames: string[]): Type{
      for(var x in list){
        var object = list[x]
        for(var fieldIndex in fieldNames) {
          var fieldName = fieldNames[fieldIndex];
          if(!object[fieldName] || typeof object[fieldName] !== 'string'){
            continue;
          }
          object[fieldName] = JSON.parse(object[fieldName]);
        }
      }
      return list;
    }
}






