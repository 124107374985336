import { Component, OnInit, Input, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { IProcedureData } from 'src/app/core/data/transactions-engine/interfaces/IProcedureData';
import { MatStepper } from '@angular/material/stepper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html',
  styleUrls: ['./procedures.component.scss']
})
export class ProceduresComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('procedure') procedure?: IProcedureData;
  @ViewChild('stepper') stepper: MatStepper;

  isLinear = true;
  isEditable = false;
  currentTransaction: any = {
    steps: [],
    name: ''
  };

  constructor() { }

  ngOnInit() {
    if (this.procedure != null && typeof this.procedure !== 'undefined') {
      this.currentTransaction.steps = this.procedure.transactionsStatus;
      this.currentTransaction.name = this.procedure.procedureName;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadSteps();
    }, 100);
  }

  loadSteps() {
    let index = 0;
    const steps = this.currentTransaction.steps;

    let LastOrder = 0
    steps.forEach(step => {
      if (step.active) {
        index = step.orderStatus;
      }
      if(LastOrder <= step.orderStatus)
        LastOrder = step.orderStatus;
    });

    if(index == 0)
       index = LastOrder
    else LastOrder = 0;

    let counter = 0;
    do {
      if (this.stepper) {
        this.stepper.next();
      }
      counter = counter + 1;
    } while (counter <= index);
    if (this.stepper) {
      this.stepper._stateChanged();
      //this.stepper.reset();
    }
  }

  ngOnDestroy(): void {
    // console.log('DESTROYING COMPONENT ');
  }

}
