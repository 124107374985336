import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * @Modules
 */
import { AuthModule } from './modules/auth/auth.module';

/**
 * @Components
 */
import { AppComponent } from './app.component';

/**
 * @Services
 */
import { CoreModule } from './core/core.module';


/**
 * @Interceptors
 */
import { AuthInterceptor } from './core/session/interceptors/auth-interceptor';
import { CacheInterceptor } from './core/cache/interceptors/cache-interceptor';
import { LegalModule } from './modules/legal/legal.module';

import { PublicPagesModule } from './modules/public/public-pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlobInterceptor } from './core/session/interceptors/blob-interceptor';
import { IPInterceptor } from './core/session/interceptors/ip-interceptor';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { ConfigService } from './core/config/services/config.service';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './core/utils/auth.factories';
import { HdrInterceptor } from './core/session/interceptors/hdr-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    LegalModule,
    AuthModule,
    PublicPagesModule,
    MsalModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [ConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [ConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IPInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HdrInterceptor, multi: true }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
