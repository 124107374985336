import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleRequestFacade } from './facades/title-request-facade';
import { StoreModule } from '@ngrx/store';
import { TitleRequestReducer } from './redux/title-request.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('titleRequest', TitleRequestReducer),
  ],
  declarations: [],
  providers: [
    TitleRequestFacade
  ]
})
export class TitleRequestStoreModule { }
