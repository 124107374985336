import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { ChangeUserPageComponent } from './pages/change-user-page/change-user-page.component';
import { ConfirmationTokenPageComponent } from './pages/confirmation-token-page/confirmation-token-page.component';
import { MsalGuard } from '@azure/msal-angular';

export const AUTH_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'confirmationToken',
    component: ConfirmationTokenPageComponent,
  },
  {
    path: 'logout',
    canActivate: [MsalGuard],
    data: {
      id: 'logout',
      title: 'Cerrar Sesión',
      navigable: false,
      ssel: { url: 'logout.jsp' }
    },
    component: LogoutPageComponent,
  },
  {
    path: 'changeUser',
    data: {
      id: 'changeUser',
      title: 'Cambiar de usuario',
      navigable: false
    },
    component: ChangeUserPageComponent,
  },
  /*{
    path: 'account-recovery',
    component: PasswordRecoveryComponent
  }*/
];
