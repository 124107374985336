import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'base64ToHtml',
})
export class base64ToHtml implements PipeTransform {

    constructor() { }

    transform(html) {
        console.log(html);
        console.log(atob(html));
        return atob(html);
    }

}