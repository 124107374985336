import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { library } from '@fortawesome/fontawesome-svg-core';
import { IMessageDialogData } from '../../../../core/interfaces/imessage-dialog-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-otp-confirmation',
  templateUrl: './otp-confirmation.component.html',
  styleUrls: ['./otp-confirmation.component.scss']
})
export class OtpConfirmationComponent implements OnInit {

  canChoose: boolean;
  isEmail: boolean;
  isLoadedData: boolean;

  mainLabel: string = '';


  constructor(
    private dialog: MatDialogRef<OtpConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMessageDialogData
  ) { }

  ngOnInit() {
    this.isLoadedData = true;
    this.mainLabel = 'Ingresa el código enviado a tu cuenta de correo *****me@correo.com.mx';
  }



  close() {
    this.dialog.close();
  }
}
