import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserFacade } from '../../user/facades/user-facade';
import { Observable, firstValueFrom } from 'rxjs';
import { IUserData } from '../../user/interfaces/iuser-data';
import { switchMap } from 'rxjs/operators';
import { CatalogueService } from 'src/app/core/services/catalogue/catalogue.service';
import { IValidationCampus } from 'src/app/core/interfaces/ivalidation-campus';
import { DocumentFacade } from '../../document/facades/document-facade';
import { IDocumentState } from '../../document/interfaces/idocument-status';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/core/session/services/auth/auth.service';

@UntilDestroy()
@Injectable()
export class SchoolServiceResolve implements Resolve<any> {

    _urlRedirect: string;
    profile$: Observable<IUserData> = this.userFacde.profile$;


    constructor(
        private router: Router,
        private userFacde: UserFacade,
        private catalogueService: CatalogueService,
        private documentFacade: DocumentFacade,
        private authServiceLegacy: AuthService
    ) {
        
    }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
        
        this.documentFacade.setSession(false, '');
        this.documentFacade.removeDocument();
        return firstValueFrom(this.profile$.pipe(
            switchMap(profile => this.catalogueService.getInformativeValidation(1, profile.id))
        )).then((validation: IValidationCampus) => {
            if (validation && !validation.redirectToSSEL) {
                this.documentFacade.setDocument(<IDocumentState>{
                    active: true,
                    documents: null,
                    idDocument: null,
                    documentType: 'report-card',
                    regulation: { url: validation.fileBlobUrl, avaliable: !!validation.fileBlobUrl },
                    idSession: '',
                    metadata: null,
                    sessionActive: false
                });
                this.router.navigate(['/service-window/regulation/report-card']);
                return true;
            } else {
                return true;
            }
        });
    }




}
