import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TutorServiceService } from '../../../../core/data/user/services/tutor/tutor-service.service';
import { combineLatest, Observable } from 'rxjs';
import { ImpersonateFacade } from '../../../../core/data/impersonate/facades/impersonate-facade';
import { first, map, tap } from 'rxjs/operators';
import { ITutorMe } from 'src/app/core/http/interfaces/itutor';
import { MessageService } from 'src/app/core/services/message/message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-update-tutor-email',
  templateUrl: './update-tutor-email.component.html',
  styleUrls: ['./update-tutor-email.component.scss']
})
export class UpdateTutorEmailComponent implements OnInit, AfterViewInit {

  @Output() checkEmail: EventEmitter<any> = new EventEmitter();

  email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  hasToUpdate: boolean = false;

  profile$: Observable<any> = this.impersonateFacade.backup$
    .pipe(tap(profile => {
      this.email.setValue(profile.personalEmail);
      this.checkValid();
      this.emmitValue();
    }));

  canUpdateEmail$: Observable<boolean> = combineLatest(this.profile$, this.impersonateFacade.isActive$)
    .pipe(map(([profile, impersonated]) => profile.isTutor && impersonated));

  constructor(
    private impersonateFacade: ImpersonateFacade,
    private tutorService: TutorServiceService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.checkValid();
    this.email.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.emmitValue();
    });
  }

  emmitValue() {
    if (this.email.valid) {
      if (this.hasToUpdate) {
        this.checkEmail.emit(true);
      } else {
        this.checkEmail.emit(false);
      }
    } else {
      if (this.hasToUpdate) {
        this.checkEmail.emit(true);
      } else {
        this.checkEmail.emit(false);
      }
    }
  }

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.email.invalid) {
      this.email.updateValueAndValidity();
      return;
    }
    this.submit(this.email.value);
  }

  submit(personalEmail) {
    this.profile$.pipe(first()).pipe(untilDestroyed(this)).subscribe(profile => {
      const iTutorMe = <ITutorMe>{
        id: profile.id,
        personalEmail: personalEmail
      };
      this.tutorService.updateTutorMe(iTutorMe)
        .pipe(untilDestroyed(this)).subscribe(response => {
          this.impersonateFacade.setBackup(response);
          this.checkEmail.emit(false);
          this.hasToUpdate = false;
          this.messageService.success('El email se actualizó exitosamente.')
        });
    });
  }

  checkValid() {
    if (this.email.valid) {
      this.hasToUpdate = false;
    } else {
      this.hasToUpdate = true;
    }
  }
}
