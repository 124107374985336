import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RemoveAccentsPipe } from '../pipes/remove-accents.pipe';

export enum CastTypes {
  uppercase = 'uppercase',
  lowercase = 'lowercase',
  phonenumber = 'phonenumber',
  onlyuppercase = 'onlyuppercase',
  onlylowercase = 'onlylowercase',
}
@Directive({
  selector: '[appInputFormat]'
})
export class InputFormatDirective {

  @Input() appInputFormat: string = CastTypes.uppercase;
  constructor(
    private removeAccentsPipe: RemoveAccentsPipe,
    private ngControl: NgControl
  ) {}

  @HostListener('keyup', ['$event']) onKeyup($event) {
    $event.target.value = this.transform($event.target.value);
    this.ngControl.control.setValue($event.target.value);
  }

  transform(value) {
    value = value.replace(new RegExp(/[^A-Za-zÀ-ÿ0-9_\s@.,ñÑ]/g), '');
    switch (this.appInputFormat) {
      case CastTypes.uppercase: {
        value = this.removeAccentsPipe.transform(value).toUpperCase();
        // value = value.toUpperCase();
        break;
      }
      case CastTypes.lowercase: {
        value = this.removeAccentsPipe.transform(value).toLowerCase();
        // value = value.toLowerCase();
        break;
      }
      case CastTypes.onlylowercase: {
        value = value.toLowerCase();
        // value = value.toLowerCase();
        break;
      }
      case CastTypes.onlyuppercase: {
        value = value.toUpperCase();
        // value = value.toLowerCase();
        break;
      }
      case CastTypes.phonenumber: {
        if (value.toUpperCase() === 'S/N') {
          return 'S/N';
        }

        value = value.replace(new RegExp(/[^0-9]/g), '');
        let newValue = '';

        if (value.length < 10) {
          for (let index = 0; index < value.length; index++) {
            if (index % 2 === 0 && index !== 0) {
              newValue += '-';
            }
            newValue += value.substr(index, 1);
          }
        } else if (value.length < 12) {
          newValue = '(' + value.substr(0, 2) + ') ';
          for (let index = 2; index < value.length; index++) {
            if ((index + 2) % 4 === 0 && index !== 2) {
              newValue += '-';
            }
            newValue += value.substr(index, 1);
          }
        } else {
          newValue = value.substr(0, 2) + ' (' + value.substr(2, 3) + ') ' + value.substr(5, 3) + '-';
          for (let index = 8; index < value.length; index++) {
            if ((index + 8) % 4 === 0 && index !== 8) {
              newValue += '-';
            }
            newValue += value.substr(index, 1);
          }
        }

        value = newValue;
        break;
      }
      default: {}
    }
    return value;
  }
}
