import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IReenrollmentState } from '../interfaces/ireenrollment-status';
import {
  REMOVE_DATA,
  SET_KEY_CEMDI_DATA,
  SET_PAYMENT_DATA,
  SET_PAYMENT_RESUME_DATA,
  SET_STUDENT_DATA,
  SET_SUBJECTS_DATA,
  SET_TOKEN_DATA,
} from '../redux/reenrollment.actions';
import { getState } from '../redux/reenrollment.selectors';

@Injectable()
export class ReenrollmentFacade {
  private state$: Observable<IReenrollmentState> = this.store.select(getState);

  enrollment$: Observable<IReenrollmentState> = this.state$.pipe(
    select((state) => state)
  );
  tokenData$: Observable<string> = this.state$.pipe(
    select((state) => state.tokenData)
  );
  apiKeyCemdi$: Observable<string> = this.state$.pipe(
    select((state) => state.apiKeyCemdi)
  );
  studentData$: Observable<any> = this.state$.pipe(
    select((state) => state.studentData)
  );
  subjectsData$: Observable<any> = this.state$.pipe(
    select((state) => state.subjectsData)
  );
  paymentResumeData$: Observable<any> = this.state$.pipe(
    select((state) => state.paymentResumeData)
  );
  paymentData$: Observable<any> = this.state$.pipe(
    select((state) => state.paymentData)
  );

  constructor(private store: Store<IReenrollmentState>) {}

  tokenData(data: string) {
    this.store.dispatch(SET_TOKEN_DATA({ payload: data }));
  }

  apiKeyCemdi(data: string) {
    this.store.dispatch(SET_KEY_CEMDI_DATA({ payload: data }));
  }

  setStudentData(data: any) {
    this.store.dispatch(SET_STUDENT_DATA({ payload: data }));
  }

  setSubjectData(data: any) {
    this.store.dispatch(SET_SUBJECTS_DATA({ payload: data }));
  }

  setPaymentResumeData(data: any) {
    this.store.dispatch(SET_PAYMENT_RESUME_DATA({ payload: data }));
  }

  setPaymentData(data: any) {
    this.store.dispatch(SET_PAYMENT_DATA({ payload: data }));
  }

  removeData(active: boolean) {
    this.store.dispatch(REMOVE_DATA({ payload: active }));
  }
}
