import { Injectable } from '@angular/core';
import { AppHttp } from '../../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../../http/models/endpoint/endpoint';
import { IResponse } from '../../../../http/interfaces/iresponse';
import { ICampusData } from '../../interfaces/icampus-data';
import { map, catchError, switchMap } from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { DAY } from 'src/app/core/cache/services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class CampusService {

  campusEndpoint: {
    campus: Endpoint,
    campusById: Endpoint,
    saveCampusChanges: Endpoint
  };
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
  ) {
    this.campusEndpoint = this.endpoints.catalogs;
  }

  getCampusList(includeAll: boolean = false, includePrincipal: boolean = false, excludeOnline: boolean = false, enableCache: boolean = true): Observable<ICampusData[]> {
    const query = { includeAll, includePrincipal, excludeOnline };
    const params = this.http.buildQueryParams(query);

    if(enableCache){
      this.campusEndpoint.campus.setCache(DAY);
    }
    
    return this.http.get<IResponse<ICampusData>>(this.campusEndpoint.campus, { params })
      .pipe(map(response => response.data.filter((c: ICampusData) => c.active == true)));
  }


  getCampusById(id: string, allInfo: boolean, includeLSC = false, enableCache: boolean = true): Observable<ICampusData> {
    this.campusEndpoint.campusById.useWildcardParams({id});
    
    if(enableCache){
      this.campusEndpoint.campusById.setCache(DAY);
    }
    
    const query = { allInfo, includeLSC };
    const params = this.http.buildQueryParams(query);
    return this.http.get<IResponse<ICampusData>>(this.campusEndpoint.campusById, {params})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar información del campus')));
  }

  SaveCampusChanges(userId: string , body: any): Observable<any> {
    this.campusEndpoint.saveCampusChanges.useWildcardParams({userId});
    return this.http.patch(this.campusEndpoint.saveCampusChanges, body)
      .pipe(map(response => response))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al guardar la información')));
  }

}
