import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampusService } from './services/campus/campus.service';
import { CareerService } from './services/career/career.service';
import { SchoolService } from './services/school/school.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    CampusService,
    CareerService,
    SchoolService,
  ]
})
export class CatalogsModule { }
