import { Endpoint } from '../endpoint/endpoint';
import { RequestOptionsInterface } from '../../interfaces/request-options-interface';
import { HttpHeaders } from '@angular/common/http';

export const CACHEABLE_HEADER = 'Offline-Cache';

export class RequestOptionsHandler {

  /**
   * Resolve request options using Endpoint configuration
   * @param {Endpoint} endpoint
   * @param {RequestOptionsInterface} options
   * @returns {RequestOptionsInterface}
   */
  static resolve(endpoint: Endpoint, options: RequestOptionsInterface = {}): RequestOptionsInterface {
    options = this.ensureHeaders(options);
    /** Add cacheable header if endpoint response is cachable **/
    options = this.appendCacheableHeader(options, endpoint.cache);
    /** Add Authorization header if endpoint has authorization config as true **/
    options = (endpoint.authorization) ? this.appendAuthorizationHeader(options) : options;
    /** Add Confirmation header if endpoint has authorization config as true **/
    options = (endpoint.confirmation) ? this.appendConfirmationHeader(options) : options;
    options = (endpoint.reenrollmentAuthorization) ? this.appendReenrollmentHeader(options) : options;
    options = (endpoint.reportReenrollmentAuthorization) ? this.appendReportReenrollmentHeader(options) : options;
    options = (endpoint.timbradosAuthorization) ? this.appendTimbradosHeader(options) : options;
    options = (endpoint.timbradosApiKey) ? this.appendTimbradosKeyHeader(options) : options;
    return options;
  }

  /**
   * Add headers property if not exists in options definition
   * @param {RequestOptionsInterface} options
   * @returns {RequestOptionsInterface}
   */
  static ensureHeaders(options: RequestOptionsInterface): RequestOptionsInterface {
    if (!options.hasOwnProperty('headers')) {
      options.headers = new HttpHeaders();
    }
    return options;
  }

  /**
   * Add cacheable headers
   * @param {RequestOptionsInterface} options
   * @param ttl
   * @returns {RequestOptionsInterface}
   */
  static appendCacheableHeader(options: RequestOptionsInterface, ttl: number): RequestOptionsInterface {
    options.headers = !!ttl
      ? options.headers.append(CACHEABLE_HEADER, `${ttl}`)
      : options.headers.append('Cache-Control', 'no-cache');
    return options;
  }

  /**
   * Add Authorization header
   * @param {RequestOptionsInterface} options
   * @returns {RequestOptionsInterface}
   */
  static appendAuthorizationHeader(options: RequestOptionsInterface): RequestOptionsInterface {
    options.headers = options.headers.append('Authorization', '');
    return options;
  }

  /**
   * Add Confirmation header
   * @param {RequestOptionsInterface} options
   * @returns {RequestOptionsInterface}
   */
  static appendConfirmationHeader(options: RequestOptionsInterface): RequestOptionsInterface {
    options.headers = options.headers.append('ConfirmationToken', '');
    return options;
  }

  static appendReenrollmentHeader(options: RequestOptionsInterface): RequestOptionsInterface {
    options.headers = options.headers.append('ReenrollmentToken', '');
    return options;
  }

  static appendReportReenrollmentHeader(options: RequestOptionsInterface): RequestOptionsInterface {
    options.headers = options.headers.append('ReportReenrollmentToken', '');
    return options;
  }
  
  static appendTimbradosHeader(options: RequestOptionsInterface): RequestOptionsInterface {
    options.headers = options.headers.append('TimbradosToken', '');
    return options;
  }

  static appendTimbradosKeyHeader(options: RequestOptionsInterface): RequestOptionsInterface {
    options.headers = options.headers.append('TimbradosKeyToken', '');
    return options;
  }

}
