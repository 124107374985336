import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../../shared/layouts/components/message-dialog/message-dialog.component';
import { IMessageDialogData, MessageIcons, MessageTypes } from '../../interfaces/imessage-dialog-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface ICustomMessageError {
  label: string;
  detailsOpen: boolean;
  redirectToProfile: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private queueSource: BehaviorSubject<IMessageDialogData[]> = new BehaviorSubject([]);
  current: MatDialogRef<MessageDialogComponent>;
  queue$: Observable<IMessageDialogData[]> = this.queueSource.asObservable();

  constructor(private dialog: MatDialog) {
    this.queue$.pipe(filter(queue => !!queue.length)).subscribe(queue => {
      const [firstMessage] = queue.slice(0, 1);
      this.handleMessage(firstMessage);
    });
  }

  getDialogManager() {
    return this.dialog;
  }

  success(message: string, details = {}, continueCallback: Function = null, cancelCallback: Function = null, labels = null, openedDetails: boolean = null) {
    const m = {
      message, details, type: MessageTypes.success, icon: MessageIcons.success,
      actions: { continueCallback, cancelCallback },
      labels: labels,
      detailsOpened: openedDetails
    };
    this.add(m);
  }

  warning(message: string, details = {}, continueCallback: Function = null, cancelCallback: Function = null, labels = null, openedDetails: boolean = null) {
    const m = {
      message, details, type: MessageTypes.warning, icon: MessageIcons.warning,
      actions: { continueCallback, cancelCallback },
      labels: labels,
      detailsOpened: openedDetails
    };
    this.add(m);
  }

  error(message: string, details = {}, continueCallback: Function = null, cancelCallback: Function = null, labels = null, openedDetails: boolean = null) {
    const m = {
      message, details, type: MessageTypes.error, icon: MessageIcons.error,
      actions: { continueCallback, cancelCallback },
      labels: labels,
      detailsOpened: openedDetails
    };
    this.add(m);
  }

  info(message: string, details = {}, continueCallback: Function = null, cancelCallback: Function = null, labels = null, openedDetails: boolean = null) {
    const m = {
      message, details, type: MessageTypes.info, icon: MessageIcons.info,
      actions: { continueCallback, cancelCallback },
      labels: labels,
      detailsOpened: openedDetails
    };
    this.add(m);
  }

  private handleMessage(message: IMessageDialogData) {
    const success = this.dialog.getDialogById(`message-${MessageTypes.success}`);
    const warning = this.dialog.getDialogById(`message-${MessageTypes.warning}`);
    const error = this.dialog.getDialogById(`message-${MessageTypes.error}`);
    const info = this.dialog.getDialogById(`message-${MessageTypes.info}`);
    if (!success && !warning && !error && !info) {
      this.launchMessage(message);
    }
  }

  add(message: IMessageDialogData) {
    this.queueSource.next([...this.queueSource.getValue(), message]);
  }

  remove() {
    this.queueSource.next(this.queueSource.getValue().slice(1));
  }

  clear() {
    this.queueSource.next([]);
  }

  private launchMessage(
    message: IMessageDialogData
  ) {
    this.current = this.dialog.open(MessageDialogComponent, {
      minWidth: '200px',
      maxWidth: '400px',
      disableClose: true,
      autoFocus: false,
      id: `message-${message.type}`,
      data: message,
    });

    this.current.afterClosed().subscribe(() => {
      this.remove();
    });
  }
}

export interface ConfigMessageModel
{
  text: string,
  type: string
}
