import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private title: Subject<string> = new Subject<string>();

  constructor() { }

  public setTitle(title: string) {
    this.title.next(title);
  }

  public getTitle() {
    return this.title;
  }
}
