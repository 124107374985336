import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ITitleRequestState } from '../interfaces/ititle-request-status';
import {
  REMOVE_DATA,
  SET_KEY_TIMBRADOS_DATA,
  SET_TIMBRADOS_DATA,
  SET_TOKEN_DATA,
} from '../redux/title-request.actions';
import { getState } from '../redux/title-request.selectors';
import { ITokenBaseResponse } from 'src/app/modules/service-window/title-request/core/ititle-request';

@Injectable()
export class TitleRequestFacade {
  private state$: Observable<ITitleRequestState> = this.store.select(getState);

  timbrados$: Observable<ITitleRequestState> = this.state$.pipe(
    select((state) => state)
  );
  token$: Observable<string> = this.state$.pipe(
    select((state) => state.token)
  );
  apiKey$: Observable<string> = this.state$.pipe(
    select((state) => state.apiKeyTimbrados)
  );
  data$: Observable<ITokenBaseResponse> = this.state$.pipe(
    select((state) => state.data)
  );

  constructor(private store: Store<ITitleRequestState>) {}

  setToken(data: string) {
    this.store.dispatch(SET_TOKEN_DATA({ payload: data }));
  }

  setApiKey(data: string) {
    this.store.dispatch(SET_KEY_TIMBRADOS_DATA({ payload: data }));
  }

  setData(data: ITokenBaseResponse){
    this.store.dispatch(SET_TIMBRADOS_DATA({ payload: data }));
  }

  clean(active: boolean) {
    this.store.dispatch(REMOVE_DATA({ payload: active }));
  }
}
