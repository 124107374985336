import { Injectable } from "@angular/core";
import { Endpoint } from "../../http/models/endpoint/endpoint";
import { AppHttp } from "../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../http/services/endpoints/endpoints.service";
import { Observable, of } from "rxjs";
import { IValidationCampus } from "../../interfaces/ivalidation-campus";
import { map, catchError } from "rxjs/operators";
import { IBankStatement, ICarouselPaymentModel } from "../../interfaces/ibank-statement";

@Injectable({
  providedIn: 'root'
})
export class DebtsService {

  debtsEndpoints: {
    requestDebts: Endpoint,
    getDebts: Endpoint,
    getServices: Endpoint,
    validateDebts: Endpoint,
    carouselRequest : Endpoint,
    carouselPayment : Endpoint
  }

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
  ) {
    this.debtsEndpoints = this.endpoints.debts;
  }

  requestDebts(profileUserId: string): Observable<string> {
    //return of('asdsadasdasdsa');
    this.debtsEndpoints.requestDebts.useWildcardParams({ profileUserId });
    return this.http.post(this.debtsEndpoints.requestDebts, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible solicitar los adeudos.')));
  }

  carouselRequestStart(profileUserId: string): Observable<string[]> {
    //return of('asdsadasdasdsa');
    this.debtsEndpoints.requestDebts.useWildcardParams({ profileUserId });
    return this.http.post(this.debtsEndpoints.requestDebts, {})
      .pipe(map(response => [response.data, response.additionalData]))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible solicitar los adeudos.')));
  }

  getDebts(profileUserId: string, requestId: string): Observable<IBankStatement> {
    //return this.debtsMock();
    this.debtsEndpoints.getDebts.useWildcardParams({ requestId, profileUserId });
    return this.http.get(this.debtsEndpoints.getDebts)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener los adeudos.')));
  }

  getServices(profileUserId: string, requestId: string): Observable<any>{
    this.debtsEndpoints.getServices.useWildcardParams({ requestId, profileUserId });
    return this.http.get(this.debtsEndpoints.getServices)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener servicios.')));
  }

  validateDebts(requestId: string, profileUserId: string, procedureName = 'bankStatement'): Observable<boolean> {
    //return of(true);
    this.debtsEndpoints.validateDebts.useWildcardParams({ requestId, profileUserId, procedureName });
    return this.http.get(this.debtsEndpoints.validateDebts)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible comprobar el estatus de los adeudos.')));
  }

  carouselRequest(type : string, profileId: string, processId: string, modelRequest : ICarouselPaymentModel) : Observable<string>
  {
    this.debtsEndpoints.carouselRequest.useWildcardParams({ type, profileId, processId });
    return this.http.post(this.debtsEndpoints.carouselRequest,modelRequest)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible realizar orden.')));
  }

  getCarouselPayment(userId, profileUserId, paymentId) : Observable<any>
  {
    this.debtsEndpoints.carouselPayment.useWildcardParams({ paymentId, profileUserId });
    return this.http.get(this.debtsEndpoints.carouselPayment)
    .pipe(map(response => ({ data: response.data, additionalData: response.additionalData })))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }

  debtsMock() {
    return of(<IBankStatement>{
      overduePayment: 20000.0,
      overdueOther: 0.0,
      interesrt: 4520.0,
      overdueingPayment: 0.0,
      overduingOther: 0.0,
      overduingInterest: 0.0,
      overduingTotal: 0.0,
      positiveBalance: 0.0,
      negativeBalance: 24520.0,
      transactionId: "9312182",
      bankReference: "31500000031503073071",
      allowsOnlinePayment: true,
      blockMessage: "",
      notificationMessage: "Los conceptos deben pagarse en el orden mostrado",
      outErrorNumber: 0.0,
      outError: "",
      bankStatementDetail: [{
        sequence: 1,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "144",
        priceDate: "07/10/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "07/10/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 2,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "145",
        priceDate: "16/10/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/10/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 3,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "146",
        priceDate: "21/10/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "21/10/2019",
        unitPrice: 450.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 4,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "147",
        priceDate: "22/10/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "22/10/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 5,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "148",
        priceDate: "31/10/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "31/10/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 6,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "149",
        priceDate: "06/11/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "06/11/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 7,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "150",
        priceDate: "07/11/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "07/11/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 8,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "",
        priceDate: "16/11/2019",
        minAmmount: 2.0,
        maxAmmount: 2.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/11/2019",
        unitPrice: 625.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 9,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "152",
        priceDate: "21/11/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "21/11/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 10,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "153",
        priceDate: "22/11/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "22/11/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 11,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "154",
        priceDate: "01/12/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "01/12/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 12,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "",
        priceDate: "06/12/2019",
        minAmmount: 2.0,
        maxAmmount: 2.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "06/12/2019",
        unitPrice: 350.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 13,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "158",
        priceDate: "07/12/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "07/12/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 14,
        conceptCode: "R038",
        concept: "Cargo Admon de Cuenta",
        priceId: "159",
        priceDate: "16/12/2019",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/12/2019",
        unitPrice: 175.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 15,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "160",
        priceDate: "01/01/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "01/01/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 16,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "161",
        priceDate: "16/01/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/01/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 17,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "162",
        priceDate: "01/02/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "01/02/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 18,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "163",
        priceDate: "16/02/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/02/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 19,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "164",
        priceDate: "01/03/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "01/03/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 20,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "165",
        priceDate: "16/03/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/03/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 21,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "166",
        priceDate: "01/04/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "01/04/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 22,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "167",
        priceDate: "16/04/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/04/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 23,
        conceptCode: "R039",
        concept: "Cargo Admtivo Cobranza",
        priceId: "168",
        priceDate: "16/05/2020",
        minAmmount: 1.0,
        maxAmmount: 1.0,
        groupBy: "RXT",
        cycle: "201935",
        overdueDate: "16/05/2020",
        unitPrice: 130.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 24,
        conceptCode: "B010",
        concept: "FACS  COLEGIATURA",
        priceId: "",
        priceDate: "07/10/2019",
        minAmmount: 5.0,
        maxAmmount: 5.0,
        groupBy: "TUI",
        cycle: "201935",
        overdueDate: "07/10/2019",
        unitPrice: 5000.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 25,
        conceptCode: "B010",
        concept: "FACS  COLEGIATURA",
        priceId: "",
        priceDate: "16/10/2019",
        minAmmount: 5.0,
        maxAmmount: 5.0,
        groupBy: "TUI",
        cycle: "201935",
        overdueDate: "16/10/2019",
        unitPrice: 5000.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 26,
        conceptCode: "B010",
        concept: "FACS  COLEGIATURA",
        priceId: "",
        priceDate: "06/11/2019",
        minAmmount: 5.0,
        maxAmmount: 5.0,
        groupBy: "TUI",
        cycle: "201935",
        overdueDate: "06/11/2019",
        unitPrice: 5000.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }, {
        sequence: 27,
        conceptCode: "B010",
        concept: "FACS  COLEGIATURA",
        priceId: "",
        priceDate: "06/12/2019",
        minAmmount: 5.0,
        maxAmmount: 5.0,
        groupBy: "TUI",
        cycle: "201935",
        overdueDate: "06/12/2019",
        unitPrice: 5000.0,
        paymentAlternative: "1",
        chargeType: "VENCIDO",
        additionalInfo: ""
      }
      ]
    });
  }

}
