import { Routes } from '@angular/router';
import { SocialServiceComponent } from 'src/app/modules/school-services/pages/social-service/pages/social-service.component';


export const SOCIAL_SERVICE: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'start'
  },
  {
    path: 'start',
    component: SocialServiceComponent,
  }
];
