import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationNodeFactory } from './factories/NavigationNodeFactory';
import { NavigationFacade } from './facades/navigation-facade';
import { NavigationService } from './services/navigation.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    NavigationNodeFactory,
    NavigationFacade,
    NavigationService,
  ],
})
export class NavigationModule { }
