import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IApplicationState } from "./application.state";

export const selectApplicationState = createFeatureSelector<IApplicationState>('application');

export const getState = createSelector(selectApplicationState, (state: IApplicationState) => state);

export const getInitialized = createSelector(selectApplicationState, (state: IApplicationState) => state.init);

export const getTitle = createSelector(selectApplicationState, (state: IApplicationState) => state);

