import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../../../../core/config/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingLayoutComponent implements OnInit {

  open: boolean = false;
  constructor(private config: ConfigService) { }

  urlLegacy: string = `${this.config.get('ssel.url')}${this.config.get('ssel.legacyLogin')}`;
  ngOnInit() {
  }

  toggle() {
    this.open = !this.open;
  }

}
