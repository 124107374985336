import { Injectable } from '@angular/core';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor() { }


  resizedataURL(datas, wantedPercentageWidth, wantedPercentageHeight): Promise<string> {

    return new Promise(async function (resolve, reject) {

      // We create an image to receive the Data URI
      const img = document.createElement('img');

      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // We set the dimensions at the wanted size.
        canvas.width = img.naturalWidth * wantedPercentageWidth;
        canvas.height = img.naturalHeight * wantedPercentageHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const dataURI = canvas.toDataURL();

        // This is the return of the Promise
        resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;

    });
  }
}
