import { DocumentParam } from "./document.param";
import { DocTypeEnum, LocationEnum } from "src/app/core/interfaces/generic-document/isection.model";
import { IDocumentSection } from "../interfaces/idocument.section";

export class DocumentSection implements IDocumentSection {
    isFixed: () => boolean;
    isOptional: () => boolean;
    isExclude(): boolean {
        throw new Error("Method not implemented.");
    }
    excludeoptional(): boolean {
        throw new Error("Method not implemented.");
    }
    hasParam: (id: string) => boolean;

    sectionId: string;
    content: string;
    name: string;
    type: DocTypeEnum;
    order: number;
    location: LocationEnum;
    parameters: DocumentParam[];

    getId(): string {
        return this.sectionId;
    }    
    
    getContent(): string {
        return this.content;
    }
    
    getName(): string {
        return this.name;
    }
    
    getType(): string {
        return this.type;
    }
    
    isType(key: string): boolean {
        return this.type === key;
    }
    
    getOrder(): number {
        return this.order;
    }
    
    getLocation(): string {
        return this.location;
    }
    
    isLocation(key: string): boolean {
        return this.location === key;
    }
    
    getParameters(): DocumentParam[] {
        return this.parameters;
    }
    
}