import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log('AuthInterceptor');

    if (req.headers.has('TimbradosToken')) {
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getTimbradosToken().token}`,
        },
      });
      return next.handle(request);
    }
    
    if (req.headers.has('ReenrollmentToken')) {
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getReenrollmentToken().token}`,
        },
      });
      return next.handle(request);
    }
    if (req.headers.has('ReportReenrollmentToken')) {
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getReportReenrollmentToken().token}`,
        },
      });
      return next.handle(request);
    }
    if (!req.headers.has('Authorization')) {
      return next.handle(req);
    }


    /*if (!req.headers.has('ConfirmationToken')) {
      console.log('NO ConfirmationToken');
      console.log(`Bearer ${this.auth.getUserInfo().idToken}`);
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getUserInfo().idToken}`,
        },
      });
      return next.handle(request);
    }*/

    //console.log('ConfirmationToken');
    const request = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getUserInfo().idToken}`,
        ConfirmationToken: `Bearer ${this.auth.getConfirmationToken().token}`,
      },
    });
    return next.handle(request);
  }
  
}
