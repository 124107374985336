import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FLYWIRE_ROUTES } from './flywire.routes';


@NgModule({
  imports: [RouterModule.forChild(FLYWIRE_ROUTES)],
  exports: [RouterModule]
})
export class FlywireRoutingModule { }
