import { Routes } from '@angular/router';
import { RequestBankPaymentNewComponent } from './pages/request-payment/request-bank-payment.v2.component';

export const BANK_BRIDGE_NEW_ROUTES: Routes = [
  {
    path: '',
    data: {
      title: 'Bank Bridge v2',
    },
    component: RequestBankPaymentNewComponent
  }
];
