import { Injectable, OnDestroy } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { IDocumentTypeMetadaModel } from '../../../interfaces/generic-document/idocument-type-data';
import { GenericDocumentService } from '../../../services/generic-document/generic-document.service';
import { GenericDocumentFactory } from '../../document/factories/generic-document-factory';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { UserFacade } from '../../user/facades/user-facade';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { IUserData } from '../../user/interfaces/iuser-data';
import { switchMap } from 'rxjs/operators';
import { DocumentFacade } from '../../document/facades/document-facade';
import { IMetadataDoc } from '../../document/interfaces/idocument-status';
import { isRouteCorrect } from '../functions/is-route-correct.function';
import { getDocumentIdFunction } from '../functions/get-document-id.function';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class GenericDocumentResolve implements Resolve<any>, OnDestroy {

    _urlRedirect: string;
    profile$: Observable<IUserData> = this.userFacde.profile$;
    documentActive: boolean = null;
    activeSubscription: Subscription;

    constructor(
        private genericDocumentFactory: GenericDocumentFactory,
        private genericDocumentService: GenericDocumentService,
        private router: Router,
        private navigationService: NavigationService,
        private userFacde: UserFacade,
        private documentFacade: DocumentFacade
    ) {
        
    }

    ngOnDestroy() {
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
    }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
        const documentTypeParam = route.params['documentType'];
        let documentType = this.resolverDocumentId(documentTypeParam).toString();
        let isCorrect = this.isCorrect(documentTypeParam);
        if (!isCorrect) {
            this.router.navigate([this._urlRedirect, documentTypeParam]);
            return false;
        }

        this.activeSubscription = this.documentFacade.active$.pipe(untilDestroyed(this)).subscribe((active: boolean) => {
            this.documentActive = active;
        });

        return firstValueFrom(this.profile$.pipe(
            switchMap(profile => this.genericDocumentService.getDocumentTypeMetadata(documentType, profile.id))
        )).then((documentData: IDocumentTypeMetadaModel) => {
            if (documentData) {
                //console.log(documentData);
                let metadata: IMetadataDoc = {
                    documentMetadata: null,
                    formData: null,
                    metadata: documentData,
                    orderId: null
                }
                if (this.documentActive) {
                    this.documentFacade.setSession(false, '');
                    this.documentFacade.setDocumentType(documentType);
                    this.documentFacade.setMetadata(metadata);
                    //console.log('En IF');
                } else {
                    this.documentFacade.removeDocument();
                    this.documentFacade.setDocument({
                        active: true,
                        documents: null,
                        idDocument: '',
                        documentType: documentTypeParam,
                        regulation: null,
                        metadata: metadata,
                        idSession: '',
                        sessionActive: false
                    });
                }
                return { metadata: this.genericDocumentFactory.create(documentData), fromCache: false };
            } else {
                console.log('NO documentData');
                this.router.navigate(['/']);
                return false;
            }
        });
    }

    isCorrect(documentId: string): boolean {
        let result: { isCorrect: boolean, redirect: string } = isRouteCorrect(documentId, this.navigationService.getCurrentUrl());
        this._urlRedirect = result.redirect;
        return result.isCorrect;
    }

    resolverDocumentId(documentType: string): number {
        return getDocumentIdFunction(documentType);
    }
}
