import { ISupportingDocumentMetadata, ISupportingDocumentMetadataModel } from '../interfaces/isupporting-document-metadata';
import { SupportingDocumentMetadata } from '../models/supporting-document-metadata';
import { Injectable } from '@angular/core';
import { SectionFactory } from './section-factory';
import { ISupportingDocumentSection } from '../interfaces/isupporting-document-section';

@Injectable()
export class MetadataFactory {

  constructor(
    private sectionFactory: SectionFactory,
  ) {}
  /**
   * @param properties
   */
  create(properties: ISupportingDocumentMetadata<ISupportingDocumentSection>): ISupportingDocumentMetadataModel {
    const metadata = new SupportingDocumentMetadata();
    metadata.documentId = properties.documentId;
    metadata.name = properties.name;
    metadata.description = properties.description;
    metadata.parameters = properties.parameters ? properties.parameters : [];
    metadata.sections = properties.sections.map(section => this.sectionFactory.create(section));
    return metadata;
  }
}
