import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { PaymentPayRoutingModule } from './pay-routing.module';
import { PaymentPayComponent } from './pages/pay/pay.component';
import { RequestPaymentComponent } from './pages/request/request.component';
import { BankBridgeModule } from '../../payment/modules/bank-bridge/bank-bridge.module';
import { PositiveBalanceComponent } from './components/positive-balance/positive-balance.component';
import { CodiModule } from '../../payment/modules/codi/codi.module';
import { BankBridgeNewModule } from '../modules/bank-bridge-v2/bank-bridge.v2.module';
import { FlywireModule } from '../modules/flywire/flywire.module';
import { MercadoPagoModule } from '../modules/mercadopago/mercadopago.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PaymentPayRoutingModule,
    BankBridgeModule,
    CodiModule,
    BankBridgeNewModule,
    FlywireModule,
    MercadoPagoModule
  ],
  declarations: [
      PaymentPayComponent,
      RequestPaymentComponent,
      PositiveBalanceComponent
  ],
  entryComponents: [
  ],
  exports: [
    PaymentPayComponent,
    RequestPaymentComponent
  ],
  providers: [

  ]
})
export class PaymentPayModule { }
