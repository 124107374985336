import { Injectable } from '@angular/core';
import { UserFacade } from '../../data/user/facades/user-facade';
import { NavigationService } from '../../data/navigation/services/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { NavigationFacade } from '../../data/navigation/facades/navigation-facade';
import { DEFAULT_NAVIGATION_CONFIG } from '../../data/navigation/default.menu';
import { SidenavExtendService } from '../../services/sidenav-extend/sidenav-extend.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class NavigationInitializer {

  profile$ = this.userFacade.profile$;
  constructor(
    private userFacade: UserFacade,
    private navigationService: NavigationService,
    private navigationFacade: NavigationFacade,
    private messenger: MessageService,
    private sidenavExtendService: SidenavExtendService
  ) {}

  init() {
    this.profile$.pipe(untilDestroyed(this)).subscribe(profile => this.loadMenu(profile.id));
  }

  loadMenu(userId) {
    this.navigationFacade.setConfig(DEFAULT_NAVIGATION_CONFIG);
    this.navigationService.getMenu(userId)
      .pipe(untilDestroyed(this)).subscribe(
        config => {
          this.navigationFacade.setConfig(config);
          setTimeout(() => {
            this.sidenavExtendService.extend();
          }, 200);
        },
        response => this.handleError(response)
      );
  }

  handleError(response) {
    (response.error && response.error.message)
      ? this.messenger.error(response.error.message, response.error.error)
      : this.messenger.error('Ocurrió un error al intentar cargar el menú del usuario');
  }
}
