
export interface IToken {
    token: string;
}

export interface IErrorInfo {
    active: boolean;
    error: string;
    errorDescription: string;
}

export interface IConfirmationState {
    idToken: IToken;
    accessToken: IToken;
    active: boolean;
    finished: boolean;
    error: IErrorInfo;
    user: string;
}

export enum TypeToken {
    idToken = 1,
    accessToken = 2
}
