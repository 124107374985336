import { ISupportingDocumentMetadataModel } from '../interfaces/isupporting-document-metadata';
import { ISupportingDocumentParam } from '../interfaces/isupporting-document-param';
import { ISupportingDocumentSectionModel } from '../interfaces/isupporting-document-section';

export class SupportingDocumentMetadata implements ISupportingDocumentMetadataModel {
  description: string;
  documentId: string;
  name: string;
  parameters: ISupportingDocumentParam[] = [];
  sections: ISupportingDocumentSectionModel[] = [];

  hasParam(id: string) {
   return !!this.param(id);
  }

  param(id) {
    return this.parameters.find(param => param.parameterId === id);
  }

}
