import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { BankBridgeRoutingModule } from './bank-bridge-routing.module';
import { RequestBankPaymentComponent } from './pages/request-payment/request-bank-payment.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BankBridgeRoutingModule
  ],
  declarations: [
    RequestBankPaymentComponent
  ],
  entryComponents: [
  ],
  exports: [
    RequestBankPaymentComponent
  ]
})
export class BankBridgeModule { }
