import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/services/config.service';
import { ScriptElementModel } from '../../interfaces/icreate-script';

@Injectable({
  providedIn: 'root'
})
export class CreateElementsService {

  private Script: ScriptElementModel;
  private loadAPI: Promise<any>;

  constructor(
    private configService: ConfigService
  ) { }

  LoadScript(script: ScriptElementModel) {
    this.Script = script;
    var existingScript = document.querySelector("script[id*='" + this.Script.id + "']");
    if (existingScript) {
      console.log(`Script already exist ${this.Script.id}`);
      return;
    }

    let scriptSrc = this.configService.get(this.Script.src);
    if (this.Script.timestampQuery) {
      let qs = window.btoa(`${new Date()}`);
      scriptSrc = `${scriptSrc}?v=${qs}`;
    }

    let node = document.createElement('script');
    node.src = scriptSrc;
    node.type = 'text/javascript';
    node.async = true;
    node.setAttribute('id', this.Script.id)
    this.Script.attributes.forEach(data => {
      node.setAttribute(data.attribute, data.value)
    });
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  removeElement(id: string, type: string) {
    const element = document.getElementById(id);
    if (element != null) {
      element.parentNode.removeChild(element);
    }
  }
}
