import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarlyEnrollmentFacade } from './facades/early-enrollment-facade';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    EarlyEnrollmentFacade
  ]
})
export class EarlyEnrollmentStoreModule { }
