import { IParamTypes } from "src/app/core/interfaces/generic-document/iparam.model";
import { IDocumentParam } from "../interfaces/idocument.param";
import { IDocumentParamData } from "../interfaces/idocument.param.data";
import { IDocumentPrice } from "../interfaces/idocument.prices";

export class DocumentParam implements IDocumentParam {

  prices: IDocumentPrice[] = [];
  parameterId: string;
  name: string;
  required: boolean;
  type: IParamTypes;
  data: any;


  getId(): string {
    return this.parameterId;
  }

  getName(): string {
    return this.name;
  }

  isRequired(): boolean {
    return !!this.required;
  }

  getType(): IParamTypes {
    return this.type;
  }

  existData(): boolean {
    return this.data != null && typeof this.data !== 'undefined';
  }

  getData(): IDocumentParamData[] | any {
    return this.existData() ? this.data : null;
  }

  addPrice(price: IDocumentPrice): void {
    this.prices.push(price);
    this.setParamPrice(price);
  }

  setPrices(prices: IDocumentPrice[]): void {
    this.prices = prices;
    prices.forEach(p => {
      this.setParamPrice(p);
    });
  }

  hasPrices() {
    return this.prices != null && typeof this.prices !== 'undefined';
  }

  private setParamPrice(price: IDocumentPrice) {
    if (this.existData()) {
      if (typeof (<Array<any>>this.data).find(p => p['id'] == price['id']) !== 'undefined') {
        let aux: any[] = JSON.parse(JSON.stringify(this.data));
        aux.find((p: any) => p.id === price.id)['price'] = price.price;
        this.data = aux;
      }
    }

  }

}
