import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestCodiPaymentComponent } from './components/request-codi-payment/request-codi-payment.component';
import { WaitingMessagesLoaderComponent } from './components/waiting-messages-loader/waiting-messages-loader.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    RequestCodiPaymentComponent,
    WaitingMessagesLoaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    RequestCodiPaymentComponent
  ]
})
export class CodiModule { }
