import { RequiredWildcardParamException } from '../../../exceptions/http/required-wildcard-param-exception';

export class WildcardHandler {
  private readonly requiredPattern: RegExp;
  private readonly optionalPattern: RegExp;

  constructor() {
    this.requiredPattern = /{[a-zA-z0-9]+}/gm;
    this.optionalPattern = /{[a-zA-z0-9]+\?}/gm;
  }

  /**
   * Return array matches using a regexp
   * @param {RegExp} needle
   * @param {string} haystack
   * @param {boolean} firstMatch
   * @returns {string[]}
   */
  static getMatches(needle: RegExp, haystack: string, firstMatch: boolean = false): string[] {
    const matches = [];
    let match;
    do {
      match = needle.exec(haystack);
      if (match) {
        matches.push(...match);
      }
      if (firstMatch) {
        match = false;
      }
    } while (match);
    return matches;
  }

  /**
   * Return first match using a regexp
   * @param {RegExp} needle
   * @param {string} haystack
   * @returns {string}
   */
  static getMatch(needle: RegExp, haystack: string): string {
    return WildcardHandler.getMatches(needle, haystack, true).shift();
  }

  /**
   * Return all Required wildcard names from a string
   * @param {string} haystack
   * @returns {string[]}
   */
  getRequiredWildcards(haystack: string): string[] {
    return WildcardHandler.getMatches(this.requiredPattern, haystack);
  }

  /**
   * Return all optional wildcards from a string
   * @param {string} haystack
   * @returns {string[]}
   */
  getOptionalWildcards(haystack: string): string[] {
    return WildcardHandler.getMatches(this.optionalPattern, haystack);
  }

  /**
   * Replace optional wildcard from a string if exists in given params
   * else remove wildcards
   * @param {string} target
   * @param params
   * @returns {string}
   */
  replaceOptionalWildcards(target: string, params: {[name: string]: any}) {
    const optional = this.getOptionalWildcards(target);
    optional.forEach((wildcard) => {
      const name = WildcardHandler.getMatch(/[a-zA-Z0-9]+/, wildcard);
      const value = params.hasOwnProperty(name) ? params[name] : '';
      target = target.replace(wildcard, <string>value);
    });
    return target;
  }

  /**
   * Replace required wildcards from a string if exists in given params
   * else throw a RequiredWildcardParamException
   * @param {string} target
   * @param params
   * @returns {string}
   * @throws RequiredWildcardParamException
   */
  replaceRequiredWildcards(target: string, params: {[name: string]: any}) {
    const optional = this.getRequiredWildcards(target);
    optional.forEach((wildcard) => {
      const name = WildcardHandler.getMatch(/[a-zA-Z0-9]+/, wildcard);
      if (!params.hasOwnProperty(name)) {
        throw new RequiredWildcardParamException();
      }
      target = target.replace(wildcard, <string>params[name]);
    });
    return target;
  }

  /**
   *
   * @param {string} target
   * @param params
   * @returns {string}
   */
  replaceAllWildcards(target: string, params: {[name: string]: any}): string {
    target = this.replaceRequiredWildcards(target, params);
    target = this.replaceOptionalWildcards(target, params);
    return target;
  }
}
