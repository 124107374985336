import { createAction, props } from '@ngrx/store';
import { INotificationData } from '../interfaces/inotification-data';

export const ENABLE = createAction('[NOTIFICATION] enable');

export const DISABLE = createAction('[NOTIFICATION] disable');

export const OPEN_CONTAINER = createAction('[NOTIFICATION] open container');

export const CLOSE_CONTAINER = createAction('[NOTIFICATION] close container');

export const ADD = createAction('[NOTIFICATION] add', props<{ payload: INotificationData }>());

export const REMOVE = createAction('[NOTIFICATION] remove', props<{ payload: INotificationData }>());