import { Injectable } from '@angular/core';
import { AppHttp } from '../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../http/models/endpoint/endpoint';
import { ConfigService } from '../../../config/services/config.service';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  protected permissionEndpoint: Endpoint;

  constructor(
    private config: ConfigService,
    private http: AppHttp,
    private endpoints: EndpointsService
  ) {
    this.permissionEndpoint = this.endpoints.account.permissions;
  }

  getPermissions(schoolId: string) {
    this.permissionEndpoint.useWildcardParams({ schoolId });
    return this.http.get(this.permissionEndpoint).pipe(retry(1));
  }
}
