import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';

import { SupportingDocumentService } from 'src/app/core/data/supporting-documents/services/supporting-document.service';
import { OrderDetail } from '../../interfaces/order-detail-interface';
import { StringService } from 'src/app/core/services/string/string.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-constance-order-detail',
  templateUrl: './constance-order-detail.component.html',
  styleUrls: ['./constance-order-detail.component.scss']
})
export class ConstanceOrderDetailComponent extends BaseComponent implements OnInit {

  @ViewChild('HtmlContainer') HtmlContainer: ElementRef;

  TransactionId: string;
  captchaString: string;
  ShowOrder: boolean = false;
  NotFound: boolean = false;
  OrderDetail$: Observable<any>;
  Data: OrderDetail;
  Loading: boolean;
  timeBeforeRender: number = 1500;
  HtmlElement: HTMLElement;

  ZoomInit = 0.9;


  HtmlData: string = null;
  PdfData: Uint8Array = null;
  base64src: string;

  captcha = {
    lang: 'es',
    size: 'Normal',
    theme: 'ligth',
    type: 'image',
  };

  constructor(private route: ActivatedRoute,
              private ConstanceService: SupportingDocumentService,
              private stringService: StringService) { 
                super();
              }

  ngOnInit() {
    this.TransactionId = encodeURIComponent(decodeURIComponent(this.route.snapshot.queryParamMap.get('t')));
  }

  GetOrderDetail() {
    this.Loading = true;
    this.OrderDetail$ = this.ConstanceService.getPublicOrderDetail(this.TransactionId, this.captchaString);
    firstValueFrom(this.OrderDetail$).then(OrderData => {
      if(OrderData.data !== '' && OrderData.data !== null){
          this.Data = JSON.parse(OrderData.data);
          this.ShowOrder = true;
          if (!this.Data.isExpired) {
            this.RenderData(this.Data.HtmlSource, this.Data.PdfSource);
          }
      }
      this.Loading = false;
    }).catch(x => { this.NotFound = true; this.Loading = false; });
  }

  handleCaptchaExpire() {}

  handleCaptchaLoad() {}

  handleCaptchaSuccess(event) {
    this.captchaString = event;
    this.GetOrderDetail();
  }

  RenderData(Html: string, PdfBase64: string) {
    if (Html !== null) {
      this.HtmlData = Html.replace('div', 'section');
    }

    if (PdfBase64 !== null) {
      this.base64src = PdfBase64;
      this.PdfData = this.stringService.base64ToUint8Array(PdfBase64);
    }

    setTimeout(() => {
      this.HtmlElement = document.getElementById('htmlContent');
      console.log(this.HtmlElement);
    }, this.timeBeforeRender + 100);
  }

  ZoomInTest() {
    if (this.ZoomInit < 2) {
      this.ZoomInit = this.ZoomInit + 0.1;
      this.HtmlElement.style['zoom'] = '' + this.ZoomInit;
    }
  }

  ZoomOutTest() {
    if (this.ZoomInit > 0.1) {
      this.ZoomInit = this.ZoomInit - 0.1;
      this.HtmlElement.style['zoom'] = '' + this.ZoomInit;
    }
  }
}
