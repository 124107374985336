import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { ISchoolFile } from "../../interfaces/iinformative-files.interface";
import { FormControl } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export const schoolForAll = "TODAS";

@UntilDestroy()
@Component({
	selector: "app-files-school",
	templateUrl: "./files-school.component.html",
	styleUrls: ["./files-school.component.scss"],
})
export class FilesSchoolComponent implements OnInit, OnChanges {
	/**
	 * @param withSelectAll
	 * @type boolean
	 * @default false
	 * Parametro para indicar si se debe mostrar la opción seleccionar todo
	 */
	@Input("withSelectAll") withSelectAll: boolean = false;

	/**
	 * @param withExcluded
	 * @type boolean
	 * @default false
	 * Parametro para indicar si se debe bloquear la seleccion de escuelas excluidos
	 */
	@Input("withExcluded") withExcluded: boolean = false;
	/**
	 * @param schoolExcluded
	 * @type string[]
	 * @default []
	 * Lista de escuelas que no se pueden seleccionar
	 */
	@Input("schoolExcluded") schoolExcluded: string[] = [];

	/**
	 * @param schoolList
	 * @type ISchoolFile[]
	 * @default []
	 * Lista completa de escuelas para el combo
	 */
	@Input("schoolList") schoolList: ISchoolFile[] = [];
	/**
	 * @param schoolSelected
	 * @type string[]
	 * @default []
	 * Lista de escuelas seleccionados
	 */
	@Input("schoolSelected") schoolSelected: string[] = [];
	/**
	 * @param campusFilter
	 * @type string[]
	 * @default []
	 * Lista de campus para filtrar las escuelas
	 */
	@Input("campusFilter") campusFilter: string[] = [];
	/**
	 * @param levelFilter
	 * @type string[]
	 * @default []
	 * Lista de niveles para filtrar las escuelas
	 */
	@Input("levelFilter") levelFilter: string[] = [];
	/**
	 * @param useFilters
	 * @type boolean
	 * @default false
	 * Parametro para indicar que se deben utilizar los filtros de campus, escuelas y niveles
	 */
	@Input("useFilters") useFilters: boolean = false;
	/**
	 * @param readonly
	 * @type boolean
	 * @default false
	 * Si el combo tiene la funcionalidad de solo lectura o permite seleccionar y deseleccionar opciones
	 */
	@Input("readonly") readonly: boolean = false;
	/**
	 * @param withId
	 * @type boolean
	 * @default false
	 * Para mostrar las opciones del combo como pareja Id - Nombre
	 */
	@Input("withId") withId: boolean = false;
	/**
	 * @emits onSelectionChanged
	 * @type string[]
	 * @default []
	 * Evento que se emite cuando ocurre un cambio en la selección de opciones del combo
	 */
	@Output("onSelectionChanged") onSelectionChanged: EventEmitter<string[]> = new EventEmitter();

	/**
	 * @property _schoolFiltered
	 * @type ISchoolFile[]
	 * @default []
	 * Lista de escuelas filtradas para omitir combinaciones schoolId - schoolName
	 */
	_schoolFiltered: ISchoolFile[] = [];
	/**
	 * @property _schoolSelectedFiltered
	 * @type string[]
	 * @default []
	 * Lista de escuelas seleccionadas filtrada para omitir combinaciones schoolId - schoolName
	 */
	_schoolSelectedFiltered: string[] = [];

	/**
	 * @field campusControl
	 * Control de formulario para combo de escuelas
	 */
	schoolControl: FormControl = new FormControl();
	/**
	 * @property initilizedView
	 * @type boolean
	 * @default false
	 * Bandera para inicializar la vista
	 */
	initilizedView: boolean = false;

	allSelectedBefore: boolean = false;

	constructor() {}

	ngOnInit() {
		// console.log("ngOnInit");
		// console.log("campusFilter: ", this.campusFilter);
		// console.log("schoolList: ", this.schoolList);
		// console.log("schoolSelected: ", this.schoolSelected);
		this.init();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initilizedView) {
			// console.log("FilesSchoolComponent ngOnChanges executed: ", changes);
			if (this.useFilters || changes["useFilters"]) {
				if (this.useFilters || changes["useFilters"].currentValue) {
					if (changes["campusFilter"]) {
						this.assignSchools(<Array<string>>changes["campusFilter"].currentValue);
					}
				}
			}
		}
	}

	init() {
		this.schoolList = this.schoolList != null && typeof this.schoolList !== "undefined" ? this.schoolList : [];
		this.schoolSelected = this.schoolSelected != null && typeof this.schoolSelected !== "undefined" ? this.schoolSelected : [];

		this.assignSchools(this.campusFilter);

		this.schoolControl.setValue(this._schoolSelectedFiltered);
		this.onSelectionChanged.emit(this._schoolSelectedFiltered);
		this.selectionChanged();
		this.initilizedView = true;
	}

	selectionChanged() {
		this.schoolControl.valueChanges.pipe(untilDestroyed(this)).subscribe((values: string[]) => {
			// console.log("selectionChanged valueChanges: ", values);
			if (values.includes(schoolForAll) && this.withSelectAll) {
				if (!this.allSelectedBefore) {
					this.allSelectedBefore = true;
					this._schoolSelectedFiltered = [];
					this._schoolSelectedFiltered.push(schoolForAll);
					this._schoolSelectedFiltered.push(...this._schoolFiltered.map((r) => r.schoolId));
					this.schoolControl.setValue(this._schoolSelectedFiltered, {
						onlySelf: true,
						emitEvent: false,
					});
					this.onSelectionChanged.emit(this._schoolSelectedFiltered);
				} else {
					if (this._schoolFiltered.length + 1 == values.length) {
						this.allSelectedBefore = true;
						this._schoolSelectedFiltered = [];
						this._schoolSelectedFiltered.push(schoolForAll);
						this._schoolSelectedFiltered.push(...this._schoolFiltered.map((r) => r.schoolId));
						this.schoolControl.setValue(this._schoolSelectedFiltered, {
							onlySelf: true,
							emitEvent: false,
						});
						this.onSelectionChanged.emit(this._schoolSelectedFiltered);
					} else if (this._schoolFiltered.length + 1 > values.length) {
						this.allSelectedBefore = false;
						this._schoolSelectedFiltered = [];
						this._schoolSelectedFiltered.push(...values.filter((r) => r != schoolForAll));
						this.schoolControl.setValue(this._schoolSelectedFiltered, {
							onlySelf: true,
							emitEvent: false,
						});
						this.onSelectionChanged.emit(this._schoolSelectedFiltered);
					}
				}
			} else {
				if (this.allSelectedBefore) {
					this.schoolControl.setValue([], {
						onlySelf: true,
						emitEvent: false,
					});
					this.onSelectionChanged.emit([]);
					this.allSelectedBefore = false;
				} else {
					if (values.length == this._schoolFiltered.length && this.withSelectAll) {
						this._schoolSelectedFiltered = [];
						this._schoolSelectedFiltered.push(schoolForAll);
						this._schoolSelectedFiltered.push(...values);
						this.schoolControl.setValue(this._schoolSelectedFiltered, {
							onlySelf: true,
							emitEvent: false,
						});
						this.allSelectedBefore = true;
						this.onSelectionChanged.emit(this._schoolSelectedFiltered);
						return;
					}
					this.onSelectionChanged.emit(values);
				}
			}
		});
	}

	assignSchools(campusFilter: string[]) {
		// console.log("======= CAMPUS assignSchools ========");
		campusFilter = campusFilter != null && typeof campusFilter !== "undefined" ? campusFilter : [];
		this._schoolFiltered = [];
		this._schoolFiltered = this.schoolList.reduce((acc: ISchoolFile[], d: ISchoolFile) => {
			let school = acc.find((s) => s.schoolId == d.schoolId);
			if (school) {
				school.campus = school.campus ? (d.campusId ? [...new Set([...school.campus, d.campusId])] : school.campus) : [];
			} else {
				acc.push({
					...d,
					campus: d.campus ? d.campus : [d.campusId],
				});
			}
			return acc;
		}, []);

		// console.log("CAMPUS this._schoolFiltered: ", this._schoolFiltered);

		if (this.useFilters) {
			// console.log("CAMPUS assignSchools campusFilter: ", this.useFilters, campusFilter);
			if (this.schoolSelected.includes(schoolForAll)) {
				let _auxSchool: ISchoolFile[] = [];
				campusFilter.forEach((filterCampus: string) => {
					const _sf = this._schoolFiltered.filter((sf) => sf.campus.includes(filterCampus));
					_sf.forEach((sf: ISchoolFile) => {
						if (typeof _auxSchool.find((_as: ISchoolFile) => _as.schoolId == sf.schoolId && _as.schoolName == sf.schoolName) === "undefined") {
							_auxSchool.push(sf);
						}
					});
				});
				// console.log("CAMPUS assignSchools Filtered _auxSchool: ", _auxSchool);
				this._schoolFiltered = [];
				this._schoolFiltered = _auxSchool;
			}
			this.setSchoolSelectedFiltered();

			// console.log("CAMPUS _schoolSelectedFiltered: ", this._schoolSelectedFiltered);
			this.schoolControl.setValue(this._schoolSelectedFiltered, {
				onlySelf: true,
				emitEvent: false,
			});
		} else {
			this.setSchoolSelectedFiltered();
		}
	}

	setSchoolSelectedFiltered() {
		if (this.schoolSelected.includes(schoolForAll)) {
			this._schoolSelectedFiltered.push(schoolForAll);
			this._schoolSelectedFiltered.push(...this._schoolFiltered.map((r) => r.schoolId));
		} else {
			this._schoolSelectedFiltered = [...new Set([...this._schoolSelectedFiltered, ...this.schoolSelected])];
		}
	}
}
