import { Injectable } from '@angular/core';
import { ITransactionsFilter, ITransactionData, IStatusData, ITransactionLog } from '../interfaces/itransactions-filter';
import { DEFAULT_PAGINATION, IPagination } from 'src/app/core/http/interfaces/ipagination';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { catchError, map } from 'rxjs/operators';
import { IProcedureData } from '../interfaces/IProcedureData';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {



  protected transactionsEndpoints: {
    list: Endpoint,
    procedureStatus: Endpoint,
    nextStatus: Endpoint,
    changeStatus: Endpoint,
    proceduresList: Endpoint,
    transactionRestore: Endpoint,
    transactionListByUser: Endpoint,
    transactionTracker: Endpoint
    transactionsList: Endpoint,
    blog: Endpoint,
    cancel: Endpoint,
  };

  constructor(private http: AppHttp,
    private endpoints: EndpointsService) {
    this.transactionsEndpoints = this.endpoints.transactions;
  }


  public getTransactionsList(filters: ITransactionsFilter) {
    const query = { ...DEFAULT_PAGINATION, ...filters };
    const params = this.http.buildQueryParams(query);
     this.transactionsEndpoints.list.setCache(0);
    // this.transactionsEndpoints.list.useWildcardParams({id: 2});
    return this.http.get<IResponse<ITransactionData[]>>(this.transactionsEndpoints.list, { params })
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de solicitudes')));
  }

  public getTransactionsListByUser(SisAccount, profileId, paginaton):
  Observable<{data: ITransactionData[], total: number}> {
    const query = { ...paginaton };
    const params = this.http.buildQueryParams(query);
    this.transactionsEndpoints.transactionListByUser.useWildcardParams({SisAccount, profileId});
    this.transactionsEndpoints.transactionListByUser.setCache(0);
    return this.http.get<IResponse<ITransactionData[]>>(this.transactionsEndpoints.transactionListByUser, { params })
    .pipe(map(response => ({data: response.data, total: response.additionalData.total})))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de solicitudes')));
  }

  public restoreTransaction(TransactionId, profileId) { // ok
    this.transactionsEndpoints.transactionRestore.setCache(0);
    this.transactionsEndpoints.transactionRestore.useWildcardParams({ TransactionId, profileId });
    return this.http.get<IResponse<ITransactionData[]>>(this.transactionsEndpoints.transactionRestore)
      .pipe(map(response => ({ data: response.data, additionalData: response.additionalData }) ))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de solicitudes')));
  }


  public getStatusList() {
    console.log('obteniendo lista de estatus');
    const query = { };
    const params = this.http.buildQueryParams(query);
    this.transactionsEndpoints.procedureStatus.setCache(0);
    this.transactionsEndpoints.procedureStatus.useWildcardParams({procedureId: 2});
    return this.http.get<IResponse<IStatusData[]>>(this.transactionsEndpoints.procedureStatus, { params })
    .pipe(map(response => response.data))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de estatus')));
  }

  public getNextStatus(idTransaction: number) {

    console.log('obteniendo lista de estatus');
    const query = { };
    const params = this.http.buildQueryParams(query);
    this.transactionsEndpoints.nextStatus.setCache(0);
    this.transactionsEndpoints.nextStatus.useWildcardParams({ procedureId: 2, folio : idTransaction});
    return this.http.get<IResponse<IStatusData[]>>(this.transactionsEndpoints.nextStatus, { params })
    .pipe(map(response => response.data))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de estatus')));
  }

  public nextStatus(trasactionId: number, idStatus: number) {
    console.log('obteniendo lista de estatus');
    const body = { TransactionId: trasactionId, NewStatusId: idStatus };
    return this.http.post<IResponse<ITransactionData>>(this.transactionsEndpoints.changeStatus, body)
    .pipe(map(response => response.data))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo cambiar el status')));
  }

  public getProcedures(procedureType: string) {
    const query = { ProcedureType: procedureType };
    const params = this.http.buildQueryParams(query);
    this.transactionsEndpoints.proceduresList.setCache(0);
    return this.http.get<IResponse<IProcedureData[]>>(this.transactionsEndpoints.proceduresList, { params })
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de solicitudes')));
  }

  public getTransactionTracker(TransactionId, profileId) {
    this.transactionsEndpoints.transactionTracker.useWildcardParams({TransactionId, profileId});
    this.transactionsEndpoints.transactionTracker.setCache(0);
    return this.http.get<IResponse<any>>(this.transactionsEndpoints.transactionTracker)
      .pipe(map(response => response))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de solicitudes')));
  }

  public getBlog(idTransaction: number) {
    this.transactionsEndpoints.blog.setCache(0);
    this.transactionsEndpoints.blog.useWildcardParams({ idTransaction : idTransaction});

    return this.http.get<IResponse<ITransactionLog[]>>(this.transactionsEndpoints.blog)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de solicitudes')));
  }

  public cancelTransaction(transactionId, profileId) {
    this.transactionsEndpoints.cancel.setCache(0);
    this.transactionsEndpoints.cancel.useWildcardParams({transactionId, profileId});

    return this.http.post(this.transactionsEndpoints.cancel, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible realizar la cancelación')));

  }
}
