import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormGroup, FormBuilder } from '@angular/forms';

import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { ConfigService } from 'src/app/core/config/services/config.service';
import { UserFacade } from 'src/app/core/data/user/facades/user-facade';
import { IUserData } from 'src/app/core/data/user/interfaces/iuser-data';
import { ReRegistrationService } from 'src/app/modules/school-services/services/re-registration/re-registration.service';
import { IReEnrollmentModel } from 'src/app/modules/school-services/pages/interfaces/ire-rnrollment-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from '../base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-riel-iframe',
  templateUrl: './riel-iframe.component.html',
  styleUrls: ['./riel-iframe.component.scss']
})
export class RIELIFrameComponent extends BaseComponent implements OnInit, OnDestroy {
  private user$: Observable<IUserData> = this.userFacade.profile$;
  private informationUser: IReEnrollmentModel = <IReEnrollmentModel>{};

  public url: SafeUrl = null;
  public hidden = false;
  public method: string;
  public formGroup: FormGroup = this.fb.group({
    usuario: [''],
    idUsuario: [''],
    userId: [''],
    profileUserId: [''],
    campus: [''],
    admin: [''],
    firma: [''],
    token: ['']
  });

  @ViewChild('postForm') postForm: any;
  constructor(
    private config: ConfigService,
    private route: ActivatedRoute ,
    private sanitizer: DomSanitizer,
    private userFacade: UserFacade,
    private reRegistrationService: ReRegistrationService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.getRIELData();
  }
  ngOnDestroy() {}

  getRIELData () {
    // let subscription: Subscription =
    combineLatest([this.user$.pipe(first()), this.route.data])
      .pipe(map(([userData, routeData]) => {
        return {
          userData: userData,
          routeData: routeData
        };
      }))
      .pipe(untilDestroyed(this)).subscribe((data) => {
        this.reRegistrationService.getData(data.userData.id).pipe(untilDestroyed(this)).subscribe(rielData =>  {
          this.informationUser = rielData;
          const request: any = {
            url: `${this.config.get('riel.url')}${data.routeData.request.url}`,
            method: data.routeData.request.method,
            payload: {
              usuario: rielData.sisAccount,
              idUsuario: rielData.SSELUser,
              userId: rielData.userId,
              profileUserId: rielData.profileUserId,
              campus: rielData.campus,
              admin: rielData.admin,
              firma: rielData.firma,
              token: rielData.token
            },
            mimetype: 'application/json'
          };

          this.sendRequest(request);

        });
      });
  }

  sendRequest(request) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(request.url);
    this.method = request.method;
    this.formGroup.patchValue(request.payload);
    // TODO: Encontrar una evento que nos indique cuando los datos del formulario están listos
    // para poder ejecutar el envío del formulario.
    // Se está teniendo que retrasar el envío del formulario, ya que de lo contrario los atributos action
    // y method no están listos, es decir tiene un valor de null.
    setTimeout(() => {
      this.submit();
    }, 200);
  }

  submit() {
    this.postForm.nativeElement.submit();
  }
}
