import { ISupportingDocumentParam } from './isupporting-document-param';

export enum SupportingDocumentSectionTypes {
  fixed = 'fixed',
  optional = 'optional',
  exclude = 'exclude',
  excludeoptional = 'excludeoptional'
}

export interface ISupportingDocumentSection {
  sectionId: number;
  name: string;
  type: SupportingDocumentSectionTypes;
  content: string;
  parameters: ISupportingDocumentParam[];
  order: number;
}

export interface ISupportingDocumentSectionModel extends ISupportingDocumentSection {
  hasParam: (id: string) => boolean;
  isFixed: () => boolean;
  isOptional: () => boolean;
  isExclude: () => boolean;
}
