import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-preload-track',
  templateUrl: './preload-track.component.html',
  styleUrls: ['./preload-track.component.scss']
})
export class PreloadTrackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
