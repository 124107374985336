import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { StringService } from 'src/app/core/services/string/string.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('pdf') pdf: string;
  pdfURLReady: boolean;
  timeBeforeRender: number = 2000;
  pdfURL: Uint8Array;
  base64src: string;

  constructor(
    private stringService: StringService,
  ) { }

  ngOnInit() {
    try {
      setTimeout(() => {
        (<any>window).PDFViewerApplication.setTitleUsingUrl('Comprobante-de-Pago.pdf');
      }, this.timeBeforeRender);
    } catch (error) { }
    if (this.pdf) {
      this.handlePdfURL(this.pdf);
      this.handleTime();
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {

  }

  handlePdfURL(base64PDF: string) {
    this.base64src = base64PDF;
    this.pdfURL = this.stringService.base64ToUint8Array(base64PDF);
  }

  handleTime() {
    const self = this;
    setTimeout(() => {
      self.pdfURLReady = true;
    }, 0);
  }

}
