import { Routes } from '@angular/router';
//import { SocialServiceComponent } from '../school-services/pages/social-service/pages/social-service.component';
import { ConstanceOrderDetailComponent } from './pages/constance-order-detail/constance-order-detail.component';
import { DocumentOrderDetailComponent } from './pages/document-order-detail/document-order-detail.component';

export const PUBLIC_PAGES_ROUTES: Routes = [
  {
    path: 'supportingdocument',
    data: {
      title: 'Detalle de constancia'
    },
    component: ConstanceOrderDetailComponent
  },
  {
    path: 'document',
    data: {
      title: 'Información del documento'
    },
    component: DocumentOrderDetailComponent
  },
  {
    path: 'social-service',
    loadChildren: () => import('src/app/modules/school-services/pages/social-service/social-service.module').then(m => m.SocialServiceModule)
    // children: [
    //   {
    //     path: '',
    //     redirectTo: "start",
    //     pathMatch: 'prefix'
    //   },
    //   {
    //     path: 'start',
    //     data: {
    //       id: 'social-service',
    //       title: 'Empresas servicio social',
    //       icon: 'industry'
    //     },
    //     component: SocialServiceComponent,
    //   }
      // {
      //   path: 'sector/:sector/career/:career',
      //   data: {
      //     id: 'social-service',
      //     title: 'Empresas servicio social',
      //     icon: 'industry'
      //   },
      //   component: SocialServiceComponent,
      // }
      // {
      //   path: 'program/:program/sector/:sector/career/:career',
      //   pathMatch: 'full',
      //   data: {
      //     id: 'social-service',
      //     title: 'Empresas servicio social',
      //     icon: 'industry'
      //   },
      //   component: SocialServiceProgramComponent,
      // }
    //]
  }
];
