import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IReportReenrollmentState } from '../interfaces/ireport-reenrollment-status';

export const selectReportReenrollmentState =
  createFeatureSelector<IReportReenrollmentState>('reportReenrollment');

export const getState = createSelector(
  selectReportReenrollmentState,
  (state: IReportReenrollmentState) => state
);
