import { Component, OnInit } from '@angular/core';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-unitec-shield',
  templateUrl: './unitec-shield.component.html',
  styleUrls: ['./unitec-shield.component.scss']
})
export class UnitecShieldComponent implements OnInit {

  extend$ = this.sidenavExtendService.extend$;

  constructor(
    private sidenavExtendService: SidenavExtendService
  ) { }

  ngOnInit() {
  }

}
