import { createAction, props } from '@ngrx/store';
import { ITokenBaseResponse } from 'src/app/modules/service-window/title-request/core/ititle-request';

export const SET_TOKEN_DATA = createAction(
  '[TITLE_REQUEST] set SET_TOKEN_DATA',
  props<{ payload: string }>()
);

export const SET_KEY_TIMBRADOS_DATA = createAction(
  '[TITLE_REQUEST] set SET_KEY_TIMBRADOS_DATA',
  props<{ payload: string }>()
);

export const SET_TIMBRADOS_DATA = createAction(
  '[TITLE_REQUEST] set SET_KEY_TIMBRADOS_DATA',
  props<{ payload: ITokenBaseResponse }>()
);

export const REMOVE_DATA = createAction(
  '[TITLE_REQUEST] REMOVE DATA data',
  props<{ payload: boolean }>()
);
