import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Endpoint } from "../../models/endpoint/endpoint";
import { Observable, of, throwError } from "rxjs";
import { RequestOptionsInterface } from "../../interfaces/request-options-interface";
import { RequestOptionsHandler } from "../../models/request-options-handler/request-options-handler";
import { catchError, tap } from "rxjs/operators";
import { ICustomMessageError, MessageService } from "../../../services/message/message.service";
import { UrlBypassExceptionMessage } from "../../data/url-bypass-exception-message";
import { IResponse } from "../../interfaces/iresponse";
import { IMessageConfig } from "../../interfaces/imessage-config";
import { NotificationFacade } from "src/app/core/data/notification/facades/notification-facade";
import { INotificationType, NotificationTypes } from "src/app/core/data/notification/interfaces/inotification-data";

export interface IAppHttpCustomError {
	url: string;
	customMessage: ICustomMessageError;
}

@Injectable({
	providedIn: "root",
})
export class AppHttp {
	whiteList = UrlBypassExceptionMessage;
	customMessages: IAppHttpCustomError[] = [];

	constructor(private http: HttpClient, private messenger: MessageService, private router: Router, private notificationFacade: NotificationFacade) { }

	buildQueryParams(query: { [name: string]: any }): HttpParams {
		return Object.keys(query).reduce((parameters, key) => (!!query[key] ? parameters.set(key, query[key]) : parameters), new HttpParams());
	}

	handleError(response: HttpErrorResponse, defaultMessage: string = null) {
		//debugger;
		let error: IResponse<any, any> = response.error;
		if (error['action'] && error['action'] === 'stopPropagation') {
			return throwError(() => response);
		}
		const message = error && error.message ? error.message : defaultMessage || response.statusText;
		//console.log("handleError: ", response);
		const details = error && error.error ? this.getDetails(error) : response.status >= 500 ? { details: [response.message] } : {};
		const urlFound = this.whiteList.find((uri) => response["url"].includes(uri));
		if (typeof urlFound === "undefined" || urlFound == null) {
			let icustom = this.customMessages.findIndex((c) => c.url == response.url);
			if (icustom > -1) {
				this.messenger.error(message, details, null, null, { continueLabel: this.customMessages[icustom].customMessage.label }, !!this.customMessages[icustom].customMessage.detailsOpen);
				this.customMessages.splice(icustom, 1);
			} else {
				this.messenger.error(message, details);
			}
		}

		return throwError(() => response);
	}

	getDetails(error: IResponse<any, any>) {
		//debugger;
		if (typeof error.error === "string") {
			return { 0: [error.error] };
		}
		return error.error;
	}

	handleConfigMessage(error: IMessageConfig) {
		this.handleErrorMessage(error);
		let action = 'continue';
		switch (error.event) {
			case "finish":
				setTimeout(() => this.router.navigate(["/user/profile"]), 500);
				action = 'stopPropagation';
				break;
			case "start":
				break;
			case "beforeStep":
				break;
			case "continue":
			default:
				break;
		}
		return action;
	}

	handleErrorMessage(error: IMessageConfig) {
		let details = error.detail ? this.parseDetails(error.detail) : {};
		switch (error.showAs) {
			case "notification":
				this.notificationFacade.add({
					notificationType: INotificationType.alert,
					type: this.getNotificationType(error),
					title: error.header,
					message: error.detail.reduce((p, c, a) => `${p} ${c}`),
					data: {},
					date: "",
					expires: "",
					background: false,
					origin: "",
				});
				break;
			case "modal":
			default:
				if (error.level === "error") {
					this.messenger.error(error.header, details);
				}
				if (error.level === "informative") {
					this.messenger.info(error.header, details);
				}
				if (error.level === "warning") {
					this.messenger.warning(error.header, details);
				}
				break;
		}
	}
	parseDetails(details: any) {
		return Array.isArray(details) ? { error: details } : details;
	}

	handleTapResponse(response: any) {
		let error: IResponse<any, any> = response;
		if (!!error.messageConfig) {
			response['action'] = this.handleConfigMessage(error.messageConfig);
		}
		//console.log("handleTapResponse response: ", response);
		return response;
	}

	handleCatchErrorResponse(response: any) {
		//console.log("handleCatchErrorResponse response: ", response);
		return this.handleTapResponse(response.error);
	}

	handleMessage(response: any, customMessage: ICustomMessageError) {
		if (!!response["message"] && !!response["httpStatusCode"] && response["httpStatusCode"] >= 201 && response["httpStatusCode"] <= 299) {
			if (`${response["message"]}`.includes("still in progress")) return;
			this.messenger.info(response["message"], null, () => {
				if (customMessage && customMessage.redirectToProfile) {
					this.router.navigate(["/user/profile"]);
				}
			});
		}
	}

	handleAndCreateErrorResponse(response: any) {
		return new HttpErrorResponse({
			error: this.handleCatchErrorResponse(response),
			url: response.url,
			headers: response.headers,
			status: response.status,
			statusText: response.statusText,
		});
	}

	get<T>(endpoint: Endpoint, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("GET", endpoint.url, RequestOptionsHandler.resolve(endpoint, options))
			.pipe(
				tap((response) => {
					// console.log("GET tap response: ", response);
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					//console.log("GET catch response: ", response);
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	post<T>(endpoint: Endpoint, body: any | null, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("POST", endpoint.url, { ...RequestOptionsHandler.resolve(endpoint, options), body })
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	put<T>(endpoint: Endpoint, body: any | null, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("PUT", endpoint.url, { ...RequestOptionsHandler.resolve(endpoint, options), body })
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	patch<T>(endpoint: Endpoint, body: any | null, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("PATCH", endpoint.url, { ...RequestOptionsHandler.resolve(endpoint, options), body })
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	delete<T>(endpoint: Endpoint, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("DELETE", endpoint.url, RequestOptionsHandler.resolve(endpoint, options))
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	options<T>(endpoint: Endpoint, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("OPTIONS", endpoint.url, options)
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	head<T>(endpoint: Endpoint, options?: RequestOptionsInterface, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.request("HEAD", endpoint.url, options)
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	jsonp<T>(endpoint: Endpoint, callBackParam: string, customMessage: ICustomMessageError = null): Observable<any> {
		this.setCustomMessage(endpoint.url, customMessage);
		return this.http
			.jsonp(endpoint.url, callBackParam)
			.pipe(
				tap((response) => {
					this.handleTapResponse(response);
					endpoint.restore();
				})
			)
			.pipe(
				catchError((response) => {
					endpoint.restore();
					return throwError(() => this.handleAndCreateErrorResponse(response));
				})
			);
	}

	setCustomMessage(url: string, custom: ICustomMessageError = null) {
		if (!!custom) {
			let message = this.customMessages.find((m) => m.url == url);
			if (!!message) {
				message.customMessage = custom;
			} else {
				this.customMessages.push({
					url: url,
					customMessage: custom,
				});
			}
		}
	}

	private getNotificationType(error: IMessageConfig): NotificationTypes {
		if (error.level === "error") {
			return NotificationTypes.error;
		}
		if (error.level === "informative") {
			return NotificationTypes.info;
		}
		if (error.level === "warning") {
			return NotificationTypes.warning;
		}
	}
}
