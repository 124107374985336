import { SocialServiceComponent } from './pages/social-service.component';
import { SocialServiceProgramComponent } from './components/social-service-program/social-service-program.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SOCIAL_SERVICE } from './social-service.routes';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    RouterModule.forChild(SOCIAL_SERVICE)
  ],
  declarations: [
    SocialServiceProgramComponent,
    SocialServiceComponent
  ]
})
export class SocialServiceModule { }
