import { Injectable, OnDestroy } from '@angular/core';
import { UserFacade } from '../../data/user/facades/user-facade';
import { TransactionFacade } from '../../data/transactions-engine/facades/transaction-facade';
import { TransactionsService } from '../../data/transactions-engine/services/transactions.service';
import { ITransactionsFilter } from '../../data/transactions-engine/interfaces/itransactions-filter';
import { IPagination } from '../../http/interfaces/ipagination';
import { Subscription, timer, of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChatBotService } from '../../data/chatbot/services/chatbot.service';
import { IAzureAD, IAuthBot } from '../../data/chatbot/interfaces/ichatbot-status';
import { ChatbotFacade } from '../../data/chatbot/facades/chatbot-facade';
import { ConfigService } from '../../config/services/config.service';
import { IUserData } from '../../data/user/interfaces/iuser-data';
import { IChatbotModel } from '../../data/chatbot/interfaces/ichatbot-model';
import { CreateElementsService } from '../../services/create-elements/create-elements.service';
import { ScriptChatbot } from '../../data/constants/predefine-scripts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class ChatBotInitializer {

    profile$: Observable<IUserData> = this.userFacade.profile$;
    subscription: Subscription;

    constructor(
        private userFacade: UserFacade,
        private chatBotService: ChatBotService,
        private chatBotFacade: ChatbotFacade,
        private createElements: CreateElementsService
    ) {

    }

    init() {
        this.chatBotFacade.setChatbot(true);
        //this.loadAzureAD();
    }

    loadAzureAD() {
        this.profile$.pipe(
            switchMap((profile: IUserData) => this.chatBotService.getMeBot(profile.id))
        ).pipe(untilDestroyed(this)).subscribe((chatbot: IChatbotModel) => {
            if (chatbot && (chatbot.token != null && typeof chatbot.token !== 'undefined' && chatbot.token != '')) {
                this.chatBotFacade.setChanelId(chatbot.channelId);
                const token: IAuthBot = {
                    result: {
                        token: chatbot.token
                    }
                };
                this.chatBotFacade.setAuthBot(token);
                setTimeout(() => this.createElements.LoadScript(ScriptChatbot), 100);
            } else {
                this.chatBotFacade.removeChatBot(false);
            }
        });
    }

}
