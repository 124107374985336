import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ɵConsole } from "@angular/core";
import { ICareerFile, ICareerExcluded } from "../../interfaces/iinformative-files.interface";
import { FormControl } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export const careerForAll = "TODAS";

@UntilDestroy()
@Component({
	selector: "app-files-career",
	templateUrl: "./files-career.component.html",
	styleUrls: ["./files-career.component.scss"],
})
export class FilesCareerComponent implements OnInit, OnChanges {
	/**
	 * @param withSelectAll
	 * @type boolean
	 * @default false
	 * Parametro para indicar si se debe mostrar la opción seleccionar todo
	 */
	@Input("withSelectAll") withSelectAll: boolean = false;

	/**
	 * @param withExcluded
	 * @type boolean
	 * @default false
	 * Parametro para indicar si se debe bloquear la seleccion de carreras excluidos
	 */
	@Input("withExcluded") withExcluded: boolean = false;
	/**
	 * @param careerExcluded
	 * @type string[]
	 * @default []
	 * Lista de carreras que no se pueden seleccionar
	 */
	@Input("careerExcluded") careerExcluded: ICareerExcluded[] = [];

	/**
	 * @param careerList
	 * @type ICareerFile[]
	 * @default []
	 * Lista completa de carreras para el combo
	 */
	@Input("careerList") careerList: ICareerFile[] = [];
	/**
	 * @param careerSelected
	 * @type string[]
	 * @default []
	 * Lista de carreras seleccionadas
	 */
	@Input("careerSelected") careerSelected: string[] = [];
	/**
	 * @param campusFilter
	 * @type string[]
	 * @default []
	 * Lista de campus para filtrar las carreras
	 */
	@Input("campusFilter") campusFilter: string[] = [];
	/**
	 * @param levelFilter
	 * @type string[]
	 * @default []
	 * Lista de niveles para filtrar las carreras
	 */
	@Input("levelFilter") levelFilter: string[] = [];
	/**
	 * @param schoolFilter
	 * @type string[]
	 * @default []
	 * Lista de escuelas para filtrar las carreras
	 */
	@Input("schoolFilter") schoolFilter: string[] = [];
	/**
	 * @param useFilters
	 * @type boolean
	 * @default false
	 * Parametro para indicar que se deben utilizar los filtros de campus, escuelas y niveles
	 */
	@Input("useFilters") useFilters: boolean = false;
	/**
	 * @param readonly
	 * @type boolean
	 * @default false
	 * Si el combo tiene la funcionalidad de solo lectura o permite seleccionar y deseleccionar opciones
	 */
	@Input("readonly") readonly: boolean = false;
	/**
	 * @param withId
	 * @type boolean
	 * @default false
	 * Para mostrar las opciones del combo como pareja Id - Nombre
	 */
	@Input("withId") withId: boolean = false;
	/**
	 * @emits onSelectionChanged
	 * @type string[]
	 * @default []
	 * Evento que se emite cuando ocurre un cambio en la selección de opciones del combo
	 */
	@Output("onSelectionChanged") onSelectionChanged: EventEmitter<string[]> = new EventEmitter();

	/**
	 * @property _careerFiltered
	 * @type ICareerFile[]
	 * @default []
	 * Lista de carreras filtradas para omitir combinaciones careerId - careerName
	 */
	_careerFiltered: ICareerFile[] = [];
	/**
	 * @property _careerSelectedFiltered
	 * @type string[]
	 * @default []
	 * Lista de carreras filtradas para omitir combinaciones careerId - careerName
	 */
	_careerSelectedFiltered: string[] = [];

	/**
	 * @field careerControl
	 * Control de formulario para combo de carreras
	 */
	careerControl: FormControl = new FormControl();
	/**
	 * @property initilizedView
	 * @type boolean
	 * @default false
	 * Bandera para inicializar la vista
	 */
	initilizedView: boolean = false;

	allSelectedBefore: boolean = false;

	constructor() {}

	ngOnInit() {
		// console.log("FilesCareerComponent ngOnInit");
		// console.log("careerList: ", this.careerList);
		// console.log("schoolFilter: ", this.schoolFilter);
		// console.log("campusFilter: ", this.campusFilter);
		// console.log("careerSelected: ", this.careerSelected);
		this.init();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initilizedView) {
			// console.log("ngOnChanges executed: ", changes);
			if (this.useFilters || changes["useFilters"]) {
				if (this.useFilters || changes["useFilters"].currentValue) {
					if (changes["campusFilter"]) {
						this.campusFilter = <Array<string>>changes["campusFilter"].currentValue;
					}
					if (changes["schoolFilter"]) {
						this.assignCareers(<Array<string>>changes["schoolFilter"].currentValue);
					}
				}
			}
		}
	}

	init() {
		this.careerList = this.careerList != null && typeof this.careerList !== "undefined" ? this.careerList : [];
		this.careerSelected = this.careerSelected != null && typeof this.careerSelected !== "undefined" ? this.careerSelected : [];

		this.assignCareers(this.schoolFilter);

		this.careerControl.setValue(this._careerSelectedFiltered);
		this.onSelectionChanged.emit(this._careerSelectedFiltered);
		this.selectionChanged();

		this.initilizedView = true;
	}

	selectionChanged() {
		this.careerControl.valueChanges.pipe(untilDestroyed(this)).subscribe((values: string[]) => {
			//// console.log(values);
			//// console.log('this._careerFiltered: ', this._careerFiltered);
			if (values.includes(careerForAll) && this.withSelectAll) {
				if (!this.allSelectedBefore) {
					this.allSelectedBefore = true;
					this._careerSelectedFiltered = [];
					this._careerSelectedFiltered.push(careerForAll);
					this._careerSelectedFiltered.push(...this._careerFiltered.map((r) => r.careerId));
					//// console.log(selected);
					this.careerControl.setValue(this._careerSelectedFiltered, { onlySelf: true, emitEvent: false });
					this.onSelectionChanged.emit(this._careerSelectedFiltered);
				} else {
					if (this._careerFiltered.length + 1 == values.length) {
						//todas estan seleccionadas
						this.allSelectedBefore = true;
						this._careerSelectedFiltered = [];
						this._careerSelectedFiltered.push(careerForAll);
						this._careerSelectedFiltered.push(...this._careerFiltered.map((r) => r.careerId));
						//// console.log(selected);
						this.careerControl.setValue(this._careerSelectedFiltered, { onlySelf: true, emitEvent: false });
						this.onSelectionChanged.emit(this._careerSelectedFiltered);
					} else if (this._careerFiltered.length + 1 > values.length) {
						this.allSelectedBefore = false;
						this._careerSelectedFiltered = [];
						this._careerSelectedFiltered.push(...values.filter((r) => r != careerForAll));
						//// console.log(selected);
						this.careerControl.setValue(this._careerSelectedFiltered, { onlySelf: true, emitEvent: false });
						this.onSelectionChanged.emit(this._careerSelectedFiltered);
					}
				}
			} else {
				if (this.allSelectedBefore) {
					this.careerControl.setValue([], {
						onlySelf: true,
						emitEvent: false,
					});
					this.onSelectionChanged.emit([]);
					this.allSelectedBefore = false;
				} else {
					if (values.length == this._careerFiltered.length && this.withSelectAll) {
						this._careerSelectedFiltered = [];
						this._careerSelectedFiltered.push(careerForAll);
						this._careerSelectedFiltered.push(...values);
						this.careerControl.setValue(this._careerSelectedFiltered, { onlySelf: true, emitEvent: false });
						this.allSelectedBefore = true;
						this.onSelectionChanged.emit(this._careerSelectedFiltered);
						return;
					}
					this.onSelectionChanged.emit(values);
				}
			}
		});
	}

	assignCareers(schoolFilter: string[]) {
		// console.log("======== assignCareers ========");
		schoolFilter = schoolFilter != null && typeof schoolFilter !== "undefined" ? schoolFilter : [];
		this._careerFiltered = [];
		this._careerFiltered = this.careerList.reduce((acc: ICareerFile[], d: ICareerFile) => {
			let career = acc.find((s) => s.careerId == d.careerId);
			if (career) {
				career.schools = career.schools ? (d.schoolId ? [...new Set([...career.schoolId, d.schoolId])] : career.schools) : [];
				career.campus = career.campus ? (d.campusId ? [...new Set([...career.campus, d.campusId])] : career.campus) : [];
			} else {
				acc.push({
					...d,
					schools: d.schools ? d.schools : [d.schoolId],
					campus: d.campus ? d.campus : [d.campusId],
				});
			}
			return acc;
		}, []);

		// console.log("this._careerFiltered: ", this._careerFiltered);
		if (this.useFilters) {
			// console.log("this.useFilters: ", this.useFilters, schoolFilter);
			if (!this.careerSelected.includes(careerForAll)) {
				let _auxCareer: ICareerFile[] = [];
				schoolFilter.forEach((filterSchool: string) => {
					const _cf = this._careerFiltered.filter((sf) => sf.schools.includes(filterSchool));
					_cf.forEach((cf: ICareerFile) => {
						if (typeof _auxCareer.find((_as: ICareerFile) => _as.careerId == cf.careerId) === "undefined") {
							_auxCareer.push(cf);
						}
					});
				});
				// console.log("_auxCareer: ", _auxCareer);

				this._careerFiltered = [];
				this._careerFiltered = _auxCareer;
			}

			this.setFilteredSlectedCareers();
			this.careerControl.setValue(this._careerSelectedFiltered, {
				onlySelf: true,
				emitEvent: false,
			});
		} else {
			this.setFilteredSlectedCareers();
		}
	}

	getExcluded() {
		// // console.log('excluding');
		// // console.log(this.careerExcluded);
		const careerExcluded = this.careerExcluded.map((c) => c.careerId);
		this._careerFiltered = this._careerFiltered.filter((c) => !careerExcluded.includes(c.careerId));
	}

	setFilteredSlectedCareers() {
		if (this.careerSelected.includes(careerForAll)) {
			this._careerSelectedFiltered.push(careerForAll);
			this._careerSelectedFiltered.push(...this._careerFiltered.map((r) => r.careerId));
		} else {
			this.careerSelected.forEach((career: string, index: number) => {
				if (typeof this._careerSelectedFiltered.find((c) => c == career) === "undefined") {
					this._careerSelectedFiltered.push(career);
				}
			});
		}
	}
}
