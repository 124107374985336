import { Injectable } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class BlockuiService {
  private blockui: NgBlockUI;

  constructor() { }

  public setBlockUI(blockui: NgBlockUI) {
    this.blockui = blockui;
  }

  public start(message?: any) {
    return this.blockui.start(message);
  }

  public stop() {
    return this.blockui.stop();
  }
}
