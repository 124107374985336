import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  iconConfig: string [];
  @Input('icon') icon: string;
  @Input('size') size: string;
  @Input('spin') spin: boolean = false;
  constructor() {}

  ngOnInit() {
    this.iconConfig = this.getIconConfig();
  }

  getIconConfig() {
    const split = this.icon.split(' ');
    const [prefix, iconName ] = split;
    if(iconName) {
      if(iconName.length >= 5) {
        return [prefix, iconName.replace('fa-', '')];
      }
      return null;
    }
    return null;
  }
}
