import { Injectable } from '@angular/core';
import { AppHttp } from '../../../http/services/app-http/app-http.service';
import { IResponse } from '../../../http/interfaces/iresponse';
import { EndpointsService } from '../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../http/models/endpoint/endpoint';
import { Observable } from 'rxjs';
import { INavigationTree } from '../interfaces/inavigation-tree';
import { map } from 'rxjs/operators';
import { INavigationNodeData } from '../interfaces/inavigation-node-data';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private endpointMenu: Endpoint;
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService
  ) {
    this.endpointMenu = this.endpoints.account.menu;
  }

  /**
   * Fetch navigation nodes configuration
   */
  public getMenu(userId: string): Observable<INavigationTree<INavigationNodeData>> {
    this.endpointMenu.useWildcardParams({userId});
    return this.http.get<IResponse<INavigationTree<INavigationNodeData>>>(this.endpointMenu)
      .pipe(map(response => response.data));
  }
}
