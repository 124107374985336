import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MERCADO_PAGO_ROUTES } from './mercadopago.routes';


@NgModule({
  imports: [RouterModule.forChild(MERCADO_PAGO_ROUTES)],
  exports: [RouterModule]
})
export class MercadoPagoRoutingModule { }
