import { createAction, props } from '@ngrx/store';
import { IBillBoxData, IBillBoxFilters, IBillingData, IRebillingData } from '../interfaces/ibilling-status';

export const SET_BILLBOX = createAction('[BILLING] set BILLBOX data', props<{ payload: IBillBoxData }>());

export const SET_BILLBOX_FILTERS = createAction('[BILLING] set billbox FILTER data', props<{ payload: IBillBoxFilters }>());

export const SET_BILLBOX_BILLS = createAction('[BILLING] set billbox BILLS data', props<{ payload: Array<any> }>());

export const SET_BILLBOX_TRNS_ID = createAction('[BILLING] set billbox TRANSACTION ID data', props<{ payload: string }>());

export const REMOVE_BILLBOX = createAction('[BILLING] REMOVE billbox data', props<{ payload: boolean }>());


export const SET_BILLINGDATA = createAction('[BILLING] set BILLINGDATA data', props<{ payload: IBillingData }>());

export const SET_BILLINGDATA_DATA = createAction('[BILLING] set billingdata DATA', props<{ payload: any }>());

export const SET_BILLINGDATA_ACTIVE = createAction('[BILLING] set billingdata ACTIVE data', props<{ payload: boolean }>());

export const SET_BILLINGDATA_TRNS_ID = createAction('[BILLING] set billingdata TRANSACTION ID data', props<{ payload: string }>());

export const REMOVE_BILLINGDATA = createAction('[BILLING] REMOVE billingdata', props<{ payload: boolean }>());


export const SET_REBILLING = createAction('[BILLING] set REBILLING data', props<{ payload: IRebillingData }>());

export const SET_REBILLING_FOLIO = createAction('[BILLING] set rebilling FOLIO data', props<{ payload: string }>());

export const SET_REBILLING_UUID = createAction('[BILLING] set rebilling UUID data', props<{ payload: string }>());

export const SET_REBILLING_ACTIVE = createAction('[BILLING] set rebilling ACTIVE data', props<{ payload: boolean }>());

export const SET_REBILLING_DATA = createAction('[BILLING] set rebilling DATA data', props<{ payload: any }>());

export const SET_REBILLING_ADDITIONALDATA = createAction('[BILLING] set rebilling ADDITIONALDATA data', props<{ payload: any }>());

export const SET_REBILLING_TRNS_ID = createAction('[BILLING] set rebilling TRANSACTION ID data', props<{ payload: string }>());

export const REMOVE_REBILLING = createAction('[BILLING] REMOVE rebilling', props<{ payload: boolean }>());


export const SET_BILLING_MESSAGE = createAction('[BILLING] set billing message ACTIVE', props<{ payload: { [name: string]: boolean } }>());

export const REMOVE_BILLING_MESSAGE = createAction('[BILLING] REMOVE billing message', props<{ payload: boolean }>());
