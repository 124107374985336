import { ISectionControl } from "../interfaces/isection-control";
import { DocTypeEnum } from "src/app/core/interfaces/generic-document/isection.model";
import { IDocumentSection } from "../interfaces/idocument.section";

export class SectionControl implements ISectionControl {
    
    order: number;
    type: DocTypeEnum;
    sections: IDocumentSection[] = [];
  
    get section() {
      if (this.hasMultipleOptions()) {
        throw Error('This control has multiple sections');
      }
      const [section] = this.sections;
      return section;
    }
  
    hasMultipleOptions() {
      return this.sections.length > 1;
    }
}