import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationClient } from './services/notification-client/notification-client.service';
import { NotificationFacade } from './facades/notification-facade';
//import { PushNotificationService } from 'ngx-push-notifications';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    NotificationClient,
    NotificationFacade,
  ]
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        NotificationClient
      ],
    };
  }
}
