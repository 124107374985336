import { createReducer, on } from '@ngrx/store';
import {
  SET_AUTH_DATA,
  SET_SSEL_TOKEN,
  SET_PROFILE,
  SET_PROFILE_PICTURE,
  SET_PERMISSIONS,
  SET_QUEUE_DATA
} from './user.actions';
import { USER_STATE } from './user.state';
import { IUserState } from '../interfaces/iuser-state';

const _userReducer = createReducer(USER_STATE,
  on(SET_AUTH_DATA, (state: IUserState, { payload }) => {
    const auth = { ...state.auth, authenticated: true, username: payload };
    return { ...state, auth };
  }),
  on(SET_SSEL_TOKEN, (state: IUserState, { payload }) => ({ ...state, SSELToken: payload })),
  on(SET_PROFILE, (state: IUserState, { payload }) => ({ ...state, profile: payload })),
  on(SET_PROFILE_PICTURE, (state: IUserState, { payload }) => ({ ...state, picture: payload })),
  on(SET_PERMISSIONS, (state: IUserState, { payload }) => ({ ...state, permissions: payload })),
  on(SET_QUEUE_DATA, (state: IUserState, { payload }) => ({ ...state, queue: payload }))
);

export function UserReducer(state, action) {
  return _userReducer(state, action);
}