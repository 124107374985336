import { Routes } from '@angular/router';
import { RequestPaymentComponent } from './pages/request/request.component';
import { PaymentPayComponent } from './pages/pay/pay.component';

export const PAYMENTS_PAY_ROUTES: Routes = [
  {
    path: 'apply/:id',
    data: {
      title: 'Solicitud de Pago',
    },
    component: RequestPaymentComponent
  },
  {
    path: 'process/:id',
    data: {
      title: 'Procesar Pago',
    },
    component: PaymentPayComponent
  }
];
