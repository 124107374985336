import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAYMENTS_SUMMARY_ROUTES } from './summary.routes';


@NgModule({
  imports: [RouterModule.forChild(PAYMENTS_SUMMARY_ROUTES)],
  exports: [RouterModule]
})
export class PaymentSummaryRoutingModule { }
