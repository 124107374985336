import { createReducer, on } from '@ngrx/store';
import { CONFIRMATION_STATE } from './confirmation.state';
import { IConfirmationState, TypeToken } from '../interfaces/iconfirmation-status';
import { SET_CONFIRMATION, SET_CONFIRMATION_ID, SET_CONFIRMATION_ACCESS, ADD_CONFIRMATION, REMOVE_CONFIRMATION, SET_CONFIRMATION_ERROR } from './confirmation.actions';

const _confirmationReducer = createReducer(CONFIRMATION_STATE,
    on(SET_CONFIRMATION, (state: IConfirmationState, { payload }) => {
        const token = null;
        const active = true;
        const user = null;
        const finished = false;
        return { ...state, idToken: token, active, user, finished };
    }),
    on(SET_CONFIRMATION_ID, (state: IConfirmationState, { payload, active, user, finished }) => {
        const token = { ...payload };
        return { ...state, idToken: token, active, user, finished };
    }),
    on(SET_CONFIRMATION_ACCESS, (state: IConfirmationState, { payload, active, user, finished }) => {
        const token = { ...payload };
        return { ...state, accessToken: token, active, user, finished };
    }),
    on(ADD_CONFIRMATION, (state: IConfirmationState, { payload, typeToken }) => {
        const token = { ...payload };
        if (typeToken == TypeToken.idToken) {
            return { ...state, idToken: token };
        } else {
            return { ...state, accessToken: token };
        }
    }),
    on(REMOVE_CONFIRMATION, (state: IConfirmationState, { payload }) => {
        const idToken = null;
        const accessToken = null;
        const active = false;
        const user = null;
        const error = null;
        const finished = false;
        return { idToken, accessToken, user, active, error, finished };
    }),
    on(SET_CONFIRMATION_ERROR, (state: IConfirmationState, { payload, finished }) => {
        const error = { ...payload };
        return { ...state, error, finished };
    })
);

export function ConfirmationReducer(state, action) {
    return _confirmationReducer(state, action);
}