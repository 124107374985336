import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../config/services/config.service';

@Injectable()
export class BlobInterceptor implements HttpInterceptor {

  _blobUri: string = '';

  constructor(
    private configService: ConfigService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.configService.get('storage.blob.url', ''))) {
      const _url = req.url;
      const request = req.clone({
        url: _url + this.getSignature(_url)
      });
      return next.handle(request);
    }
    return next.handle(req);
  }

  getSignature(url: string): string {
    if (url.includes(this.configService.get('storage.blob.signature', '&sig=')))
      return '';
    let signature = this.configService.get(`storage.blob.signature`, '');
    return signature == '' ? '' : `?${signature}`;
  }

}
