import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user.service';
import { UserFacade } from './facades/user-facade';
import { StudentService } from './services/student/student.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    UserService,
    StudentService,
    UserFacade,
  ]
})
export class UserModule { }
