import { Component, OnInit, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { REQUESTED_URL, REQUESTED_URL_QUERIES } from 'src/app/core/session/services/auth/auth.service';

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.scss']
})
export class BaseComponent {

  private spinner: NgxSpinnerService = inject(NgxSpinnerService);
  constructor(
    
  ) { 
    this.spinner.hide();
    localStorage.removeItem(REQUESTED_URL);
    //localStorage.removeItem(REQUESTED_URL_QUERIES);
    //console.log('BaseComponent clear');
  }

}
