import { Injectable } from '@angular/core';
import { AppHttp } from '../../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../../http/models/endpoint/endpoint';
import { IResponse } from '../../../../http/interfaces/iresponse';
import { ISchoolData } from '../../interfaces/ischool-data';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  schoolEndpoint: Endpoint;
  schoolsByWordEndpoint: Endpoint;
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService
  ) {
    this.schoolEndpoint = this.endpoints.catalogs.schools;
    this.schoolsByWordEndpoint = this.endpoints.catalogs.schoolsByWord;
  }

  getSchoolList(campusId: string) {
    this.schoolEndpoint.useWildcardParams({ campusId });
    return this.http.get<IResponse<ISchoolData>>(this.schoolEndpoint)
      .pipe(map(response => response.data));
  }

  getSchoolsByWordList(word: string) {
    this.schoolsByWordEndpoint.useWildcardParams({ word });
    return this.http.get<IResponse<ISchoolData>>(this.schoolsByWordEndpoint)
      .pipe(map(response => response.data));
  }

}
