import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICampusFile } from '../../interfaces/iinformative-files.interface';

export const campusForAll = 'TODOS';

@UntilDestroy()
@Component({
  selector: 'app-files-campus',
  templateUrl: './files-campus.component.html',
  styleUrls: ['./files-campus.component.scss']
})
export class FilesCampusComponent implements OnInit {

  /**
   * @param withSelectAll
   * @type boolean
   * @default false
   * Parametro para indicar si se debe mostrar la opción seleccionar todo
   */
  @Input('withSelectAll') withSelectAll: boolean = false;

  /**
   * @param withExcluded
   * @type boolean
   * @default false
   * Parametro para indicar si se debe bloquear la seleccion de campus excluidos
   */
  @Input('withExcluded') withExcluded: boolean = false;

  /**
   * @param campusExcluded
   * @type string[]
   * @default []
   * Lista de campus que no se pueden seleccionar
   */
  @Input('campusExcluded') campusExcluded: string[] = [];

  /**
   * @param campusList
   * @type ICampusFile[]
   * @default []
   * Lista completa de campus para el combo
   */
  @Input('campusList') campusList: ICampusFile[] = [];
  /**
   * @param campusSelected
   * @type string[]
   * @default []
   * Lista de campus seleccionados
   */
  @Input('campusSelected') campusSelected: string[] = [];
  /**
   * @param readonly
   * @type boolean
   * @default false
   * Si el combo tiene la funcionalidad de solo lectura o permite seleccionar y deseleccionar opciones
   */
  @Input('readonly') readonly: boolean = false;
  /**
   * @param withId
   * @type boolean
   * @default false
   * Para mostrar las opciones del combo como pareja Id - Nombre
   */
  @Input('withId') withId: boolean = false;
  /**
   * @emits onSelectionChanged
   * @type string[]
   * @default []
   * Evento que se emite cuando ocurre un cambio en la selección de opciones del combo
   */
  @Output('onSelectionChanged') onSelectionChanged: EventEmitter<string[]> = new EventEmitter();

  /**
   * @property _campusFiltered
   * @type ICampusFile[]
   * @default []
   * Lista de campus filtrada para omitir combinaciones campusId - campusName
   */
  _campusFiltered: ICampusFile[] = [];
  /**
   * @property _campusSelectedFiltered
   * @type string[]
   * @default []
   * Lista de campus seleccionado filtrada para omitir combinaciones campusId -campusName
   */
  _campusSelectedFiltered: string[] = [];
  /**
     * @field campusControl
     * Control de formulario para combo de campus
     */
  campusControl: FormControl = new FormControl();
  /**
   * @property initilizedView
   * @type boolean
   * @default false
   * Bandera para inicializar la vista
   */
  initilizedView: boolean = false;

  allSelectedBefore: boolean = false;

  constructor(

  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.init();
    }, 1);
  }

  init() {
    this.campusList = (this.campusList != null && typeof this.campusList !== 'undefined') ? this.campusList : [];
    this.campusSelected = (this.campusSelected != null && typeof this.campusSelected !== 'undefined') ? this.campusSelected : [];

    this.campusList.forEach((campus: ICampusFile, index: number) => {
      if (typeof this._campusFiltered.find(c => c.campusId == campus.campusId && c.campusName == campus.campusName) === 'undefined') {
        this._campusFiltered.push(campus);
      }
    });
    if(this.campusSelected.includes(campusForAll)) {
      this._campusSelectedFiltered.push(campusForAll);
      this._campusSelectedFiltered.push(...this._campusFiltered.map(r => r.campusId));
    } else {
      this.campusSelected.forEach((campus: string, index: number) => {
        if (typeof this._campusSelectedFiltered.find(c => c == campus) === 'undefined') {
          this._campusSelectedFiltered.push(campus);
        }
      });
    }

    this.campusControl.setValue(this._campusSelectedFiltered);
    this.onSelectionChanged.emit(this._campusSelectedFiltered);
    if (this.withExcluded) {
      this.excludeOptions();
    }
    this.selectionChanged();
    this.initilizedView = true;
  }

  selectionChanged() {
    this.campusControl.valueChanges.pipe(untilDestroyed(this)).subscribe((values: string[]) => {
      //console.log(values);
      //console.log('this._campusFiltered: ', this._campusFiltered);
      if (values.includes(campusForAll) && this.withSelectAll) {
        if (!this.allSelectedBefore) {
          this.allSelectedBefore = true;
          this._campusSelectedFiltered = [];
          this._campusSelectedFiltered.push(campusForAll);
          this._campusSelectedFiltered.push(...this._campusFiltered.map(r => r.campusId));
          //console.log(selected);
          this.campusControl.setValue(this._campusSelectedFiltered, { onlySelf: true, emitEvent: false });
          this.onSelectionChanged.emit(this._campusSelectedFiltered);
        } else {
          if ((this._campusFiltered.length + 1) == values.length) {
            //todas estan seleccionadas
            this.allSelectedBefore = true;
            this._campusSelectedFiltered = [];
            this._campusSelectedFiltered.push(campusForAll);
            this._campusSelectedFiltered.push(...this._campusFiltered.map(r => r.campusId));
            //console.log(selected);
            this.campusControl.setValue(this._campusSelectedFiltered, { onlySelf: true, emitEvent: false });
            this.onSelectionChanged.emit(this._campusSelectedFiltered);
          }
          else if ((this._campusFiltered.length + 1) > values.length) {
            this.allSelectedBefore = false;
            this._campusSelectedFiltered = [];
            this._campusSelectedFiltered.push(...values.filter(r => r != campusForAll));
            //console.log(selected);
            this.campusControl.setValue(this._campusSelectedFiltered, { onlySelf: true, emitEvent: false });
            this.onSelectionChanged.emit(this._campusSelectedFiltered);
          }
        }
      } else {
        if (this.allSelectedBefore) {
          this.campusControl.setValue([], { onlySelf: true, emitEvent: false });
          this.onSelectionChanged.emit([]);
          this.allSelectedBefore = false;
        } else {
          if (values.length == this._campusFiltered.length && this.withSelectAll) {
            this._campusSelectedFiltered = [];
            this._campusSelectedFiltered.push(campusForAll);
            this._campusSelectedFiltered.push(...values);
            this.campusControl.setValue(this._campusSelectedFiltered, { onlySelf: true, emitEvent: false });
            this.allSelectedBefore = true;
            this.onSelectionChanged.emit(this._campusSelectedFiltered);
            return;
          }
          this.onSelectionChanged.emit(values);
        }

      }
    });
  }

  excludeOptions() {

  }

}
