import { Injectable } from "@angular/core";
import { Endpoint } from "../../http/models/endpoint/endpoint";
import { AppHttp } from "../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../http/services/endpoints/endpoints.service";
import { Observable, of } from "rxjs";
import { IValidationCampus } from "../../interfaces/ivalidation-campus";
import { map, catchError } from "rxjs/operators";
import { IDocumentsRulesConfiguration } from "../../interfaces/documents-rules.interface";
import { IAcademicTree, IAcademicTreeWithLevel } from "../../data/catalogs/interfaces/iacademic-tree";
import { IAddress } from "../../interfaces/iaddrees";

@Injectable({
    providedIn: 'root'
})
export class CatalogueService {

    catalogueEndpoints: {
        getInformativeConfiguration: Endpoint,
        getInformativeValidation: Endpoint,
        getInformativeFile: Endpoint,
        informativeFile: Endpoint,
        deleteInformativeFile: Endpoint,
        academicTree: Endpoint,
        getAddress: Endpoint
    }

    constructor(
        private http: AppHttp,
        private endpoints: EndpointsService,
    ) {
        this.catalogueEndpoints = this.endpoints.catalogs;
    }

    getInformativeConfiguration(informativeTypeId: any): Observable<IDocumentsRulesConfiguration> {
        this.catalogueEndpoints.getInformativeConfiguration.useWildcardParams({ informativeTypeId });
        return this.http.get(this.catalogueEndpoints.getInformativeConfiguration)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener los datos de configuración.')));
    }

    getInformativeValidation(informativeTypeId: any, profileId: string): Observable<IValidationCampus> {
        this.catalogueEndpoints.getInformativeValidation.useWildcardParams({ informativeTypeId, profileId });
        return this.http.get(this.catalogueEndpoints.getInformativeValidation)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener información de validación.')));
    }

    getInformativeFile(informativeTypeId: any): Observable<IValidationCampus[]> {
        this.catalogueEndpoints.getInformativeFile.useWildcardParams({ informativeTypeId });
        return this.http.get(this.catalogueEndpoints.getInformativeFile)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener la lista de documentos')));
    }

    saveInformativeFile(body: any): Observable<boolean> {
        return this.http.post(this.catalogueEndpoints.informativeFile, body)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No fué posible guardar el documento')));
    }

    updateInformativeFile(body: any): Observable<boolean> {
        return this.http.patch(this.catalogueEndpoints.informativeFile, body)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No fué posible actualizar el documento')));
    }

    deleteInformativeFile(InformativeFileId: any): Observable<IValidationCampus> {
        this.catalogueEndpoints.deleteInformativeFile.useWildcardParams({ InformativeFileId });
        return this.http.delete(this.catalogueEndpoints.deleteInformativeFile)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No fué posible realizar la eliminación del documento')));
    }

    academicTree(body): Observable<IAcademicTree[] | IAcademicTreeWithLevel[]> {
        return this.http.post(this.catalogueEndpoints.academicTree, body)
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener la lista de campus')));
    }

    getAddress(zipCode: string): Observable<IAddress[]> {
      this.catalogueEndpoints.getAddress.useWildcardParams({ id: zipCode });
      return this.http.get(this.catalogueEndpoints.getAddress)
          .pipe(map(response => response.data))
          .pipe(catchError(response => this.http.handleError(response, 'No fue posible obtener la información asociada al código postal')));
    }


    private getValidationMoc(): Observable<IValidationCampus> {
        return of(this.getValidation());
    }

    private getValidation(): IValidationCampus {
        return <IValidationCampus>{
            redirectToSSEL: false,
            fileBlobUrl: 'https://qa-cdn-appstorage-onlinese-unitec.azureedge.net/class-whithdraw-guidelines/046effdb-578e-40f6-b1af-fbcfe53eaab5'
        }
    }

}
