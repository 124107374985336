export enum ActionType {
  show = 'show',
  hide = 'hide',
  disabled = 'disabled',
  message = 'message',
  popUp = 'popUp',
  url = 'url',
}

export enum TargetTypes {
  blank = '_blank',
}

export interface INavigationNodeAdditionalData {
  message: string;
  target: TargetTypes;
  url: string;
}

export interface INavigationNodeDataStyle {
  cssstyle: string;
  superindextext: string;
  iconcolor: string;
}

export interface INavigationNodeData {
  id: string;
  text: string;
  translateKey: string;
  action: ActionType;
  iconKey: string;
  children: INavigationNodeData[];
  data?: INavigationNodeAdditionalData;
  rules?: IRule[],
  style?: INavigationNodeDataStyle;
}

export interface IRule {
  action: string;
  startDate: Date;
  endDate: Date;
  campus: CampusData[];
  school: SchoolData[];
  data: IDataMessage;
  message: string;
}

export interface SchoolData {
  schoolId: string;
  schoolName: string;
}

export interface CampusData {
  campusName: string;
  campusId: string;
}

export interface IDataMessage {
  message?: string;
  url?: string;
  target?: string;
}

/**
 * NUEVA FUNCIONALIDAD DE MENUS
 */

export interface INavigationNodeDataModel {
  id: string;
  text: string;
  translateKey: string;
  action: ActionType;
  iconKey: string;
  children: INavigationNodeDataModel[];
  data?: INavigationNodeAdditionalData;
  style?: INavigationNodeDataStyle;
}
