import { createReducer, on } from '@ngrx/store';
import { IEarlyEnrollmentState } from '../interfaces/iearly-enrollment-status';
import { REMOVE_DATA, SET_ACTIVE, SET_ENROLLMENT_DATA, SET_PAYMENT_DATA, SET_PAYMENT_RESUME_DATA, SET_STUDENT_DATA, SET_SUBJECTS_DATA } from './early-enrollment.actions';
import { EARLYENROLLMENT_STATE } from './early-enrollment.state';


const _earlyEnrollmentReducer = createReducer(EARLYENROLLMENT_STATE,
  on(SET_ENROLLMENT_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, ...payload })),
  on(SET_ACTIVE, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, active: true })),
  on(SET_STUDENT_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, studentData: payload })),
  on(SET_SUBJECTS_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, subjectsData: payload })),
  on(SET_PAYMENT_RESUME_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, paymentResumeData: payload })),
  on(SET_PAYMENT_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, paymentResumeData: payload })),
  on(REMOVE_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...EARLYENROLLMENT_STATE, active: false }))
);

export function EarlyEnrollmentReducer(state, action) {
  return _earlyEnrollmentReducer(state, action);
}
