import { Component, OnInit } from '@angular/core';
import { UserFacade } from 'src/app/core/data/user/facades/user-facade';
import { Observable } from 'rxjs';
import { IUserData } from 'src/app/core/data/user/interfaces/iuser-data';
import { ITransactionsFilter, ITransactionData } from 'src/app/core/data/transactions-engine/interfaces/itransactions-filter';
import { TransactionFacade } from 'src/app/core/data/transactions-engine/facades/transaction-facade';
import { TransactionsService } from 'src/app/core/data/transactions-engine/services/transactions.service';
import { IPagination } from 'src/app/core/http/interfaces/ipagination';
import { PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-transaction-history-center',
  templateUrl: './transaction-history-center.component.html',
  styleUrls: ['./transaction-history-center.component.scss']
})
export class TransactionHistoryCenterComponent implements OnInit {

  user$: Observable<IUserData> = this.userFacade.profile$;
  transactions$: Observable<{ data: ITransactionData[], total: number }> = this.transactionFacade.transactions$;
  transactions: ITransactionData[] = [];

  length = 0;
  pageSize = 10;

  pageEvent: PageEvent;
  pagination: IPagination;

  constructor(
    private transactionFacade: TransactionFacade,
    private userFacade: UserFacade,
    private transactionService: TransactionsService,
  ) { }

  ngOnInit() {
    this.transactions$.pipe(untilDestroyed(this)).subscribe(transactions => {
      this.transactions = transactions.data;
      this.length = transactions.total;
    });
  }

  getPage(event: PageEvent) {
    this.pageEvent = event;
    this.pagination = {
      skip: this.pageEvent.pageIndex * 10,
      take: 10
    };
    this.user$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.transactionService.getTransactionsListByUser(profile.sisAccount, profile.id, this.pagination)
      .pipe(untilDestroyed(this)).subscribe(data => {
        this.transactions = [];
        this.transactions = data.data;
      });
    });
  }

}
