export interface IApplicationState {
  init: boolean;
  title: string;
  ip: IApplicationIp;
}

export interface IApplicationIp {
  ip: string;
}

export const APPLICATION_STATE: IApplicationState = {
  init: false,
  title: null,
  ip: null
};
