import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tutor-dialog',
  templateUrl: './tutor-dialog.component.html',
  styleUrls: ['./tutor-dialog.component.scss']
})
export class TutorDialogComponent implements OnInit {

  constructor(
    private dialog: MatDialogRef<TutorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
  }

}
