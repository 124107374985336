import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from '../../config/services/config.service';
import { UserAgentApplication, Configuration } from 'msal';
import { AUTH_ROUTES } from '../../../modules/auth/auth.routes';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../session/services/auth/auth.service';
import { ConfirmationFacade } from '../../data/confirmation/facades/confirmation-facade';
import { IToken, IErrorInfo } from '../../data/confirmation/interfaces/iconfirmation-status';
import { Subscription, of } from 'rxjs';
import { IConfirmationError } from '../../interfaces/iconfirmation-error';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationTokenService implements OnDestroy {

  static get idToken(): string { return 'id_token'; }
  static get adalIdToken(): string { return 'adal.idtoken'; }
  static get accessToken(): string { return 'access_token'; }
  static get errorDescription(): string { return 'error_description'; }
  static get error(): string { return 'error'; }
  static get sessionStorageIdTokenKey(): string { return 'confirmationIdToken'; }
  static get sessionStorageAccessTokenKey(): string { return 'confirmationAccessToken'; }

  private msalConfig = <Configuration>{};
  private pathConfirmation: string = '';
  private OAuth2ConfigClientId: string = '';
  private replyUrl: string = '';

  tokenSubscriptor: Subscription;
  activeSubscription: Subscription;

  constructor(
    private config: ConfigService,
    private authService: MsalService,
    private confirmationFacade: ConfirmationFacade
  ) {
    this.OAuth2ConfigClientId = this.config.get('oauth2.azure.clientId');
    this.pathConfirmation = AUTH_ROUTES.filter(r => r.path === 'confirmationToken')[0].path;

    this.replyUrl = window.location.origin + '/' + this.pathConfirmation;

    this.msalConfig = <Configuration>{
      auth: {
        clientId: this.OAuth2ConfigClientId,
        redirectUri: this.replyUrl
      }
    };
  }

  ngOnDestroy() {
    if (this.tokenSubscriptor) {
      this.tokenSubscriptor.unsubscribe();
    }
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }
  }

  /**
   * LaunchConfirmationPopUp
   */
  public LaunchConfirmationPopUp(loginHint: string): any {
    const msalInstance = new UserAgentApplication(this.msalConfig);

    const loginRequest = {
      scopes: ['user.read'],
      extraQueryParameters: { prompt: 'login' },
      loginHint: loginHint
    };

    msalInstance.loginPopup(loginRequest)
      .then(idTokenResponse => {
        if (idTokenResponse.idToken &&
          (idTokenResponse.idToken.rawIdToken != null && typeof idTokenResponse.idToken.rawIdToken !== 'undefined' && idTokenResponse.idToken.rawIdToken != '')) {
          this.saveIdToken(idTokenResponse.idToken.rawIdToken);

          //console.log('adquire access token');

          // if the user is already logged in you can acquire a token
          if (msalInstance.getAccount()) {
            const tokenRequest = {
              scopes: ["user.read"]
            };
            msalInstance.acquireTokenSilent(tokenRequest)
              .then(accessTokenResponse => {
                //console.log('get access token from response')
                //console.log('response.accessToken')
                if (accessTokenResponse &&
                  (accessTokenResponse.accessToken != null && typeof accessTokenResponse.accessToken !== 'undefined'
                    && accessTokenResponse.accessToken != '')) {
                  this.saveAccessToken(accessTokenResponse.accessToken);
                } else {
                  this.saveError("Error al confirmar la contraseña", "No se pudó obtener el access token del usuario");
                }
              })
              .catch(_err => {
                // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                if (_err.name === "InteractionRequiredAuthError") {
                  return msalInstance.acquireTokenPopup(tokenRequest)
                    .then(accessTokenResponse_ => {
                      // get access token from response
                      // response.accessToken
                      if (accessTokenResponse_ &&
                        (accessTokenResponse_.accessToken != null && typeof accessTokenResponse_.accessToken !== 'undefined'
                          && accessTokenResponse_.accessToken != '')) {
                        this.saveAccessToken(accessTokenResponse_.accessToken);
                      } else {
                        this.saveError("Error al confirmar la contraseña", "No se pudó obtener el access token del usuario");
                      }
                    })
                    .catch(err_ => {
                      // handle error
                      this.saveError("Error al confirmar la contraseña", err_);
                    });
                } else {
                  this.saveError("Error al confirmar la contraseña", _err);
                }
              });
          } else {
            // user is not logged in, you will need to log them in to acquire a token
            this.saveError("Error al confirmar la contraseña", "El usuario no se encontro");
          }

        } else {
          this.saveError("Error al confirmar la contraseña", "No se pudó obtener el idToken del usuario");
        }
        // nunca entrará aquí por el tema de Adal
      })
      .catch((err: IConfirmationError) => {
        // handle error
        console.log('ERROR launching confirmation', err);
        this.saveError(err.errorCode, err.errorMessage);
      });
  }

  public confirmationTokenCallbackListener() {
    if (this.isCallback(window.location)) {
      const hashParams = this.deserialize(this.getHash(window.location.hash));

      if (hashParams.hasOwnProperty(ConfirmationTokenService.idToken)) {
        this.saveIdToken(hashParams[ConfirmationTokenService.idToken]);
        this.openConfirmationTokenWindow();

      } else if (hashParams.hasOwnProperty(ConfirmationTokenService.accessToken)) {
        this.saveAccessToken(hashParams[ConfirmationTokenService.accessToken]);
      } else if (hashParams.hasOwnProperty(ConfirmationTokenService.errorDescription) || hashParams.hasOwnProperty(ConfirmationTokenService.error)) {
        this.saveError(hashParams[ConfirmationTokenService.errorDescription], hashParams[ConfirmationTokenService.error]);
      }
    }
  }

  private openConfirmationTokenWindow() {
    let date = new Date();
    of(this.authService.instance.getActiveAccount()).subscribe({
      next: usr => {
        const url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=token&' +
          'scope=user.read&' +
          'client_id=' + this.OAuth2ConfigClientId + '&' +
          'redirect_uri=' + this.replyUrl + '&' +
          'state=' + date.getTime() + '&' +
          'nonce=' + date.getTime() + '&' +
          'client_info=1&' +
          'x-client-SKU=MSAL.JS&' +
          'x-client-Ver=1.0.0&' +
          'login_hint=' + usr.username + '&' +
          'login_req=' + date.getTime() + '&' +
          'domain_req=' + date.getTime() + '&' +
          'client-request-id=' + date.getTime() + '&' +
          'prompt=none';

        const frameName = "msalRenewFrame" + date.getTime();

        const frameHandle = this.addHiddenIFrame(frameName);
        frameHandle.src = "about:blank";
        this.loadIframeTimeout(encodeURI(url), frameName);
      },
      error: err => {
        console.log('ERROR getting idToken', err);
        this.saveError(err.errorCode, err.errorMessage);
      }
    });
  }

  private addHiddenIFrame(iframeId: string): HTMLIFrameElement {
    if (typeof iframeId === "undefined") {
      return null;
    }

    let adalFrame = document.getElementById(iframeId) as HTMLIFrameElement;
    if (!adalFrame) {
      if (document.createElement &&
        document.documentElement &&
        (window.navigator.userAgent.indexOf("MSIE 5.0") === -1)) {
        const ifr = document.createElement("iframe");
        ifr.setAttribute("id", iframeId);
        //ifr.style.visibility = "hidden";
        //ifr.style.position = "absolute";
        //ifr.style.width = ifr.style.height = "3330";
        ifr.style.border = "0";
        adalFrame = (document.getElementsByTagName("body")[0].appendChild(ifr) as HTMLIFrameElement);
      } else if (document.body && document.body.insertAdjacentHTML) {
        document.body.insertAdjacentHTML("beforeend", "<iframe name='" + iframeId + "' id='" + iframeId + "' style='display:block;width:3000;height:3000'></iframe>");
      }

      if (window.frames && window.frames[iframeId]) {
        adalFrame = window.frames[iframeId];
      }
    }

    return adalFrame;
  }

  private loadIframeTimeout(urlNavigate: string, frameName: string): void {
    //set iframe session to pending
    this.loadFrame(urlNavigate, frameName);
  }

  private loadFrame(urlNavigate: string, frameName: string): void {
    // This trick overcomes iframe navigation in IE
    // IE does not load the page consistently in iframe
    const frameCheck = frameName;

    setTimeout(() => {
      const frameHandle = this.addHiddenIFrame(frameCheck);
      if (frameHandle.src === "" || frameHandle.src === "about:blank") {
        frameHandle.src = urlNavigate;
      }
    }, 500);
  }

  private saveIdToken(token: string): void {
    this.confirmationFacade.setIdToken(<IToken>{ token: token }, true, '', false);
  }

  private saveAccessToken(token: string): void {
    this.activeSubscription = this.confirmationFacade.active$.subscribe(active => {
      if (active) {
        this.tokenSubscriptor = this.confirmationFacade.idToken$.subscribe(idToken => {
          this.setConfirmation({ token: token }, idToken);
        });
      }
    });
  }

  setConfirmation(token: IToken, idToken: IToken) {
    if (this.tokenSubscriptor)
      this.tokenSubscriptor.unsubscribe();
    this.confirmationFacade.setAccessToken(token, true, '', true);
  }

  private saveError(error: string, errorDescription: string) {
    this.confirmationFacade.setError(
      <IErrorInfo>{
        active: true,
        error: error ? error : null,
        errorDescription: errorDescription ? errorDescription : null
      },
      true
    );
  }

  private getHash(hash: string): string {
    if (hash.indexOf("#/") > -1) {
      hash = hash.substring(hash.indexOf("#/") + 2);
    } else if (hash.indexOf("#") > -1) {
      hash = hash.substring(1);
    }

    return hash;
  }

  private isCallback(location: Location): boolean {
    const hash = this.getHash(location.hash);

    if (location.href.indexOf(this.pathConfirmation) === -1) {
      return false;
    }

    const parameters = this.deserialize(hash);
    return (
      parameters.hasOwnProperty(ConfirmationTokenService.errorDescription) ||
      parameters.hasOwnProperty(ConfirmationTokenService.error) ||
      parameters.hasOwnProperty(ConfirmationTokenService.accessToken) ||
      parameters.hasOwnProperty(ConfirmationTokenService.idToken)
    );
  }

  private deserialize(query: string): any {
    let match: Array<string>; // Regex for replacing addition symbol with a space
    const pl = /\+/g;
    const search = /([^&=]+)=([^&]*)/g;
    const decode = (s: string) => decodeURIComponent(s.replace(pl, " "));
    const obj: {} = {};
    match = search.exec(query);
    while (match) {
      obj[decode(match[1])] = decode(match[2]);
      match = search.exec(query);
    }
    return obj;
  }
}
