import { Injectable, OnDestroy } from '@angular/core';
import { UserFacade } from '../../data/user/facades/user-facade';
import { TransactionFacade } from '../../data/transactions-engine/facades/transaction-facade';
import { TransactionsService } from '../../data/transactions-engine/services/transactions.service';
import { ITransactionsFilter } from '../../data/transactions-engine/interfaces/itransactions-filter';
import { IPagination } from '../../http/interfaces/ipagination';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class TransactionInitializer {

    profile$ = this.userFacade.profile$;
    subscription: Subscription;
    timeOfRequest = 0;
    time = 3;
    timeout: any;

    constructor(
        private userFacade: UserFacade,
        private transactionFacade: TransactionFacade,
        private transactionsService: TransactionsService,
    ) { }

    init() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }

        this.timeout = setTimeout(() => {
            //console.log('LOADING TRANSACTIONS... ' + new Date());
            this.initTransactions();
        }, 1000 * 60 * this.timeOfRequest)
    }

    initTransactions() {
        this.timeOfRequest = this.timeOfRequest + this.time;
        this.init();
        // this.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
        //     if (profile.sisAccount != null && typeof profile.sisAccount !== 'undefined') {
        //         this.loadTransactions(profile.sisAccount, profile.id);
        //     }
        // });
    }

    loadTransactions(sisAccount: string, profileId: string) {
        this.transactionsService.getTransactionsListByUser(sisAccount, profileId, <IPagination>{ skip: 0, take: 10 })
            .pipe(untilDestroyed(this)).subscribe(transactions => {
                this.transactionFacade.setTransactions([], 0);
                this.transactionFacade.setTransactions(transactions.data, transactions.total);
            });
    }

}
