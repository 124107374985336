import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum componentsInSidenav {
    transactions = 2,
    notification = 1
}

@Injectable({
    providedIn: 'root'
})
export class SidenavComponentService {

    private componentId$ = new Subject<number>();

    constructor() { }

    public setComponent(componentId: number) {
        this.componentId$.next(componentId);
    }

    public getComponet() {
        return this.componentId$;
    }

}
