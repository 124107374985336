import { Injectable } from '@angular/core';
import { UserService } from '../../../data/user/services/user.service';
import { timer } from 'rxjs';
import { MINUTE } from '../../../cache/services/cache.service';
import { ConfigService } from '../../../config/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class ServerAvailability {

  protected time = 4.5 * MINUTE;
  protected canSendRequest: boolean = true;
  protected account;
  protected timer;
  constructor(
    private user: UserService,
    private config: ConfigService
  ) {}


  isEnabled() {
    return this.config.get('enableQueueSessions') === 'yes';
  }

  checkAvailability(account) {
    if (!this.isEnabled()) {
      return false;
    }
    this.account = account;
    window.addEventListener('click', () => {
      console.log('user click');
      this.canSendRequest = true;
    });

    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.timer = timer(0, this.time).subscribe(() => this.sendRequest());
  }

  sendRequest() {
    if (this.canSendRequest && this.account) {
      this.resetControlFlag();
      this.user.getQueueStatus(this.account).subscribe();
    }
  }

  resetControlFlag() {
    this.canSendRequest = false;
  }
}
