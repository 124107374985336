import { NavigationNode } from '../models/navigation-node';
import { Injectable } from '@angular/core';
import { INavigationNode } from '../interfaces/inavigation-node';
import { INavigationNodeData } from '../interfaces/inavigation-node-data';
import { INavigationTree } from '../interfaces/inavigation-tree';

@Injectable()
export class NavigationNodeFactory {
  /**
   * Build a navigation node
   * @param properties
   */
  create(properties: INavigationNodeData): INavigationNode {
    const node = new NavigationNode();
    const { id, text, translateKey, action, iconKey, children, data, style } = properties;
    node.id = id;
    node.text = text;
    node.translateKey = translateKey;
    node.action = action;
    node.iconKey = iconKey;
    node.config = properties;
    node.children = (children) ? children.map(child => this.create(child)) : null;
    node.data = data;
    node.isOpen = false;
    node.style = style;
    return node;
  }

  /**
   * Build a navigation tree
   * @param treeData
   */
  createTree(treeData: INavigationTree<INavigationNodeData>): INavigationTree<INavigationNode> {
    const keys = Object.keys(treeData);
    let tree: INavigationTree<INavigationNode> = null;
    keys.forEach(key => {
      const nodes = treeData[key].map(node => this.create(node));
      tree = { ...tree, ...{ [key]: nodes} };
    });
    return tree;
  }
}
