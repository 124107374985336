import { createReducer, on } from '@ngrx/store';
import { IMPERSONATE_STATE } from './impersonate.state';
import { SET_PROFILE_BACKUP, SET_PICTURE_BACKUP, SET_ACTIVE, SET_INACTIVE } from './impersonate.actions';
import { IImpersonateState } from '../interfaces/iimpersonate-state';

const _impersonateReducer = createReducer(IMPERSONATE_STATE,
    on(SET_PROFILE_BACKUP, (state: IImpersonateState, { payload }) => ({ ...state, backup: payload })),
    on(SET_PICTURE_BACKUP, (state: IImpersonateState, { payload }) => ({ ...state, pictureBackup: payload })),
    on(SET_ACTIVE, (state: IImpersonateState) => ({ ...state, isActive: true })),
    on(SET_INACTIVE, (state: IImpersonateState) => ({ ...state, isActive: false }))
);

export function ImpersonateReducer(state, action) {
    return _impersonateReducer(state, action);
}