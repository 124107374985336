import { INavigationTree } from './interfaces/inavigation-tree';
import { ActionType, INavigationNodeData } from './interfaces/inavigation-node-data';

export const DEFAULT_NAVIGATION_CONFIG: INavigationTree<INavigationNodeData> = {
 main: [
   {
     id: 'main.home',
     text: 'Inicio',
     translateKey: 'home',
     action: ActionType.show,
     iconKey: 'fas fa-home',
     children: []
   },
 ],
 profile: []
};
