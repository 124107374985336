import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInitializer } from './user/user-initializer';
import { NavigationInitializer } from './navigation/navigation-initializer';
import { SSELInitializer } from './ssel/ssel-initializer';
import { NotificationInitializer } from './notification/notification-initializer';
import { TransactionInitializer } from './transaction/transaction-initializer';
import { ChatBotInitializer } from './chatbot/chat-bot-initializer';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    UserInitializer,
    NavigationInitializer,
    SSELInitializer,
    NotificationInitializer,
    TransactionInitializer,
    ChatBotInitializer
  ]
})
export class BootstrapModule { }
