import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { CACHEABLE_HEADER } from '../../http/models/request-options-handler/request-options-handler';
import { CacheService } from '../services/cache.service';
import { catchError, tap } from 'rxjs/operators';
import { Network } from '@ngx-pwa/offline';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cache: CacheService, private network: Network) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isCacheable(req)) { return next.handle(req); }

    return this.canBeLoadFromCache(req) && this.getFromCache(req).body
      ? of(this.getFromCache(req))
      : this.sendRequest(req, next);
  }

  /**
   * Check if a request is cacheable
   * @param {HttpRequest<any>} req
   * @returns {boolean}
   */
  isCacheable(req: HttpRequest<any>): boolean {
    return req.headers.has(CACHEABLE_HEADER) && req.method.toLowerCase() === 'get';
  }


  /**
   * Check if response can be load from storage
   * @param {HttpRequest<any>} req
   * @returns {boolean}
   */
  canBeLoadFromCache(req: HttpRequest<any>): boolean {
    return this.cache.has(req.urlWithParams) || (!this.network.online && this.cache.has(req.urlWithParams));
  }

  /**
   * Get Data from cache
   * @param {HttpRequest<any>} req
   * @returns {any}
   */
  getFromCache(req: HttpRequest<any>): HttpResponse<any> {
    const cachedResponse = this.cache.get(req.urlWithParams, this.network.online);
    return new HttpResponse({body: cachedResponse });
  }

  /**
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ttl = req.headers.get(CACHEABLE_HEADER);
    const request = req.clone({ headers: req.headers.delete(CACHEABLE_HEADER) });
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(req.urlWithParams, event.body, parseInt(ttl, 10));
        }
      })
    );
  }
}
