import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MessageService } from 'src/app/core/services/message/message.service';
import { IEventPyment } from 'src/app/modules/payment/modules/bank-bridge/interfaces/ievent-payment';
import { PAYMENT_CHANNELS_CAT } from 'src/app/modules/payment/catalogs/payment-channels';
import { IBBPaymentViewModel } from 'src/app/modules/payment/interfaces/ipayment-new';
import { IBBInfo } from '../../interface/ibankbride';
declare var BankBridge: any;
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-request-bank-v2-payment',
  templateUrl: './request-bank-payment.v2.component.html',
  styleUrls: ['./request-bank-payment.v2.component.scss']
})
export class RequestBankPaymentNewComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input('paymentInfo') paymentInfo: IBBPaymentViewModel;
  @Output() complete: EventEmitter<IEventPyment> = new EventEmitter();
  isEventEmitted = false;

  paymentData: any;

  BankBridge = BankBridge;

  BBInfo: IBBInfo = <IBBInfo>{};

  errorCounter = 0;

  constructor(
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {

  }

  init() {
    this.clearLocalStorage();
    if (this.paymentInfo == null || typeof this.paymentInfo === 'undefined') {
      this.complete.emit({ success: false, event: 'error', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.online });
      return;
    }
    this.continueWithPayment();
  }

  clearLocalStorage() {
    this.isEventEmitted = false;
  }

  continueWithPayment() {
    this.BBInfo = <IBBInfo>{};
    this.BBInfo = {
      interface: 'modal',
      fields: {
        /*card: {
          number: "#card-number",
          securityCode: "#security-code",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year",
          nameOnCard: "#cardholder-name"
        }*/
        parent: 'divParent'

      },
      payment: {
        sessionId: this.paymentInfo.sessionId,
        merchant: this.paymentInfo.merchant,
        bBId: this.paymentInfo.requestId,
        order: {
          amount: this.paymentInfo.amount,
          currency: this.paymentInfo.currency
        },
        token: this.paymentInfo.jwt
      }
    }

    this.pay();

  }

  pay() {
    this.BankBridge.init(this.BBInfo);
    this.BankBridge.paymentResults((data) => {
      console.log(data);
      if (data.type === "success") {
        if (data.id === "BB_PAYMENT_APPROVED") {
          const onComplete: IEventPyment = {
            success: true, event: 'complete',
            data: { resultIndicator: this.paymentInfo.requestId, sessionVersion: this.paymentInfo.sessionId, },
            paymentMethode: PAYMENT_CHANNELS_CAT.online
          };
          this.emitEvent(onComplete);
        }
      }
      else if (data.type === "error") {
        if (
          data.id === "BB_CONFIRMATION_ERROR" ||
          data.id === "BB_PAYMENT_UNAPPROVED_ERROR"
        ) {
          if (this.errorCounter >= 2) {
            const onError = {
              success: false, event: 'error',
              data: { resultIndicator: this.paymentInfo.requestId, sessionVersion: this.paymentInfo.sessionId, },
              paymentMethode: PAYMENT_CHANNELS_CAT.online
            };
            this.emitEvent(onError);
          }
          this.errorCounter++;
        }
        if (
          data.id === "BB_PAYMENT_CANCELED"
        ) {
          const onCancel: IEventPyment = {
            success: false, event: 'cancel',
            data: { resultIndicator: this.paymentInfo.requestId, sessionVersion: this.paymentInfo.sessionId, },
            paymentMethode: PAYMENT_CHANNELS_CAT.online
          };
          this.emitEvent(onCancel);
        }
      }
    });
  }

  emitEvent(event: IEventPyment) {
    if (!this.isEventEmitted) {
      this.isEventEmitted = true;
      this.clearLocalStorage();
    }
    if (event) {
      this.complete.emit(event);
    }
  }

}
