import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionsService } from 'src/app/core/data/transactions-engine/services/transactions.service';
import { UserFacade } from 'src/app/core/data/user/facades/user-facade';
import { Observable } from 'rxjs';
import { IUserData } from 'src/app/core/data/user/interfaces/iuser-data';
import { switchMap } from 'rxjs/operators';
import { MessageService } from 'src/app/core/services/message/message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-restore-procedure',
  templateUrl: './restore-procedure.component.html',
  styleUrls: ['./restore-procedure.component.scss']
})
export class RestoreProcedureComponent extends BaseComponent implements OnInit {

  profile$: Observable<IUserData> = this.userFacade.profile$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransactionsService,
    private userFacade: UserFacade,
    private messageService: MessageService
  ) {
    super();
   }

  ngOnInit() {
    this.loadDataOfTransaction();
  }

  loadDataOfTransaction() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.id) {
        this.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
          this.transactionService.restoreTransaction(params.id, profile.id).pipe(untilDestroyed(this)).subscribe(data => {
            console.log(data);
            if (data.additionalData) {
              if (data.additionalData.stepPath != null && typeof data.additionalData.stepPath === 'string' &&
                data.additionalData.stepPath !== '') {
                this.router.navigate([data.additionalData.stepPath, data.data.transactionId]);
              } else {
                this.router.navigate(['/']);
              }
            } else {
              this.messageService.warning('No pudimos recuperear información de esta transacción', null, () => {
                this.router.navigate(['/user/profile']);
              });
            }
          });
        });
      }
    });
  }
}
