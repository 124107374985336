import { createAction, props } from '@ngrx/store';
import { ITransactionData } from '../interfaces/itransactions-filter';

export const SET_TRANSACTIONS = createAction('[TRANSACTIONS] set transactions data', props<{ payload: ITransactionData[], payload2: number }>());

export const ADD_TRANSACTIONS = createAction('[TRANSACTIONS] add transactions data', props<{ payload: ITransactionData[] }>());

export const ADD_TRANSACTION = createAction('[TRANSACTIONS] add transaction data', props<{ payload: ITransactionData }>());

export const REMOVE_TRANSACTION = createAction('[TRANSACTIONS] remove transaction data', props<{ payload: any }>());
