import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent extends BaseComponent implements OnInit {

  constructor(
    private authService: MsalService,
  ) { 
    super();
  }

  ngOnInit() {
    this.authService.logoutRedirect();
  }

}
