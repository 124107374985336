export enum MessageTypes {
  error = 'error',
  warning = 'warning',
  info  = 'info',
  success = 'success',
}

export enum MessageIcons {
  error = 'times-circle',
  warning = 'exclamation-triangle',
  info  = 'info-circle',
  success = 'check-circle',
}

export interface IMessageDialogData {
  type: MessageTypes;
  message: string;
  details: {[p: string]: string[]};
  icon: MessageIcons;
  actions: {
    cancelCallback: Function,
    continueCallback: Function
  };
  labels?: {
    cancelLabel?: string;
    continueLabel?: string;
    hasClose?: boolean;
  };
  detailsOpened?: boolean;
}
