import { IPaymentChannel } from "../interfaces/ipayment-channel";

export const PAYMENT_CHANNELS: IPaymentChannel[] = [
  {
    key: "Online",
    name: "Pagar en Línea",
    action: "Enable",
    canOnlyPB: true,
    message: null
  }
];


export const PAYMENT_CHANNELS_CAT = {
  online: 'Online',
  flywire: 'Flywire',
  codi: 'CoDi',
  onlinePB: 'OnlinePB',
  mercadopago: 'MercadoPago'
}

export const POSITIVE_BALANCE_CHANNEL: IPaymentChannel =
{
  key: "OnlinePB",
  name: "Pago con saldo a favor",
  action: "Enable",
  canOnlyPB: true,
  message: null
};
