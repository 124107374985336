import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericDocumentResolve } from '../resolver/generic-document/generic-document.resolve';
import { GenericDocumentFactory } from './factories/generic-document-factory';
import { DocumentFactory } from './factories/document-factory';
import { DocumentControllFactory } from './factories/document-controll-factory';
import { DocumentFacade } from './facades/document-facade';
import { GenericDocumentCacheResolve } from '../resolver/generic-document/generic-document-cache.resolve';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    DocumentFacade,
    GenericDocumentResolve,
    GenericDocumentCacheResolve,
    GenericDocumentFactory,
    DocumentFactory,
    DocumentControllFactory
  ]
})
export class DocumentModule { }
