import { IDocument } from "../interfaces/idocument";
import { IPaymentDataModel } from "../../../interfaces/generic-document/ipayment.model";
import { IParamModel } from "../../../interfaces/generic-document/iparam.model";
import { ISectionModel } from "../../../interfaces/generic-document/isection.model";

export class Documento implements IDocument {

    documentId: string;
    name: string;
    description: string;
    documentType: string;
    parameters: IParamModel[];
    sections: ISectionModel[];
    payment: IPaymentDataModel;

    getId(): string {
        return this.documentId;
    }

    getName(): string {
        return this.name;
    }

    getDescription(): string {
        return this.description;
    }

    getType(): string {
        return this.documentType;
    }

    hasParams(): boolean {
        return (this.parameters != null && typeof this.parameters !== 'undefined');
    }

    getParams(): IParamModel[] {
        return this.hasParams() ? this.parameters : [];
    }

    hasSections(): boolean {
        return (this.sections != null && typeof this.sections !== 'undefined');
    }

    getSections(): ISectionModel[] {
        return this.hasSections() ? this.sections : [];
    }

    hasParam(key: string): boolean {
        return typeof this.parameters.find(p => p.parameterId === key) !== 'undefined';
    }

    getParam(key: string): IParamModel {
        return this.hasParam(key) ? this.parameters.find(p => p.parameterId === key) : null;
    }

    hasSection(key: string): boolean {
        return typeof this.sections.find(s => s.sectionId === key) !== 'undefined';
    }

    getSection(key: string): ISectionModel {
        return this.hasSection(key) ? this.sections.find(s => s.sectionId === key) : null;
    }

    hasPayment(): boolean {
        if (this.payment != null && typeof this.payment !== 'undefined') {
            return !!this.payment.paymentAllow;
        }
        return false;
    }

    getPayment(): IPaymentDataModel {
        return this.hasPayment() ? this.payment : null;
    }

    getPaymentFields(): string[] {
        return this.hasPayment() ? (this.hasPaymentFields() ? this.payment.paymentFields : []) : [];
    }

    hasAgreementMessage(): boolean {
        // return true;
        return this.hasPayment() ? (typeof this.payment.agreementMessage !== 'undefined' && this.payment.agreementMessage != null && this.payment.agreementMessage != '') : false;
    }

    getAgreementMessage(): string {
        // return `Una vez pagada tu constancia no habrá cambios en la información elegida.<br><br>
        // ¿Estás de acuerdo con los datos que seleccionaste para tu constancia?`;
        return this.hasAgreementMessage() ? this.payment.agreementMessage : '';
    }

    getSectionParams(): IParamModel[] {
        return this.hasSections() ? this.sections.reduce((params: IParamModel[], section) => [...params, ...section.parameters], []) : [];
    }

    getAllParams(): IParamModel[] {
        const allParams = [...this.getParams(), ...this.getSectionParams()];
        return allParams != null && typeof allParams !== 'undefined' ? allParams : [];
    }

    hasNotFixedSections() {
        return this.getSections().filter(s => s.type != 'fixed').length > 0;
    }

    needUserInteraction(): boolean {
        let needUser = false;

        try {
            if (this.getAllParams().length > 0) {
                needUser = true;
            }
            if (this.hasNotFixedSections()) {
                needUser = true;
            }
        }
        catch (Exception) {
            console.log('FATAL ERROR: ', Exception)
        }

        return needUser;
    }

    private hasPaymentFields(): boolean {
        return this.payment.paymentFields != null && typeof this.payment.paymentFields !== 'undefined';
    }
}