import { createReducer, on } from '@ngrx/store';
import { NAVIGATION_STATE } from './navigation.state';
import { SET_CONFIG, SET_CURRENT } from './navigation.actions';
import { INavigationState } from '../interfaces/inavigation-state';

const _navigationReducer = createReducer(NAVIGATION_STATE,
    on(SET_CONFIG, (state: INavigationState, { payload }) => ({ ...state, config: payload })),
    on(SET_CURRENT, (state: INavigationState, { payload }) => ({ ...state, current: payload }))
);

export function NavigationReducer(state, action) {
    return _navigationReducer(state, action);
}