import { Injectable } from "@angular/core";
import { IDocumentMetadataModel } from "src/app/core/interfaces/generic-document/idocument-metadata.model";
import { DocumentParam } from "../models/document.param";
import { DocumentSection } from "../models/document.section";
import { IParamModel } from "src/app/core/interfaces/generic-document/iparam.model";
import { ISectionModel } from "src/app/core/interfaces/generic-document/isection.model";
import { IDocumentControl } from "../interfaces/idocument.control";
import { DocumentControl } from "../models/document.control";
import { SectionControl } from "../models/setion.control";
import { ISectionControl } from "../interfaces/isection-control";
import { IDocumentSection } from "../interfaces/idocument.section";


@Injectable()
export class DocumentControllFactory {
    /**
     * Build a navigation node
     * @param metadata
     */
    create(metadata: IDocumentMetadataModel): IDocumentControl {
        const control = new DocumentControl();
//        console.log('DocumentControllFactory metadata: ', metadata);

        control.metadata = metadata;
        
        control.documentParams = this.createParamenters(metadata.parameters);
        control.sectionControls = this.groupSectionsByOrder(metadata.sections);
        control.setDefaultSections(this.getFirstOptionFromSectionsControls(control.sectionControls));
        return control;
    }

    /**
   * Group sections by order for build multiple options for a section in HTML template
   * Sections with same order are grouped in to same array
   * @param sections
   */
    groupSectionsByOrder(sections: ISectionModel[]) {
        return sections
            .reduce((sectionControls, section) => {
                let sectionControl = sectionControls.find(control => section.order === control.order);
                if (!sectionControl) {
                    sectionControl = new SectionControl();
                    sectionControl.order = section.order;
                    sectionControl.type = section.type;
                    sectionControls.push(sectionControl);
                }
                sectionControl.sections.push(section);
                return sectionControls;
            }, []);
    }

    getFirstOptionFromSectionsControls(sectionControls: ISectionControl[]): IDocumentSection[] {
        return sectionControls.reduce((sections, sectionControl) => {
            const [first] = sectionControl.sections;
            return first ? [...sections, first] : sections;
        }, []);
    }



    createParamenters(parameters: IParamModel[]): DocumentParam[] {
        let params: DocumentParam[] = [];

        parameters.forEach((param: IParamModel) => {
            params.push(this.createParam(param));
        });

        return params;
    }

    createParam(properties: IParamModel): DocumentParam {
        const node = new DocumentParam();
        const { parameterId, name, required, data, type } = properties;

        node.parameterId = parameterId;
        node.name = name;
        node.required = required;
        node.data = data;
        node.type = type;

        return node;
    }

    createSections(parameters: ISectionModel[]): DocumentSection[] {
        let params: DocumentSection[] = [];

        parameters.forEach((param: ISectionModel) => {
            params.push(this.createSection(param));
        });

        return params;
    }

    createSection(properties: ISectionModel): DocumentSection {
        const node = new DocumentSection();
        const { sectionId, content, name, type, order, location, parameters } = properties;

        node.sectionId = sectionId;
        node.content = content;
        node.name = name;
        node.type = type;
        node.order = order;
        node.location = location;
        node.parameters = this.createParamenters(parameters);

        return node;
    }

}
