import { createAction, props } from '@ngrx/store';
import { IAzureAD, IAuthBot } from '../interfaces/ichatbot-status';

export const SET_CHATBOT = createAction('[CHATBOT] set CHATBOT data', props<{ payload: boolean }>());

export const ADD_CHANELID = createAction('[CHATBOT] add CHANELID data', props<{ payload: string }>());

export const ADD_AZUREAD = createAction('[CHATBOT] add AZUREAD data', props<{ payload: IAzureAD }>());

export const ADD_AUTHBOT = createAction('[CHATBOT] add AUTHBOT data', props<{ payload: IAuthBot }>());

export const REMOVE_CHATBOT = createAction('[CHATBOT] remove CHATBOT data', props<{ payload: boolean }>());
