import { Routes } from '@angular/router';
import { PaymentSummaryComponent } from './pages/summary/summary.component';

export const PAYMENTS_SUMMARY_ROUTES: Routes = [
  {
    path: ':id',
    data: {
      title: 'Resumen de Pago',
    },
    component: PaymentSummaryComponent
  }
];
