import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { PaymentFacade } from '../../../../../core/data/payment/facades/payment-facade';
import { NavigationService } from '../../../../../core/services/navigation/navigation.service';
import { mapRoutes } from '../../../catalogs/services';
import { IPaymentState } from 'src/app/core/data/payment/interfaces/ipayment-status';
import { Observable, of } from 'rxjs';

export const _URL_INIT: string = '/user/profile';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-payment-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PaymentPayComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  previusRoute = '';
  currentRoute = '';

  payment$: Observable<IPaymentState>;


  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private paymentFacade: PaymentFacade,
  ) {
    super();
    //console.log('INIT PaymentPayComponent');
    this.paymentFacade.payment$.pipe(untilDestroyed(this)).subscribe((payment: IPaymentState) => this.payment$ = of(payment));
    this.initUrls();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.payment$.pipe(untilDestroyed(this)).subscribe((payment: IPaymentState) => {
      if (payment && payment.active) {
        this.selectOperationToDo(<IPaymentState>JSON.parse(JSON.stringify(payment)));
      } else {
        this.router.navigateByUrl(_URL_INIT)
        return;
      }
    });
  }

  ngOnDestroy(): void {
    this.payment$ = null;
  }

  selectOperationToDo(payment: IPaymentState) {
    this.initUrls();
    let moduleToWork = payment.process ? payment.process.module : null;
    if (moduleToWork == null) {
      this.router.navigateByUrl(_URL_INIT)
      return;
    }

    let routeFrom = typeof mapRoutes.find(mr => mr.id == moduleToWork) === 'undefined' ? null : mapRoutes.find(mr => mr.id == moduleToWork).route;
    if (routeFrom == null) {
      this.router.navigateByUrl(_URL_INIT)
      return;
    }

    if (routeFrom != this.previusRoute) {
      this.router.navigateByUrl(_URL_INIT);
      return;
    }
    this.router.navigate(['payment/pay/apply', payment.paymentOrderId]);
  }

  initUrls() {
    this.currentRoute = this.navigationService.getCurrentUrl();
    this.previusRoute = this.navigationService.getPreviousUrl();
  }

}
