import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../../../../ui/services/assets/assets.service';
import { MediaObserver } from '@angular/flex-layout';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  availableImages = [];
  slides = [];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  constructor(private assetsService: AssetsService, public media: MediaObserver) { }

  ngOnInit() {
    this.assetsService.getCarousel().pipe(untilDestroyed(this)).subscribe((response: any) => {
      this.availableImages = response;
      this.slides = this.getVisibleSlides();
    });

    this.media.asObservable().pipe(untilDestroyed(this)).subscribe(() => {
      this.slides = this.getVisibleSlides();
    });
  }

  getVisibleSlides() {
    return (this.media.isActive('xs') || this.media.isActive('sm'))
      ? this.availableImages.filter(name => name.includes('sm')).map(blob => this.isForMobile(blob))
      : this.availableImages.filter(name => name.includes('lg')).map(blob => this.isForWeb(blob));
  }

  isForMobile(name: string) {
    const image = (<Endpoint>this.assetsService.getCarouselEndpoint()).useWildcardParams({ file: `sm/${name}` }).url;
    return image;
  }

  isForWeb(name: string) {
    const image = (<Endpoint>this.assetsService.getCarouselEndpoint()).useWildcardParams({ file: `lg/${name}` }).url;
    return image;
  }

  slidesRange() {
    return [...Object.keys(this.slides)];
  }
}
