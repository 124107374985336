import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { provideAnimations } from '@angular/platform-browser/animations';

if (environment.production) {
  enableProdMode();
}

export var config: any = {};

const httpClient = new HttpClient(
  new HttpXhrBackend({ build: () => new XMLHttpRequest() })
);
let promise = firstValueFrom(
  httpClient.get(window.location.origin + '/assets/data/config.json')
);

function bootstrapFailed(val) {
  document.getElementById('bootstrap-fail').style.display = 'block';
  console.error('bootstrap-fail', val);
}

promise.then((_config: any) => {
  window.localStorage.setItem('__config', JSON.stringify(_config));
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      providers: [
        provideAnimations(),

      ],
    })
    .catch(bootstrapFailed);
});
