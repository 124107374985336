import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations';


export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      'min-width': '74px'
    })
  ),
  state('open',
    style({
      'min-width': '270px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);


export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '74px'
    })
  ),
  state('open',
    style({
      'margin-left': '272px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);


export const animateText = trigger('animateText', [
  state('hide',
    style({
      'display': 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      'display': 'block',
      opacity: 1,
    })
  ),
  transition('hide => show', animate('1500ms ease-in')),
  transition('show => hide', animate('1500ms ease-out')),
]);

export const animateFadeInOut = trigger('animateFadeInOut', [
  transition(':enter', [   // :enter is alias to 'void => *'
    style({ opacity: 0 }),
    animate(500, style({ opacity: 1 }))
  ])
]);