import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CancelDialogComponent } from './components/cancel-dialog/cancel-dialog.component';
import { BankBridgeModule } from '../payment/modules/bank-bridge/bank-bridge.module';
import { PaymentSummaryModule } from './summary/summary.module';
import { PaymentPayModule } from './pay/pay.module';
import { BankBridgeNewModule } from './modules/bank-bridge-v2/bank-bridge.v2.module';
import { FlywireModule } from './modules/flywire/flywire.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PaymentRoutingModule,
    BankBridgeModule,
    BankBridgeNewModule,
    PaymentSummaryModule,
    PaymentPayModule,
    FlywireModule
  ],
  declarations: [
    CancelDialogComponent,
  ],
  entryComponents: [
    CancelDialogComponent
  ],
  exports: [
    PaymentSummaryModule,
    PaymentPayModule
  ]
})
export class PaymentModule { }
