import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { IPaymentState, IPaymentData, IProcessInfo } from '../interfaces/ipayment-status';
import { getState } from '../redux/payment.selectors';
import { SET_PAYMENT, ADD_PAYMENT, REMOVE_PAYMENT, ADD_PROCESS, REMOVE_PROCESS } from '../redux/payment.actions';

@Injectable()
export class PaymentFacade {

  private state$: Observable<IPaymentState> = this.store.select(getState);
  all$: Observable<IPaymentData> = this.state$.pipe(select(state => state.payment));
  _lastPaymentSource: BehaviorSubject<IPaymentData> = new BehaviorSubject(null);
  last$: Observable<IPaymentData> = this._lastPaymentSource.asObservable();

  payment$: Observable<IPaymentState> = this.state$.pipe(
    select(state => state),
    filter(payment => !!payment && payment.paymentOrderId != '')
  );

  constructor(private store: Store<IPaymentData>) { }

  setPayment(payment: IPaymentData, active: boolean, paymentId: string, process: IProcessInfo = null) {
    this.store.dispatch(SET_PAYMENT({payload: payment, active: active, paymentOrderId: paymentId, process: process}));
    this._lastPaymentSource.next(payment);
  }

  addPayment(payment: IPaymentData) {
    this.store.dispatch(ADD_PAYMENT({payload:payment}));
    this._lastPaymentSource.next(payment);
  }

  removePayment(payment: IPaymentData) {
    this.store.dispatch(REMOVE_PAYMENT({payload: payment}));
  }

  setProcess(process: IProcessInfo) {
    this.store.dispatch(ADD_PROCESS({payload: process}));
  }

  removeProcess(process: IProcessInfo) {
    this.store.dispatch(REMOVE_PROCESS({payload: process}));
  }
}
