import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterContainerComponent } from './components/filter-container/filter-container.component';
import { UIModule } from '../../ui/ui.module';
import { ResetFieldComponent } from './components/reset-field/reset-field.component';
import { SearchStudentFormComponent } from './search-forms/search-student-form/search-student-form.component';
import { RemoveAccentsPipe } from './pipes/remove-accents.pipe';
import { InputFormatDirective } from './directives/input-format.directive';
import { UpdateTutorEmailComponent } from './components/update-tutor-email/update-tutor-email.component';

@NgModule({
  imports: [
    CommonModule,
    UIModule,
  ],
  declarations: [
    FilterContainerComponent,
    ResetFieldComponent,
    SearchStudentFormComponent,
    RemoveAccentsPipe,
    InputFormatDirective,
    UpdateTutorEmailComponent,
  ],
  exports: [
    FilterContainerComponent,
    ResetFieldComponent,
    SearchStudentFormComponent,
    RemoveAccentsPipe,
    InputFormatDirective,
    UpdateTutorEmailComponent,
  ],
  providers: [
    RemoveAccentsPipe,
  ]
})
export class FormModule { }
