import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxExtendedPdfViewerVVComponent } from './ngx-extended-pdf-viewer-vv.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NgxExtendedPdfViewerVVComponent],
  exports: [NgxExtendedPdfViewerVVComponent]
})
export class NgxExtendedPdfViewerVVModule {}
