import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable, Subject, Subscription } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { NotificationFacade } from "src/app/core/data/notification/facades/notification-facade";
import { INotificationData } from "src/app/core/data/notification/interfaces/inotification-data";
import { UserFacade } from "src/app/core/data/user/facades/user-facade";
import { IUserData } from "src/app/core/data/user/interfaces/iuser-data";
import { IMinResponse, IResponse } from "src/app/core/http/interfaces/iresponse";
import { MessageService } from "src/app/core/services/message/message.service";
import { PAYMENT_CHANNELS_CAT } from "src/app/modules/payment/catalogs/payment-channels";
import { PaymentService } from "src/app/modules/payment/services/payment.service";
import { IEventPyment } from "../../../bank-bridge/interfaces/ievent-payment";
import { IFlywireEventModel, IFlywireStudenData } from "../../interfaces/iflywire";
import { BaseComponent } from "src/app/shared/layouts/components/base-component/base-component.component";
declare var BBFlywire: any;

export interface INotificationFlywireData {
	flywireRequestId: string;
	request: string;
	additionalData: string;
}

export interface IFlywireData {
	jwt: string;
	orderId: number;
}

@Component({
	selector: "app-request-flywire-payment",
	templateUrl: "./request-payment.component.html",
	styleUrls: ["./request-payment.component.scss"],
})
export class RequestPaymentComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input("flywireRequest") flywireRequest: IFlywireData;
	@Input("paymentId") paymentId: string;
	@Input("amount") amount: number;
	@Input("flywireStudentData") flywireStudentData: IFlywireStudenData = {} as IFlywireStudenData;
	@Output() complete: EventEmitter<IEventPyment> = new EventEmitter();

	isEventEmitted = false;

	StudenData: IFlywireStudenData;

	BBFlywire = BBFlywire;

	notificationSubscriptor: Subscription = null;
	flywireNotifications$: Observable<INotificationData> = null;
	timeOut: NodeJS.Timer | null = null;

	profile: IUserData;

	fatalError = false;
	private destroyed$: Subject<{}> = new Subject();

	isFlywireNotificationHere = false;

	timerSub: NodeJS.Timer | null = null;

	constructor(private messageService: MessageService, private notificationFacade: NotificationFacade, private paymentService: PaymentService, private userFacade: UserFacade) {
		super();
		this.flywireNotifications$ = this.notificationFacade.getLastNotificationFor("payment.flywire.notification");
		this.userFacade.profile$.subscribe((profile) => (this.profile = profile));
	}

	ngOnInit(): void {
		this.init();
		this.handleNotifications(this.flywireNotifications$, "notificationCompleted");
	}

	ngOnDestroy(): void {
		this.onDestroying();
	}

	init() {
		//console.log('RequestPaymentComponent: ', this.BBFlywire);
		this.clearLocalStorage();
		if (this.flywireRequest == null || typeof this.flywireRequest === 'undefined') {
			this.complete.emit({ success: false, event: "error", data: null, paymentMethode: PAYMENT_CHANNELS_CAT.flywire });
			return;
		}
		this.continueWithPayment();
	}

	clearLocalStorage() {
		this.isEventEmitted = false;
	}

	notificationCompleted(notificationData: INotificationFlywireData) {
		console.log("Flywire notificationCompleted: ", notificationData);
		this.isFlywireNotificationHere = true;
		const onComplete: IEventPyment = {
			success: true,
			event: "complete",
			data: <IMinResponse<any, any>>{ data: notificationData.request, additionalData: notificationData.additionalData },
			paymentMethode: PAYMENT_CHANNELS_CAT.flywire,
		};
		this.complete.emit(onComplete);
	}

	continueWithPayment() {
		this.StudenData = this.flywireStudentData;
		this.StudenData.jwt = this.flywireRequest.jwt;
		this.pay();
	}

	pay() {
		this.BBFlywire.init(this.StudenData, this.flywireRequest.orderId);
		this.BBFlywire.onEventComplete((event: IFlywireEventModel) => {
			this.timeOut = setTimeout(() => this.initRequestHandler(), 10000);
			if (event.action == "BBFWCANCEL") {
				const onCancel: IEventPyment = {
					success: false,
					event: "cancel",
					data: <IMinResponse<any, any>>{ data: event.data, additionalData: event.type },
					paymentMethode: PAYMENT_CHANNELS_CAT.flywire,
				};
				this.emitEvent(onCancel);
			} else if (event.action == "BBFWCOMPLETE") {
				const onComplete: IEventPyment = {
					success: true,
					event: "complete",
					data: <IMinResponse<any, any>>{ data: event.data, additionalData: event.type },
					paymentMethode: PAYMENT_CHANNELS_CAT.flywire,
				};
				//this.emitEvent(onComplete);
			} else if (event.action == "BBFWINCOMPLETE") {
				const onComplete: IEventPyment = {
					success: true,
					event: "complete",
					data: <IMinResponse<any, any>>{ data: event.data, additionalData: event.type },
					paymentMethode: PAYMENT_CHANNELS_CAT.flywire,
				};
				this.emitEvent(onComplete);
			}
		});
	}

	emitEvent(event: IEventPyment) {
		if (!this.isEventEmitted) {
			this.isEventEmitted = true;
			this.clearLocalStorage();
		}
		if (event) {
			this.complete.emit(event);
		}
	}

	initRequestHandler() {
		this.handleRequest("isFlywireNotificationHere", this.paymentId, this.profile.id, "notificationCompleted");
		return;
	}

	handleNotifications(notificationObserver$: Observable<INotificationData>, callback: any) {
		this.handleNotification("isFlywireNotificationHere", notificationObserver$, `${this.flywireRequest.orderId}`, callback, "Ocurrio un error en la notificación de pago");
	}

	handleNotification(flag: string, notificationObserver$: Observable<INotificationData>, requestId: string | number, cbFname: any, errorMsg: string = "") {
		this.notificationSubscriptor = notificationObserver$.pipe(takeUntil(this.destroyed$)).subscribe((notification: INotificationData) => {
			if (!this[flag]) {
				if (notification != null && typeof notification !== "undefined") {
					if (`${notification.data.channelId}` === `${requestId}`) {
						if (notification.type == "success") {
							this[cbFname]({
								flywireRequestId: this.flywireRequest.orderId,
								request: notification.data.request,
								additionalData: notification.data,
							});
						} else if (notification.type == "error") {
							this.messageService.error(errorMsg, { details: [notification.message] });
							this[flag] = true;
							this.fatalError = true;
							this.onDestroying();
						}
					}
				}
			}
		});
	}

	handleRequest(flag: string, requestId: string, profileId: string, cbFname: string) {
		if (!this[flag]) {
			let isSuccess = true;
			this.paymentService
				.requestPaymentChannelStatus(requestId, profileId, `${this.flywireRequest.orderId}`, "flywire")
				.pipe(
					takeUntil(this.destroyed$),
					finalize(() => {
						if (!isSuccess) this.timeOut = setTimeout(() => this.handleRequest(flag, requestId, profileId, cbFname), 30000);
					})
				)
				.subscribe(
					(data: IResponse<any>) => {
						isSuccess = false;
						if (data) {
							if (data.httpStatusCode != 200) {
								if (data.message) {
									this.messageService.info(`${data.message} Si cancelaste la operación haz caso omiso de este mensaje.`, null, () => {
										const onCancel: IEventPyment = {
											success: false,
											event: "cancel",
											data: <IMinResponse<any, any>>{ data: "", additionalData: "NOMESSAGE" },
											paymentMethode: PAYMENT_CHANNELS_CAT.flywire,
										};
										this.emitEvent(onCancel);
									});
								}
							} else {
								if (data.data) {
									if (Guid.isGuid(data.data)) {
										if (!Guid.parse(data.data).isEmpty()) {
											isSuccess = true;
											this[cbFname]({
												flywireRequestId: this.flywireRequest.orderId,
												request: data.data,
												additionalData: data.additionalData,
											});
										}
									}
								}
							}
						}
					},
					(error: any) => {
						isSuccess = false;
					}
				);
		}
	}

	private onDestroying() {
		this.destroyed$.next({});
		this.clearTimeout();
	}

	private clearTimeout() {
		if (this.timeOut) {
			clearTimeout(this.timeOut);
		}
		if (this.timerSub) {
			clearTimeout(this.timerSub);
		}
	}
}
