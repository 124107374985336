import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { IQueueData } from '../interfaces/iqueue-data';
import { IAuthData } from '../interfaces/iauth-data';
import { IUserState } from '../interfaces/iuser-state';
import { IUserData } from '../interfaces/iuser-data';
import { Observable } from 'rxjs';
import { getSatate } from '../redux/user.selector';
import { SET_PROFILE, SET_PROFILE_PICTURE, SET_SSEL_TOKEN, SET_AUTH_DATA, SET_PERMISSIONS, SET_QUEUE_DATA } from '../redux/user.actions';

@Injectable()
export class UserFacade {

  private state$: Observable<IUserState> = this.store.select(getSatate);

  session$: Observable<IAuthData> = this.state$.pipe(
    select(state => state.auth),
    filter(auth => !!auth)
  );

  queue$: Observable<IQueueData> = this.state$.pipe(select(state => state.queue));

  profile$: Observable<IUserData> = this.state$.pipe(
    select(state => state.profile),
    filter(profile => !!profile)
  );

  picture$: Observable<string> = this.state$.pipe(
    select(state => state.picture),
    filter(picture => picture != null && typeof picture !== 'undefined' && picture != '')
  );

  auth$: Observable<IAuthData> = this.state$.pipe(
    select(state => state.auth),
    filter(auth => !!auth)
  );

  SSELToken$: Observable<string> = this.state$.pipe(select(state => state.SSELToken));

  constructor(private store: Store<IUserState>) { }

  setProfile(profile: IUserData) {
    this.store.dispatch(SET_PROFILE({ payload: profile }));
  }

  setProfilePicture(picture: string) {
    this.store.dispatch(SET_PROFILE_PICTURE({ payload: picture }));
  }

  setSSELToken(token: string) {
    this.store.dispatch(SET_SSEL_TOKEN({ payload: token }));
  }

  setAuthData(data: string) {
    this.store.dispatch(SET_AUTH_DATA({ payload: data }));
  }

  setPermissions(permissions) {
    this.store.dispatch(SET_PERMISSIONS({ payload: permissions }));
  }

  getPermissions(): Observable<any> {
    return this.store.select(getSatate)
      .pipe(select(state => state.permissions));
  }

  setQueueData(queueData: IQueueData) {
    this.store.dispatch(SET_QUEUE_DATA({ payload: queueData }));
  }
}
