import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsService } from './services/transactions.service';
import { TransactionFacade } from './facades/transaction-facade';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    TransactionsService,
    TransactionFacade
  ]
})
export class TransactionModule { }
