import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlywireRoutingModule } from './flywire-routing.module';
import { RequestPaymentComponent } from './pages/request-payment/request-payment.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    RequestPaymentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlywireRoutingModule
  ],
  exports: [
    RequestPaymentComponent
  ]
})
export class FlywireModule { }
