import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { IDocumentState, IDocumentsState, IRegulationState, IMetadataDoc, ISetSessionActive } from '../interfaces/idocument-status';
import { getState } from '../redux/document.selectors';
import { SET_DOCUMENT, SET_DOCUMENT_TYPE, SET_DOCUMENT_ID, SET_DOCUMENT_REGULATION, SET_DOCUMENTS, SET_SESSION, SET_METADATA, REMOVE_DOCUMENT } from '../redux/document.actions';

@Injectable()
export class DocumentFacade {

  private state$: Observable<IDocumentState> = this.store.select(getState);
  _lastConfirmationSource: BehaviorSubject<IDocumentState> = new BehaviorSubject(null);
  last$: Observable<IDocumentState> = this._lastConfirmationSource.asObservable();

  active$: Observable<boolean> = this.state$.pipe(
    select(state => state.active)
  );

  sessionActive$: Observable<boolean> = this.state$.pipe(
    select(state => state.sessionActive)
  );

  sessionId$: Observable<string> = this.state$.pipe(
    select(state => state.idSession)
  );

  document$: Observable<IDocumentState> = this.state$.pipe(
    select(state => state),
    filter(document => !!document)
  );

  documents$: Observable<IDocumentsState[]> = this.state$.pipe(
    select(state => state.documents),
    filter(documents => !!documents)
  );

  regulation$: Observable<IRegulationState> = this.state$.pipe(
    select(state => state.regulation),
    filter(regulation => !!regulation)
  );

  documentId$: Observable<string> = this.state$.pipe(
    select(state => state.idDocument),
    filter(idDocument => !!idDocument)
  );

  documentType$: Observable<string> = this.state$.pipe(
    select(state => state.documentType),
    filter(documentType => !!documentType)
  );

  metadata$: Observable<IMetadataDoc> = this.state$.pipe(
    select(state => state.metadata),
    filter(metadata => !!metadata)
  );

  constructor(private store: Store<IDocumentState>) { }

  setDocument(document: IDocumentState) {
    this.store.dispatch(SET_DOCUMENT({ payload: document }));
    this._lastConfirmationSource.next(document);
  }

  setDocumentType(documentType: any) {
    this.store.dispatch(SET_DOCUMENT_TYPE({ payload: { type: documentType, active: true } }));
  }

  setDocumentId(documentId: string) {
    this.store.dispatch(SET_DOCUMENT_ID({ payload: { documentId: documentId, active: true } }));
  }

  setDocumentRegulation(regulation: IRegulationState) {
    this.store.dispatch(SET_DOCUMENT_REGULATION({ payload: regulation }));
  }

  setDocuments(documents: IDocumentsState[]) {
    this.store.dispatch(SET_DOCUMENTS({ payload: documents }));
  }

  setSession(session: boolean, sessionId: string) {
    let payload: ISetSessionActive = {
      sessionActive: session,
      sessionId: sessionId
    }
    this.store.dispatch(SET_SESSION({ payload }));
  }

  setMetadata(metadata: IMetadataDoc) {
    this.store.dispatch(SET_METADATA({ payload: metadata }));
  }

  removeDocument() {
    this.store.dispatch(REMOVE_DOCUMENT({ payload: {} }));
  }

}
