import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAYMENTS_PAY_ROUTES } from './pay.routes';


@NgModule({
  imports: [RouterModule.forChild(PAYMENTS_PAY_ROUTES)],
  exports: [RouterModule]
})
export class PaymentPayRoutingModule { }
