import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ILevelFile } from '../../interfaces/iinformative-files.interface';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-files-level',
  templateUrl: './files-level.component.html',
  styleUrls: ['./files-level.component.scss']
})
export class FilesLevelComponent implements OnInit, OnChanges {

  /**
   * @param withExcluded
   * @type boolean
   * @default false
   * Parametro para indicar si se debe bloquear la seleccion de niveles excluidos
   */
  @Input('withExcluded') withExcluded: boolean = false;
  /**
   * @param campusExcluded
   * @type string[]
   * @default []
   * Lista de niveles que no se pueden seleccionar
   */
  @Input('levelsExcluded') levelsExcluded: string[] = [];

  /**
   * @param levelList
   * @type ICareerFile[]
   * @default []
   * Lista completa de carreras para el combo
   */
  @Input('levelList') levelList: ILevelFile[] = [];
  /**
   * @param levelSelected
   * @type string[]
   * @default []
   * Lista de niveles seleccionadas
   */
  @Input('levelSelected') levelSelected: string[] = [];
  /**
   * @param campusFilter
   * @type string[]
   * @default []
   * Lista de campus para filtrar los niveles
   */
  @Input('campusFilter') campusFilter: string[] = [];
  /**
   * @param useFilters
   * @type boolean
   * @default false
   * Parametro para indicar que se deben utilizar los filtros de campus
   */
  @Input('useFilters') useFilters: boolean = false;
  /**
   * @param readonly
   * @type boolean
   * @default false
   * Si el combo tiene la funcionalidad de solo lectura o permite seleccionar y deseleccionar opciones
   */
  @Input('readonly') readonly: boolean = false;
  /**
   * @param withId
   * @type boolean
   * @default false
   * Para mostrar las opciones del combo como pareja Id - Nombre
   */
  @Input('withId') withId: boolean = false;
  /**
   * @emits onSelectionChanged
   * @type string[]
   * @default []
   * Evento que se emite cuando ocurre un cambio en la selección de opciones del combo
   */
  @Output('onSelectionChanged') onSelectionChanged: EventEmitter<string[]> = new EventEmitter();

  /**
   * @property _levelFiltered
   * @type ICareerFile[]
   * @default []
   * Lista de niveles filtradas para omitir combinaciones levelId - levelName
   */
  _levelFiltered: ILevelFile[] = [];
  /**
   * @property _levelSelectedFiltered
   * @type string[]
   * @default []
   * Lista de niveles filtradas para omitir combinaciones levelId - levelName
   */
  _levelSelectedFiltered: string[] = [];

  /**
     * @field levelControl
     * Control de formulario para combo de niveles
     */
  levelControl: FormControl = new FormControl();
  /**
   * @property initilizedView
   * @type boolean
   * @default false
   * Bandera para inicializar la vista
   */
  initilizedView: boolean = false;
  constructor(

  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
  }

  init() {
    this.levelList = (this.levelList != null && typeof this.levelList !== 'undefined') ? this.levelList : [];
    this.levelSelected = (this.levelSelected != null && typeof this.levelSelected !== 'undefined') ? this.levelSelected : [];

    this.levelList.forEach((level: ILevelFile, index: number) => {
      if (typeof this._levelFiltered.find(c => c.levelId == level.levelId && c.levelName == level.levelName) === 'undefined') {
        this._levelFiltered.push(level);
      }
    });
    this.levelSelected.forEach((level: string, index: number) => {
      if (typeof this._levelSelectedFiltered.find(c => c == level) === 'undefined') {
        this._levelSelectedFiltered.push(level);
      }
    });
    this.levelControl.setValue(this._levelSelectedFiltered);
    this.selectionChanged();
    this.initilizedView = true;
  }

  selectionChanged() {
    this.levelControl.valueChanges.pipe(untilDestroyed(this)).subscribe((values: any) => {
      //console.log(values);
      this._levelSelectedFiltered = values;
      this.onSelectionChanged.emit(values);
    });
  }

}
