import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../config/services/config.service';

@Injectable()
export class IPInterceptor implements HttpInterceptor {
  blockUris: string[] = [];
  constructor(
    private auth: AuthService,
    private configService: ConfigService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentIp = window.localStorage.getItem('__current_ip');

    if (!currentIp)
      return next.handle(req);

    this.blockUris.push(this.configService.get('bankbridge.url', ''));
    this.blockUris.push(this.configService.get('ip.url', ''));

    let skipInterceptor = false;
    this.blockUris.forEach(url => {
      if (req.url.includes(url)) {
        skipInterceptor = true;
        return;
      }
    });
    if (skipInterceptor)
      return next.handle(req);

    const ipInfo = currentIp;
    const request = req.clone({
      setHeaders: {
        AuthorizationIP: `${ipInfo}`
      }
    });
    return next.handle(request);
  }

}
