import { IDocumentState } from "../interfaces/idocument-status";

export const DOCUMENT_STATE: IDocumentState = {
    idDocument: null,
    documentType: null,
    active: false,
    sessionActive: false,
    idSession: null,
    regulation: null,
    documents: null,
    metadata: null
};
