import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ConfirmationFacade } from './facades/confirmation-facade';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    ConfirmationFacade
  ]
})
export class ConfirmationModule { }
