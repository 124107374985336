import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { ITransactionState, ITransactionData } from '../interfaces/itransactions-filter';
import { getState } from '../redux/transaction.selectors';
import { SET_TRANSACTIONS, ADD_TRANSACTION, REMOVE_TRANSACTION } from '../redux/transaction.actions';

@Injectable()
export class TransactionFacade {

  private state$: Observable<ITransactionState> = this.store.select(getState);
  all$: Observable<ITransactionData[]> = this.state$.pipe(select(state => state.transactions));
  _lastTransactionSource: BehaviorSubject<ITransactionData> = new BehaviorSubject(null);
  last$: Observable<ITransactionData> = this._lastTransactionSource.asObservable();

  transactions$: Observable<{ data: ITransactionData[], total: number }> = this.state$.pipe(
    select(state => ({ data: state.transactions, total: state.total })),
    filter((transaction: { data: ITransactionData[], total: number }) => !!transaction)
  );

  constructor(private store: Store<ITransactionState>) { }

  setTransactions(transactions: ITransactionData[], total: number) {
    this.store.dispatch(SET_TRANSACTIONS({ payload: transactions, payload2: total }));
  }

  addTransaction(transaction: ITransactionData) {
    this.store.dispatch(ADD_TRANSACTION({ payload: transaction }));
    this._lastTransactionSource.next(transaction);
  }

  remove(transaction: ITransactionData) {
    this.store.dispatch(REMOVE_TRANSACTION({ payload: transaction }));
  }
}
