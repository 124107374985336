import { INavigationNode, INavigationNodeModel } from '../interfaces/inavigation-node';
import { NAVIGABLE_ROUTES } from '../navigable';
import { ActionType, INavigationNodeAdditionalData, INavigationNodeData, TargetTypes, INavigationNodeDataModel, INavigationNodeDataStyle } from '../interfaces/inavigation-node-data';

export class NavigationNode implements INavigationNode {

  rules?: import("../interfaces/inavigation-node-data").IRule[];
  style?: INavigationNodeDataStyle;
  action: ActionType;
  children: INavigationNode[];
  iconKey: string;
  id: string;
  text: string;
  translateKey: string;
  config: INavigationNodeData;
  isOpen: boolean = false;
  data: INavigationNodeAdditionalData;
  private urlRegex = /^http[s]{0,1}:\/\/.+?$/gi;

  getUrl(): string | null {
    if (this.hasExternalUrl()) {
      return this.data.url;
    }
    const [parent, ...paths] = this.id.split('.');
    const id = paths.join('.');
    this.hasExternalUrl();
    return NAVIGABLE_ROUTES[id];
  }

  hasIcon(): boolean {
    return !!this.iconKey && !!this.iconKey.length;
  }

  getDisplayText(): string {
    return this.text;
  }

  hasChildren(): boolean {
    return !!this.children && !!this.children.length;
  }

  open() {
    if (this.hasChildren()) {
      this.isOpen = true;
    }
  }

  close() {
    this.isOpen = false;
  }

  isParent() {
    return this.hasChildren();
  }

  isLink() {
    return !this.hasChildren() && this.action === ActionType.url;
  }

  hasData() {
    return !!this.data;
  }

  hasExternalUrl() {
    return this.isLink() && this.hasData() && !!this.data.url.match(this.urlRegex).length;
  }

  isPopUp() {
    // return !this.hasChildren() && this.action === ActionType.popUp;
    return this.action === ActionType.popUp;
  }

  isMessage() {
    // return !this.hasChildren() && this.action === ActionType.message;
    return this.action === ActionType.message;
  }

  isDisabled() {
    // return !this.hasChildren() && this.action === ActionType.disabled;
    return this.action === ActionType.disabled;
  }

  hasMessage() {
    if (this.hasData())
      return !!this.data.message && !!this.data.message.length;

    return false;
  }

  getMessage() {
    if (this.hasData()) {
      return this.data.message;
    }
  }

  getTarget() {
    if (this.hasData()) {
      if (this.data.target === TargetTypes.blank) {
        return this.id;
      }
      return this.data.target;
    }
    return TargetTypes.blank;
  }

  hasRule(action: ActionType = null) {
    if (this.config.rules == null || typeof this.config.rules === 'undefined')
      return false;
    if (action)
      return typeof this.config.rules.find(rule => rule.action == action) !== 'undefined';

    return typeof this.config.rules.find(rule => rule.action == this.action) !== 'undefined';
  }

  getRule(action: ActionType = null) {
    if (action)
      return this.config.rules.find(rule => rule.action == action);

    return this.config.rules.find(rule => rule.action == this.action);
  }

  hasRuleData(action: ActionType = null) {
    if (this.hasRule(action)) {
      return !!this.getRule(action).data;
    }
    return false;
  }

  getRuleData(action: ActionType = null) {
    if (this.hasRuleData(action)) {
      return this.getRule(action).data;
    }
    return null;
  }

  hasRuleMessage() {
    if (this.hasRuleData()) {
      return !!this.getRuleData().message && !!this.getRuleData().message.length;
    }
    return false;
  }

  getRuleMessage() {
    if (this.hasRuleMessage()) {
      return this.getRuleData().message;
    }
    return null;
  }

  hasStyle() {
    if(this.style && this.style.cssstyle){
      return !!this.style.cssstyle && !!this.style.cssstyle.length;
    }
    return false;
  }

  getStyle() {
    return this.hasStyle() ? this.style.cssstyle : '';
  }

  hasSuperIndexText() {
    if(this.style && this.style.superindextext){
      return !!this.style.superindextext && !!this.style.superindextext.length;
    }
    return false;
  }

  getSuperIndexText() {
    return this.hasSuperIndexText() ? this.style.superindextext : '';
  }

  hasIconcolor() {
    if(this.style && this.style.iconcolor){
      return !!this.style.iconcolor && !!this.style.iconcolor.length;
    }
    return false;
  }

  getIconcolor() {
    return this.hasStyle() ? this.style.iconcolor : '';
  }

}


export class NavigationNodeModel implements INavigationNodeModel {
  action: ActionType;
  children: INavigationNodeModel[];
  iconKey: string;
  id: string;
  text: string;
  translateKey: string;
  config: INavigationNodeDataModel;
  isOpen: boolean = false;
  data: INavigationNodeAdditionalData;
  style: INavigationNodeDataStyle;
  private urlRegex = /^http[s]{0,1}:\/\/.+?$/gi;

  getUrl(): string | null {
    if (this.hasExternalUrl()) {
      return this.data.url;
    }
    const [parent, ...paths] = this.id.split('.');
    const id = paths.join('.');
    this.hasExternalUrl();
    return NAVIGABLE_ROUTES[id];
  }

  hasIcon(): boolean {
    return !!this.iconKey && !!this.iconKey.length;
  }

  getDisplayText(): string {
    return this.text;
  }

  hasChildren(): boolean {
    return !!this.children && !!this.children.length;
  }

  open() {
    if (this.hasChildren()) {
      this.isOpen = true;
    }
  }

  close() {
    this.isOpen = false;
  }

  isParent() {
    return this.hasChildren();
  }

  isLink() {
    return !this.hasChildren() && this.action === ActionType.url;
  }

  hasData() {
    return !!this.data;
  }

  hasExternalUrl() {
    return this.isLink() && this.hasData() && !!this.data.url.match(this.urlRegex).length;
  }

  isPopUp() {
    // return !this.hasChildren() && this.action === ActionType.popUp;
    return this.action === ActionType.popUp;
  }

  isMessage() {
    // return !this.hasChildren() && this.action === ActionType.message;
    return this.action === ActionType.message;
  }

  isDisabled() {
    // return !this.hasChildren() && this.action === ActionType.disabled;
    return this.action === ActionType.disabled;
  }

  hasMessage() {
    if (this.hasData())
      return !!this.data.message && !!this.data.message.length;

    return false;
  }

  getMessage() {
    if (this.hasData()) {
      return this.data.message;
    }
  }

  getTarget() {
    if (this.hasData()) {
      if (this.data.target === TargetTypes.blank) {
        return this.id;
      }
      return this.data.target;
    }
    return TargetTypes.blank;
  }

  hasStyle() {
    if(this.style && this.style.cssstyle){
      return !!this.style.cssstyle && !!this.style.cssstyle.length;
    }
    return false;
  }

  getStyle() {
    return this.hasStyle() ? this.style.cssstyle : '';
  }

  hasSuperIndexText() {
    if(this.style && this.style.superindextext){
      return !!this.style.superindextext && !!this.style.superindextext.length;
    }
    return false;
  }

  getSuperIndexText() {
    return this.hasSuperIndexText() ? this.style.superindextext : '';
  }

  hasIconcolor() {
    if(this.style && this.style.iconcolor){
      return !!this.style.iconcolor && !!this.style.iconcolor.length;
    }
    return false;
  }

  getIconcolor() {
    return this.hasIconcolor() ? this.style.iconcolor : '';
  }

}


