export const NAVIGABLE_ROUTES = {
  'home': '/user/profile',

  /**
   * @user
   */
  'user.profile': '/user/profile',

  'impersonate': '/impersonate',

  /**
   * @financial
   */
  'financial-info.bank-payments': '/financial-info/bank-payments',
  'financial-info.bank-payments-new': '/service-window/document/bank-reference',
  'financial-info.online-payments': '/financial-info/online-payments',
  'financial-info.tuition-calculator': '/financial-info/tuition-calculator',
  'financial-info.debts': '/financial-info/debts',
  'financial-info.billing': '/financial-info/billing',
  'financial-info.early-enrollment': '/financial-info/early-enrollment',
  'finantial-info.reenrollment': '/financial-info/reenrollment',

  /**
   * @school-services
   */
  'school-services.topic-list': '/school-services/topic-list',
  'school-services.topic-list-ssel': '/school-services/topic-list-ssel',
  'school-services.schedule': '/school-services/schedule',
  'school-services.history': '/school-services/history',
  'school-services.high-school-certificate': '/school-services/high-school-certificate',
  'school-services.attendance': '/service-window/document/attendance',
  'school-services.social-service': '/school-services/social-service/start',
  'school-services.school-calendar': '/school-services/school-calendar',
  'school-services.school-rules': '/school-services/school-rules',
  'school-services.next-schedule': '/school-services/next-schedule/start',
  'school-services.reinscription': '/school-services/re-enrollment-legacy',
  'school-services.re-enrollment': '/school-services/re-enrollment',
  'school-services.reprint-riel-ticket': '/school-services/reprint-riel-ticket',

  /**
   * @ServiceWindow
   */
  'service-window.supporting-document': '/service-window/supporting-document',
  'service-window.academic-historial': '/service-window/document/academic-historial',
  'service-window.report-card': '/service-window/regulation/report-card',
  'service-window.document-feature': '/service-window/document/document-feature',
  'service-window.supporting-document-feature': '/service-window/document/supporting-document',
  'service-window.class-withdraw': '/service-window/class-withdraw',
  'service-window.title-request': '/service-window/title-request',

  /**
   * @admin
   */
  'admin.tutors': '/admin/tutors',
  'admin.student-requests-campus': '/admin/student-requests/campus',
  'admin.student-requests-online': '/admin/student-requests/online',
  'admin.document-requests-historial': '/admin/document-requests/academic-historial',
  'admin.document-requests-historial-online': '/admin/document-requests/academic-historial-online',
  'admin.document-requests-supporting': '/admin/document-requests/supporting-document',
  'admin.config-menu': '/admin/menu-admin',
  'admin.schoolar-service-manager': '/admin/schoolar-service-manager',
  'admin.schoolar-service-jobs': '/admin/schoolar-service-manager/jobs',
  'admin.schoolar-service-signatories': '/admin/schoolar-service-manager/signatories',
  'admin.support-document-manager': '/admin/support-document-manager',
  'admin.logos-manager': '/admin/logos-manager',
  'admin.blob-manager': '/admin/blob-manager',
  'admin.campus': '/admin/campus',
  'admin.config-guidelines': '/admin/guidelines',
  'admin.informative-files-regulation': '/admin/informative-files/regulations',
  'admin.caption-config': '/admin/caption-config',
  'admin.document-type-manager': '/admin/document-type/manager',
  'admin.document-manager-of-historial': '/admin/document-type/2/documents/browser',
  'admin.document-manager-of-reportcard': '/admin/document-type/3/documents/browser',
  'admin.document-manager-of-email': '/admin/document-type/4/documents/browser',
  'admin.reports.reenrollment': '/admin/report-reenrollment',
  'additional-services.book-sale': '/additional-services/book-sale'
};
