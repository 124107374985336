import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/session/services/auth/auth.service';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from '../../../../core/config/services/config.service';
import { MessageService } from '../../../../core/services/message/message.service';
import { MatDialog } from '@angular/material/dialog';
import { TutorDialogComponent } from '../../../../modules/auth/components/tutor-dialog/tutor-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@UntilDestroy()
@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss']
})
export class LoginButtonComponent implements OnInit {

  icons = {
    user: faUserLock
  };
  sselUrl: string;

  @Input('showRegisterButton') showRegisterButton: boolean = true;
  @Input('showStudentsButton') showStudentsButton: boolean = true;
  @Input('showParentsButton') showParentsButton: boolean = true;
  @Input('flexType') flexType: string = 'initial';
  @Input('color') color: string = 'accent';
  @Input('askBeforeDispatch') askBeforeDispatch: boolean = true;
  constructor(
    private authServiceLegacy: AuthService,
    private config: ConfigService,
    private messenger: MessageService,
    private dialog: MatDialog,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) { }

  ngOnInit() {
    this.sselUrl = `${this.config.get('ssel.url')}${this.config.get('ssel.legacyLogin')}`;
  }

  login() {
    //this.authServiceLegacy.login();
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        prompt: 'select_account'
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  openReEnrollment() {
    this.authServiceLegacy.storeRequestedUrl('/school-services/re-enrollment');
    //this.authServiceLegacy.login();
    this.login();
  }

  notify() {
    return (this.askBeforeDispatch)
      ? this.dialog.open(TutorDialogComponent, {
        minWidth: '200px',
        maxWidth: '600px',
        autoFocus: false,
        id: 'tutor-message',
        data: {
          url: this.sselUrl,
          login: () => this.login()
        },
      })
      : this.login();
  }
}
