import { IUserState } from '../interfaces/iuser-state';

export const USER_STATE: IUserState  = {
  auth: {
    authenticated: false,
    username: null,
  },
  SSELToken: null,
  permissions: null,
  profile: null,
  queue: {
    canEnter: false,
    ticket: null,
  },
  picture: null
};
