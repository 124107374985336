import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserFacade } from "../../data/user/facades/user-facade";
import { Observable } from "rxjs";

@Injectable()
export class HdrInterceptor implements HttpInterceptor {
	hdrHeader: string | undefined;

	constructor(private userFacade: UserFacade) {
		this.userFacade.profile$.subscribe(profile => this.hdrHeader = profile.hdr);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (!this.hdrHeader) return next.handle(req);

		const request = req.clone({
			setHeaders: {
				InfTk: `${this.hdrHeader}`,
			},
		});
		return next.handle(request);
	}
}
