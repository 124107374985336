import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss']
})
export class FilterContainerComponent implements OnInit {

  @Input('opened') opened: boolean = false;
  @Input('enabled') enabled: boolean = true;
  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.opened = !this.opened;
  }
}
