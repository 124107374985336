import { NavItemComponent } from './../../../../../shared/layouts/components/nav-item/nav-item.component';
import { SocialServiceProgram } from '.././../../interfaces/social-service/social-service-program.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, startWith, filter } from 'rxjs/operators';

import { SocialServiceService } from '../../../services/social-service/social-service.service';
import { SocialServiceCareer } from '../../../interfaces/social-service/social-service-career.interface';
import { SocialServiceSector } from '../../../interfaces/social-service/social-service-sector.interface';
import { IUserData } from 'src/app/core/data/user/interfaces/iuser-data';
import { UserFacade } from 'src/app/core/data/user/facades/user-facade';
import { ActivityLogsService } from 'src/app/core/services/activity-logs/activity-logs.service';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfigService } from '../../../../../core/config/services/config.service'

export interface IPeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-social-service',
  templateUrl: './social-service.component.html',
  styleUrls: ['./social-service.component.scss']
})
export class SocialServiceComponent extends BaseComponent implements OnInit {

  @ViewChild('programName', { static: false }) programName;
  @ViewChild('companyName', { static: false }) companyName;
  @ViewChild('geographicalArea', { static: false }) geographicalArea;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  careerControl = new FormControl();

  public careers: SocialServiceCareer[];
  public filteredCareer: Observable<SocialServiceCareer[]>;
  public sectors: SocialServiceSector[];
  public dataSource: MatTableDataSource<SocialServiceProgram> = new MatTableDataSource();
  public displayedColumns: string[] = ["CompanyName", "ProgramId", "ProgramName", "GeographicalArea"];
  public selectedCareer: string;
  public selectedSector: string;
  public selectedProgram: string;
  public pdfUrl: string;
  private user$: Observable<IUserData>
  private pathPrefix: string;

  public StringDataFilters = {
    ProgramText: null,
    CompanyText: null,
    GeoText: null,
  };

  public ShowProgramDetail: boolean = false;
  public ProgramsReady: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private socialServiceService: SocialServiceService,
    private activityLogsService: ActivityLogsService,
    private userFacade: UserFacade,
    private configService: ConfigService
  ) {
    super();
    this.pathPrefix = '';
  }

  ngOnInit() {
    this.user$ = this.userFacade.profile$;
    this.user$.pipe(first()).pipe(untilDestroyed(this)).subscribe(profile => {
      if (profile.isStudent) {
        this.activityLogsService.activityStart(profile.id, 'ServicioSocial', 'Consulta').pipe(untilDestroyed(this)).subscribe((data: any) => { });
      }
    });
    this.route.data.pipe(untilDestroyed(this)).subscribe((data: any) => {
      if (data.pathPrefix) {
        this.pathPrefix = data.pathPrefix;
      }
    });

    this.route.url.pipe(untilDestroyed(this)).subscribe((params: UrlSegment[]) => {
      if (params[0].path === 'sector' && params[2].path === 'career') {
        this.selectedSector = params[1].path;
        this.selectedCareer = params[3].path;
        this.socialServiceService.getPrograms(this.selectedCareer, this.selectedSector).pipe(untilDestroyed(this)).subscribe(data => {
          // this.elementData = data;
          this.dataSource.data = data;
          //this.dataSource.filterPredicate = this.createFilter();
        });
      }
    });
    this.socialServiceService.getCareers().pipe(untilDestroyed(this)).subscribe(data => {
      this.careers = data.sort(this.compareToSort);
      this.filteredCareer = this.careerControl.valueChanges
        .pipe(startWith(''), map(value => this.FilterSectors(value))
        );
      this.user$.pipe(first()).pipe(untilDestroyed(this)).subscribe(profile => {
        if (profile.isStudent) {
          this.selectedCareer = profile.career.careerId;
        }
      });
    });
    this.socialServiceService.getSectors().pipe(untilDestroyed(this)).subscribe(data => {
      this.sectors = data;
    });
    this.pdfUrl = `${this.socialServiceService.getPdfUrl()}?${this.configService.get('storage.blob.signature')}`;
  }

  compareToSort(a: SocialServiceCareer, b: SocialServiceCareer) {
    if (a['career-sep-name'] < b['career-sep-name']) {
      return -1;
    }
    if (a['career-sep-name'] > b['career-sep-name']) {
      return 1;
    }
    return 0;
  }

  compareProgramSort(a: SocialServiceProgram, b: SocialServiceProgram) {
    if (a.CompanyName + a.ProgramName < b.CompanyName + b.ProgramName) {
      return -1;
    }
    if (a.CompanyName + a.ProgramName > b.CompanyName + b.ProgramName) {
      return 1;
    }
    return 0;
  }

  FilterSectors(value: any): SocialServiceCareer[] {
    console.log(value);
    if (value) {
      return this.careers.filter(option => option['career-sep-name'].toLowerCase().includes(value.toLowerCase()))
    }
    else {
      return this.careers;
    }
  }

  getPrograms(event: Event): any {
    if (!this.selectedSector || !this.selectedCareer) {
      return;
    }
    this.socialServiceService.getPrograms(this.selectedCareer, this.selectedSector).pipe(untilDestroyed(this)).subscribe(data => {
      this.dataSource.data = data.sort(this.compareProgramSort);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.setupFilter();
    });
  }

  openProgram(program: SocialServiceProgram): void {
    this.selectedProgram = program.ProgramId;
    this.ShowProgramDetail = true;
  }

  closeProgram(data: boolean): void {
    this.selectedProgram = "";
    this.ShowProgramDetail = false;
  }

  setupFilter() {
    return (d: SocialServiceProgram, filter: string) => {
      const Model = JSON.parse(filter);
      var ProgramValid = true;
      var CompanyValid = true;
      var GeoValid = true;

      if (Model.ProgramText) {
        const textToSearch = d['ProgramName'] && d['ProgramName'].toLowerCase() || '';
        ProgramValid = textToSearch.indexOf(Model.ProgramText.toLowerCase()) !== -1;
      }
      if (Model.CompanyText) {
        const textToSearch = d['CompanyName'] && d['CompanyName'].toLowerCase() || '';
        CompanyValid = textToSearch.indexOf(Model.CompanyText.toLowerCase()) !== -1;
      }
      if (Model.GeoText) {
        const textToSearch = d['GeographicalArea'] && d['GeographicalArea'].toLowerCase() || '';
        GeoValid = textToSearch.indexOf(Model.GeoText.toLowerCase()) !== -1;
      }

      return GeoValid && CompanyValid && ProgramValid;
    };
  }

  applyFilter(filterValue: string, column: string) {
    //this.StringDataFilters[column] = filterValue;
    this.dataSource.filter = JSON.stringify({
      ProgramText: this.programName.nativeElement.value,
      CompanyText: this.companyName.nativeElement.value,
      GeoText: this.geographicalArea.nativeElement.value,
    });
  }

  resetFilters() {
    this.companyName.nativeElement.value = '';
    this.programName.nativeElement.value = '';
    this.geographicalArea.nativeElement.value = '';

    this.dataSource.filter = JSON.stringify({
      ProgramText: '',
      CompanyText: '',
      GeoText: '',
    });
  }

  careerPad(careerId: string): string {
    return careerId.padStart(4, '0');
  }

  getOptionText(career: SocialServiceCareer) {
    if (career) {
      return career['career-sep-name'];
    }
    else {
      return null;
    }
  }
}
