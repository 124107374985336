import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  /**
   * @param {string} key
   * @returns {boolean}
   */
  has(key: string) {
    return !!(this.storage.get(key));
  }

  /**
   * Get cached data
   * @param key
   * @param remove
   * @returns {any}
   */
  get<Type = any>(key, remove: boolean = true): Type {
    const data: {
      expires: Date,
      value: Type
    } = this.storage.get(key);

    if (!data) {
      return null;
    }

    const expires = new Date(data.expires);
    const value = data.value;

    const now = new Date();
    if (expires && expires.getTime() < now.getTime() && remove) {
      this.storage.remove(key);
      return null;
    }

    return value;
  }

  /**
   * Save data in local storage for cache
   * @param key
   * @param value
   * @param {number} ttl time in milliseconds
   */
  set<Type = any>(key: string, value: Type, ttl: number = null) {
    let expires = null;

    if (ttl) {
      const now = new Date();
      expires = now.getTime() + (ttl);
    } 

    this.storage.set(key, { expires, value });
  }

  clear(filter: string) {
    let i = 0;
    const keysToEliminate = [];
    for (i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.indexOf(filter) !== -1 ) {
        keysToEliminate.push(key);
      }
    }
    keysToEliminate.forEach(key => {
      // console.log(key);
      localStorage.removeItem(key);
    });
  }

  clearAll() {
    let i = 0;
    const keysToEliminate = [];
    for (i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
        keysToEliminate.push(key);
    }
    keysToEliminate.forEach(key => {
      // console.log(key);
      localStorage.removeItem(key);
    });
  }
}
