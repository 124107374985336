import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ApplicationReducer } from './application.reducer';
import { AppFacade } from './facades/app-facade';
import { metaReducers } from './hydration-reducer';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    //StoreModule.forRoot(appReducers, { initialState: MAIN_STATE }),
    StoreModule.forRoot({'application': ApplicationReducer}, { metaReducers })
  ],
  providers:[
    AppFacade
  ]
})
export class ApplicationStoreModule { }
