import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserFacade } from '../../data/user/facades/user-facade';
import { UserService } from '../../data/user/services/user.service';
import { MessageService } from '../../services/message/message.service';

@UntilDestroy()
@Injectable()
export class SSELInitializer {

  $profile = this.userFacade.profile$;
  constructor(
    private userFacade: UserFacade,
    private userService: UserService,
    private messenger: MessageService,
  ) {}

  init() {
    this.$profile
    .pipe(untilDestroyed(this)).subscribe(profile =>  {
      if (profile && profile.isStudent) {
        this.getToken(profile.id);
      }
    });
  }

  getToken(userId: string) {
    this.userFacade.setSSELToken(null);
    this.userService.getSSELToken(userId)
    .pipe(untilDestroyed(this)).subscribe(
      response => this.userFacade.setSSELToken(response.data.sselSecret),
        response => this.handleError(response)
    );
  }

  handleError(response) {
    (response.error && response.error.message)
      ? this.messenger.error(response.error.message, response.error.error)
      : this.messenger.error('Ocurrió un error al obtener el token SSEL');
  }
}
