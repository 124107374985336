import { Injectable } from "@angular/core";
import { IDocumentMetadataModel } from "src/app/core/interfaces/generic-document/idocument-metadata.model";
import { IDocument } from "../interfaces/idocument";
import { Documento } from "../models/document";


@Injectable()
export class DocumentFactory {
    /**
     * Build a navigation node
     * @param properties
     */
    create(properties: IDocumentMetadataModel): IDocument {
        const node = new Documento();
        const { documentId, name, description, parameters, sections, payment, documentType } = properties;

        node.documentId = documentId;
        node.name = name;
        node.description = description;
        node.payment = payment;
        node.parameters = parameters;
        node.sections = sections;
        node.documentType = documentType;

        return node;
    }

}
