import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { BankBridgeNewRoutingModule } from './bank-bridge-routing.v2.module';
import { RequestBankPaymentNewComponent } from './pages/request-payment/request-bank-payment.v2.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BankBridgeNewRoutingModule
  ],
  declarations: [
    RequestBankPaymentNewComponent
  ],
  entryComponents: [
  ],
  exports: [
    RequestBankPaymentNewComponent
  ]
})
export class BankBridgeNewModule { }
