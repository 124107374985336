import { Routes } from "@angular/router";
import { MpRequestPaymentComponent } from "./pages/request-payment/mp-request-payment.component";

export const MERCADO_PAGO_ROUTES: Routes = [
  {
    path: '',
    data: {
      title: 'Mercadopago',
    },
    component: MpRequestPaymentComponent
  }
];
