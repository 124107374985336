import { Component, Input, OnInit, ViewEncapsulation, ViewChild, Renderer2, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { INavigationNode, INavigationNodeModel } from '../../../../core/data/navigation/interfaces/inavigation-node';
import { NavigationFacade } from '../../../../core/data/navigation/facades/navigation-facade';
import { ActionType } from '../../../../core/data/navigation/interfaces/inavigation-node-data';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { SidenavService } from 'src/app/core/services/sidenav/sidenav.service';
import { onSideNavChange, animateText, animateFadeInOut } from 'src/app/ui/animations/animations';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MenuMessageComponent } from '../menu-message/menu-message.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  animations: [animateText, animateFadeInOut]
})
export class NavItemComponent implements OnInit, AfterViewChecked {

  actionTypes = ActionType;
  @Input('node') public node: INavigationNodeModel;
  @Input('parent') public parent: NavItemComponent;
  rules: any;

  extend$ = this.sidenavExtendService.extend$;

  loadMobileContent = false;
  isExtended = false;

  isHandset$: Observable<boolean> =
    this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.TabletPortrait,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset])
      .pipe(map(result => result.matches));


  modulesList: Array<any>;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  levelOneProp: any;
  levelTwoProp: any;
  levelThreeProp: any;
  buttonProp: any;

  // 1 menu extendido y no mobile
  // 2 menu no extendido y no mobile
  // 3 menu no mobile
  displayContent: number = 0;

  show = false;

  @ViewChild('levelOneTrigger') levelOneTrigger: any;
  @ViewChild('button') button: any;

  constructor(
    private router: Router,
    private navigationFacade: NavigationFacade,
    private messageService: MessageService,
    private mediaService: MediaObserver,
    private sidenavService: SidenavService,
    private sidenavExtendService: SidenavExtendService,
    private breakpointObserver: BreakpointObserver,
    private ren: Renderer2,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.extend$.pipe(untilDestroyed(this)).subscribe(isExtended => {
      this.isExtended = isExtended;
      if (!this.isExtended && this.parent) {
        this.close();
      }
      this.selectComponent();
    });
    this.mediaService.asObservable().pipe(untilDestroyed(this)).subscribe((changes: MediaChange[]) => {
      changes.forEach((change:MediaChange) =>{
        this.loadMobileContent = (change.mqAlias === 'xs' || change.mqAlias === 'sm');
      });
      if (this.loadMobileContent) {
        this.sidenavExtendService.extend();
      }
      this.selectComponent();
    });
  }

  ngAfterViewChecked() {
    this.show = true;
    if (this.node.hasStyle()) {
      let ele = document.getElementById(this.node.id);
      if (ele != null && typeof ele !== 'undefined')
        ele.setAttribute('style', this.node.getStyle());
    }
  }

  selectComponent() {
    if (!this.loadMobileContent && this.isExtended) {
      this.displayContent = 1;
    }
    else if (!this.loadMobileContent && !this.isExtended) {
      if (this.node.hasChildren()) {
        this.displayContent = 3;
      }
      else {
        this.displayContent = 2;
      }
    }
    else if (this.loadMobileContent) {
      this.displayContent = 4;
    }
    else {
      this.displayContent = 0;
    }
    //console.log(`diplayContent: ${this.displayContent} for ${this.node.text} who hasChildren: ${this.node.hasChildren()} 'cause loadMobileContent: ${this.loadMobileContent} and isExtended: ${this.isExtended}`);
  }

  handleClick(event: Event) {
    if (this.node.isDisabled())
      return;

    if (this.node.isMessage()) {
      this.close();
      return this.message();
    }

    if (this.node.hasChildren()) {
      event.stopPropagation();
      return this.node.open();
    }
    this.close();
    return this.navigate();
  }

  navigate() {
    if (this.node.hasExternalUrl()) {
      return window.open(this.node.getUrl(), this.node.getTarget());
    }
    this.navigationFacade.setCurrent(this.node.config);
    return this.router.navigate([this.node.getUrl()]);
  }

  close() {
    this.node.close();
    if (this.parent) {
      this.parent.close();
    }
  }

  back(event: Event) {
    event.stopPropagation();
    this.node.close();
  }

  message() {
    if (this.node.hasMessage()) {
      //this.messageService.info(this.node.getMessage());
      this.openMessage(this.node.getMessage());
    }
  }

  openMessage(message: string) {
    const dialogRef = this.dialog.open(MenuMessageComponent, {
      minWidth: '30%',
      maxWidth: '80%',
      width: 'auto',
      data: { message: message }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(result => {

    });
  }


  buttonEnter(trigger, button) {
    this.levelOneProp = trigger;
    if (this.node.children.length > 0) {
      setTimeout(() => {
        if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
          this.prevButtonTrigger.closeMenu();
          this.prevButtonTrigger = trigger;
          this.isMatMenuOpen = false;
          this.isMatMenu2Open = false;
          trigger.openMenu()
        }
        else if (!this.isMatMenuOpen) {
          this.enteredButton = true;
          this.prevButtonTrigger = trigger
          trigger.openMenu()
        }
        else {
          this.enteredButton = true;
          this.prevButtonTrigger = trigger
        }
      });
    }


  }

  buttonLeave(trigger, button) {
    this.levelOneProp = trigger;
    this.buttonProp = button;
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    if (trigger == null || typeof trigger === 'undefined') {
      trigger = this.levelOneProp;
    }
    if (button == null || typeof button === 'undefined') {
      button = this.buttonProp;
    }
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 100)
  }

  menu2enter(event) {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1, trigger2, button) {
    if (trigger1 == null || typeof trigger1 === 'undefined') {
      trigger1 = this.levelOneProp;
    }
    if (button == null || typeof button === 'undefined') {
      button = this.buttonProp;
    }
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

}
