import { IDocumentsType } from "../interfaces/idocument-type";
import { IDocumentWindowDataModel, IDocumentTrackerDataModel, IDocumentMessageDataModel, IDocumentRegulation } from "src/app/core/interfaces/generic-document/idocument-type-data";
import { IDocumentModel } from "src/app/core/interfaces/generic-document/idocument.model";

export class DocumentsType implements IDocumentsType {


    documentId: string;
    name: string;
    description: string;
    documentType: string;
    debtsRequest: string;
    regulation: IDocumentRegulation;

    tracker: IDocumentTrackerDataModel;
    documents: IDocumentModel[];
    windowLabels: IDocumentWindowDataModel;
    initialMessage: IDocumentMessageDataModel;

    getDocumentType(): string {
        return this.documentType;
    }

    getDebtsRequest(): string {
        return this.debtsRequest;
    }

    getWindowTitle(): string {
        return this.windowLabels.title;
    }

    hasDocuments(): boolean {
        return this.existDocuments();
    }

    getDocuments(): IDocumentModel[] {
        return this.documents;
    }

    documentsSize(): number {
        return this.existDocuments() ? this.documents.length : 0;
    }

    hasUniqueDocument(): boolean {
        return this.existDocuments() ? this.documents.length == 1 : false;
    }

    getUniqueDocument(): IDocumentModel {
        return this.existDocuments() ? this.documents[0] : null;
    }

    getUniqueDocumentId(): string {
        const unique = this.getUniqueDocument();
        return unique != null ? unique.documentId : null;
    }

    hasTracker(): boolean {
        return this.existTrackerInfo() ? this.tracker.avaliable : false;
    }

    getTrackerName(): string {
        return this.existTrackerInfo() ? this.tracker.name : null;
    }

    hasDocumentSelector(): boolean {
        return this.existDocuments() ? this.documents.length > 1 : false;
    }

    getSelectorLabel(): string {
        return this.windowLabels.documentListLabel;
    }

    hasInitialMessage(): boolean {
        return (this.initialMessage != null && typeof this.initialMessage != 'undefined') ? !!this.initialMessage.message : false;
    }

    getInitialMessage(): string {
        return this.hasInitialMessage() ? this.initialMessage.message : null;
    }

    getInitialMessageType(): string {
        return this.hasInitialMessage() ? this.initialMessage.type : null;
    }

    hasRegulation(): boolean {
        return (this.regulation != null && typeof this.regulation !== 'undefined') ? this.regulation.avaliable : false;
    }

    private existDocuments(): boolean {
        if (this.documents != null && typeof this.documents !== 'undefined') {
            return this.documents.length > 0;
        };
        return false;
    }

    private existTrackerInfo(): boolean {
        return this.tracker != null && typeof this.tracker !== 'undefined';
    }

}