import { Routes } from '@angular/router';

export const PAYMENTS_ROUTES: Routes = [
  {
    path: 'pay',
    loadChildren: () => import('./pay/pay.module').then(m => m.PaymentPayModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then(m => m.PaymentSummaryModule)
  }
];
