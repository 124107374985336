import { createReducer, on } from '@ngrx/store';
import { PAYMENT_STATE } from './payment.state';
import { IPaymentState, IProcessInfo } from '../interfaces/ipayment-status';
import { SET_PAYMENT, ADD_PAYMENT, REMOVE_PAYMENT, ADD_PROCESS, REMOVE_PROCESS } from './payment.actions';

const _paymentReducer = createReducer(PAYMENT_STATE,
    on(SET_PAYMENT, (state: IPaymentState, { payload, active, paymentOrderId, process }) => {
        const payment = { ...payload };
        return { ...state, payment, active, paymentOrderId, process };
    }),
    on(ADD_PAYMENT, (state: IPaymentState, { payload }) => {
        const payment = { ...state.payment, ...payload };
        const active = true;
        const paymentOrderId = payload.paymentOrderId;
        return { ...state, active, payment, paymentOrderId };
    }),
    on(REMOVE_PAYMENT, (state: IPaymentState, { payload }) => {
        const payment = <IPaymentState>{};
        const active = false;
        const paymentOrderId = '';
        const process = <IProcessInfo>{}
        return { ...payment, active, paymentOrderId, process };
    }),
    on(ADD_PROCESS, (state: IPaymentState, { payload }) => {
        const payment = state.payment;
        const active = state.active;
        const paymentOrderId = state.paymentOrderId;
        const process = payload
        return { ...state, active, payment, paymentOrderId, process };
    }),
    on(REMOVE_PROCESS, (state: IPaymentState, { payload }) => {
        const payment = state.payment;
        const active = state.active;
        const paymentOrderId = state.paymentOrderId;
        const process = <IProcessInfo>{}
        return { ...state, active, payment, paymentOrderId, process };
    })
);

export function PaymentReducer(state, action) {
    return _paymentReducer(state, action);
}