export enum NotificationTypes {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}
export interface INotificationData {
  notificationType: INotificationType;
  type: NotificationTypes;
  title: string;
  message: string;
  data: any;
  date: string;
  expires: string;
  background: boolean;
  origin: string;
}

export enum INotificationType {
  informative = 'informative',
  alert = 'alert'
}