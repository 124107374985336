import { createAction, props } from "@ngrx/store";

export const SET_TOKEN_DATA = createAction(
  "[REPORTREENROLLMENT] set SET_TOKEN_DATA",
  props<{ report: any }>()
);

export const REMOVE_DATA = createAction(
  '[REPORTREENROLLMENT] REMOVE DATA data',
  props<{ report: boolean }>()
);
