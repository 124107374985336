import { createAction, props } from '@ngrx/store';
import { IDocumentState, IMetadataDoc, IRegulationState, IDocumentsState, ISetSessionActive, ISetDocumentId, ISetDocumentType } from '../interfaces/idocument-status';

export const SET_DOCUMENT = createAction('[DOCUMENT] set init Document data', props<{ payload: IDocumentState }>());

export const SET_METADATA = createAction('[DOCUMENT] set metada Document data', props<{ payload: IMetadataDoc }>());

export const SET_DOCUMENT_ID = createAction('[DOCUMENT] set Id Document data', props<{ payload: ISetDocumentId }>());

export const SET_DOCUMENT_TYPE = createAction('[DOCUMENT] set Type Document data', props<{ payload: ISetDocumentType }>());

export const SET_DOCUMENT_REGULATION = createAction('[DOCUMENT] add Document regulation data', props<{ payload: IRegulationState }>());

export const SET_DOCUMENTS = createAction('[DOCUMENT] set documents of document', props<{ payload: IDocumentsState[] }>());

export const SET_SESSION = createAction('[DOCUMENT] set session of document', props<{ payload: ISetSessionActive }>());

export const REMOVE_DOCUMENT = createAction('[DOCUMENT] remove document data', props<{ payload: any }>());
