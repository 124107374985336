import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserFacade } from '../../user/facades/user-facade';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IImpersonateState } from '../interfaces/iimpersonate-state';
import { IUserData } from '../../user/interfaces/iuser-data';
import { filter, first } from 'rxjs/operators';
import { getState } from '../impersonate.selectors';
import { SET_PROFILE_BACKUP, SET_PICTURE_BACKUP, SET_ACTIVE, SET_INACTIVE } from '../redux/impersonate.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class ImpersonateFacade {

  private state$: Observable<IImpersonateState> = this.store.select(getState);

  backupState$: Observable<any> = this.state$.pipe(select(state => state.backup));

  isActive$: Observable<boolean> = this.state$.pipe(select(state => state.isActive));

  backup$: Observable<any> = this.backupState$.pipe(filter(backup => !!backup));

  backUpSubscription: Subscription = null;

  pictureBackup$: Observable<string> = this.state$.pipe(
    select(state => state.pictureBackup),
    filter(picture => !!picture)
  );

  constructor(
    private store: Store<IImpersonateState>,
    private user: UserFacade,
    private router: Router
  ) { }

  setBackup(data: any) {
    this.store.dispatch(SET_PROFILE_BACKUP({ payload: data }));
  }

  setPictureBackup(data: any) {
    this.store.dispatch(SET_PICTURE_BACKUP({ payload: data }));

  }

  impersonate(profile: IUserData) {
    /**
     * First get current profile and make a backup
     */
    this.user.profile$
      .pipe(first())
      .pipe(untilDestroyed(this)).subscribe(currentProfile => {
        this.setBackup(currentProfile);
      });

    this.user.picture$.pipe(untilDestroyed(this)).subscribe((picture: string) => {
      this.setPictureBackup(picture);
    });
    /**
     * After backup is done, then set impersonated profile and notify is active
     * and redirect to home
     */
    this.backUpSubscription = this.backup$
      .pipe(first())
      .pipe(untilDestroyed(this)).subscribe(() => {
        this.user.setProfile(profile);
        this.user.setProfilePicture(null);
        this.store.dispatch(SET_ACTIVE());
        this.unsuscribe();
        this.router.navigate(['/user/profile']);
      });
  }

  unsuscribe() {
    if (this.backUpSubscription) {
      //this.backUpSubscription.unsubscribe();
    }
  }

  restore() {
    this.backUpSubscription = this.backup$
    .pipe(first())
      .pipe(untilDestroyed(this)).subscribe(backup => {
        this.pictureBackup$.pipe(untilDestroyed(this), first()).pipe(untilDestroyed(this)).subscribe((picture: string) => {
          this.user.setProfilePicture(picture);
        });
        this.user.setProfile(backup);
        this.user.setSSELToken(null);
        this.setBackup(null);
        this.setPictureBackup(null);
        this.store.dispatch(SET_INACTIVE());
        this.unsuscribe();
        this.router.navigate(['/user/profile']);
      });
  }
}
