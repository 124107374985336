import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentDataService {

  private paymentEndpoints: {
    requestList: Endpoint,
    savePaymentRequest: Endpoint,
    validatePayment: Endpoint,
    requestPaymentProcess: Endpoint,
    requestPaymentStatus: Endpoint,
    bankbridge: {
        get: Endpoint,
        save: Endpoint
    }
  };

  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService) {
    this.paymentEndpoints = this.endpointsService.payment;
  }

  get(paymentId): Observable<any> {
    this.paymentEndpoints.bankbridge.get.useWildcardParams({ paymentId });
    this.paymentEndpoints.bankbridge.get.setCache(0);
    return this.http.get(this.paymentEndpoints.bankbridge.get.url);
  }

  save(paymentId, resultIndicator, status): Observable<any> {
    const body = { id: paymentId, resultIndicator: resultIndicator, status: status };
    this.paymentEndpoints.bankbridge.save.setCache(0);
    return this.http.post(this.paymentEndpoints.bankbridge.save.url, body);
  }

}
