import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ILogEventButtonPressed } from 'src/app/core/interfaces/ilog-event-button-pressed';
import { ActivityLogsService } from 'src/app/core/services/activity-logs/activity-logs.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-document-viewer-pdf',
  templateUrl: './document-viewer-pdf.component.html',
  styleUrls: ['./document-viewer-pdf.component.scss']
})
export class DocumentViewerPdfComponent implements OnInit {

  @Input('name') name: string;
  @Input('pdfContent') pdfContent: string;
  @Input('timeBeforeRender') timeBeforeRender: number;
  @Input('download') download: boolean = false;
  @Input('orderId') orderId: string;
  @Input('message') message: string = '';
  @Input('detailMessage') detailMessage: any[] = [];
  @Output('onFileNotAvaible') onFileNotAvaible: EventEmitter<boolean> = new EventEmitter();
  @Output('buttonPressed') buttonPressed: EventEmitter<ILogEventButtonPressed> = new EventEmitter<ILogEventButtonPressed>();

  heigth: string;

  loadMobileContent = true;
  showView: boolean = false;
  base64src: string;

  constructor(
    private activityLogsService: ActivityLogsService,
    private messageService: MessageService
  ) { }

  ngOnInit() {

    if (this.pdfContent != null && typeof this.pdfContent !== 'undefined' && this.pdfContent != '') {
      this.base64src = this.pdfContent;
      this.showView = true;
      setTimeout(() => {
        this.calculateHeigth();
        this.enableButtons();
      }, 50);
    } else {
      this.messageService.warning(((this.message != null && typeof this.message !== 'undefined' && this.message != '') ? this.message : 'No es posible mostrar el documentos solicitado'),
        this.detailMessage, () => this.onFileNotAvaible.emit(true));
    }
  }

  enableButtons() {
    setTimeout(() => {
      try {
        setTimeout(() => {
          (<any>window).PDFViewerApplication.setTitleUsingUrl(this.name);
        }, this.timeBeforeRender);
      } catch (error) { }
    }, 0);
  }

  calculateHeigth() {
    var element = document.getElementById('toPrint');
    if (element) {
      this.heigth = element.clientHeight + 'px';
      if (this.loadMobileContent) {
        this.heigth = '500px';
      }
    }
  }

  onEventButtonPressed(event: ILogEventButtonPressed) {
    if (this.orderId != null && typeof this.orderId !== 'undefined' && this.orderId != '') {
      this.activityLogsService.activityLogs(this.orderId, event.type).pipe(untilDestroyed(this)).subscribe((data: any) => { });
    }
    this.buttonPressed.emit(event);
  }

}
