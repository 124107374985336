import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationFacade } from '../../../../core/data/notification/facades/notification-facade';
import { INotificationData } from '../../../../core/data/notification/interfaces/inotification-data';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
//import { PushNotificationService } from 'ngx-push-notifications';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationCenterComponent implements OnInit {

  all$: Observable<INotificationData[]> = this.notificationFacade.all$.pipe(map(n => n.filter(notif => notif.notificationType != 'alert')));
  notificationSubscription: Subscription;
  constructor(
    //private pushNotificationService: PushNotificationService,
    private notificationFacade: NotificationFacade
  ) { }

  ngOnInit() {
    this.notificationSubscription = this.all$.pipe(untilDestroyed(this)).subscribe((notifications: INotificationData[]) => {
      const lastNotification = notifications[notifications.length - 1];
      if (lastNotification) {

        // this.pushNotificationService.create(lastNotification.message).pipe(untilDestroyed(this)).subscribe(notif => {
        //   if (notif.event.type === 'show') {
        //     console.log('onshow');
        //   }
        //   if (notif.event.type === 'click') {
        //     console.log('click');
        //   }
        //   if (notif.event.type === 'close') {
        //     console.log('close');
        //   }
        // });
      }
    });


  }

}
