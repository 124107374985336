import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MessageDialogComponent } from './layouts/components/message-dialog/message-dialog.component';
import { UIModule } from '../ui/ui.module';
import { LayoutsModule } from './layouts/layouts.module';
import { FormModule } from './form/form.module';
import { PdfDialogComponent } from './layouts/components/pdf-dialog/pdf-dialog.component';
import { TransactionTrackerComponent } from './layouts/components/transaction-tracker/transaction-tracker.component';
//import { PinchZoomModule } from 'ngx-pinch-zoom';
import { DndModule } from 'ngx-drag-drop';
import { OtpConfirmationComponent } from './layouts/components/otp-confirmation/otp-confirmation.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigService } from '../core/config/services/config.service';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SafePdfPipe } from './pipes/safePdf.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { MenuMessageComponent } from './layouts/components/menu-message/menu-message.component';
import { FileSaverModule } from 'ngx-filesaver';

export function HttpLoaderFactory(http: HttpClient, config: ConfigService) {
  console.log(config.get('globalization.url', ''));
  return new TranslateHttpLoader(http, config.get('globalization.url', ''), '');
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    let val = '';
    if (params != null && typeof params !== 'undefined') {
      if (params.interpolateParams != null && typeof params.interpolateParams !== 'undefined') {
        val = (params.interpolateParams['value'] != null && typeof params.interpolateParams['value'] !== 'undefined') ? params.interpolateParams['value'] : '';
      }
    }
    return val;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    LayoutsModule,
    FormModule,
    //PinchZoomModule,
    DndModule,
    ClipboardModule,
    FileSaverModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ConfigService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    })
  ],
  exports: [
    RouterModule,
    UIModule,
    LayoutsModule,
    FormModule,
    //PinchZoomModule,
    DndModule,
    TranslateModule,
    ClipboardModule,
    FileSaverModule
  ],
  entryComponents: [
    OtpConfirmationComponent,
    MessageDialogComponent,
    PdfDialogComponent,
    TransactionTrackerComponent,
    MenuMessageComponent
  ],

})
export class SharedModule { }

