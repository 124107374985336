import { ISupportingDocumentSection, ISupportingDocumentSectionModel } from '../interfaces/isupporting-document-section';
import { Injectable } from '@angular/core';
import { SupportingDocumentSection } from '../models/supporting-document-section';

@Injectable()
export class SectionFactory {
  create(properties: ISupportingDocumentSection): ISupportingDocumentSectionModel {
    const section = new SupportingDocumentSection();
    section.sectionId = properties.sectionId;
    section.name = properties.name;
    section.type = properties.type;
    section.content = properties.content;
    section.order = properties.order;
    section.parameters = properties.parameters ? properties.parameters : [];
    return section;
  }
}
