import { IDocumentTypeMetadaModel } from "src/app/core/interfaces/generic-document/idocument-type-data";
import { IDocumentsType } from "../interfaces/idocument-type";
import { DocumentsType } from "../models/document-type";
import { Injectable } from "@angular/core";


@Injectable()
export class GenericDocumentFactory {
  /**
   * @method create Create an IDocumentsType object from IDocumentTypeMetadaModel properties
   * @param properties: IDocumentTypeMetadaModel
   * @returns IDocumentsType
   */
  create(properties: IDocumentTypeMetadaModel): IDocumentsType {
    const node = new DocumentsType();
    const { name, description, documentId, tracker, documents, documentType, windowLabels: window, initialMessage, regulation, debtsRequest } = properties;
    node.tracker = tracker;
    node.documents = documents;
    node.documentType = documentType;
    node.windowLabels = window;
    node.initialMessage = initialMessage;
    node.name = name;
    node.description = description;
    node.documentId = documentId;
    node.regulation = regulation;
    node.debtsRequest = debtsRequest;

    return node;
  }

}