import { Routes } from '@angular/router';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

export const LEGAL_ROUTES: Routes = [
  {
    path: 'terms-of-service',
    pathMatch: 'full',
    component: TermsOfServiceComponent
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: PrivacyPolicyComponent
  },
];
