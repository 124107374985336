import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IChatbotState, IAzureAD, IAuthBot } from '../interfaces/ichatbot-status';
import { getState, getStateAvailable } from '../chatbot.selectors';
import { SET_CHATBOT, ADD_CHANELID, ADD_AZUREAD, ADD_AUTHBOT, REMOVE_CHATBOT } from '../redux/chatbot.actions';

@Injectable()
export class ChatbotFacade {

  private state$: Observable<IChatbotState> = this.store.select(getState);

  public active$: Observable<boolean> = this.store.select(getStateAvailable);

  chatbot$: Observable<IChatbotState> = this.state$.pipe(
    select(state => state),
    filter(chatbot => !!chatbot && chatbot.active)
  );

  constructor(private store: Store<IChatbotState>) { }

  setChatbot(active: boolean) {
    this.store.dispatch(SET_CHATBOT({ payload: active }));
  }

  setChanelId(chanelId: string) {
    this.store.dispatch(ADD_CHANELID({ payload: chanelId }));
  }

  setAzureAD(azureAD: IAzureAD) {
    this.store.dispatch(ADD_AZUREAD({ payload: azureAD }));
  }

  setAuthBot(authBot: IAuthBot) {
    this.store.dispatch(ADD_AUTHBOT({ payload: authBot }));
  }

  removeChatBot(active: boolean) {
    this.store.dispatch(REMOVE_CHATBOT({ payload: active }));
  }
}
