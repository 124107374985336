import { Component, Input, OnInit } from '@angular/core';
import { IPaymentsModel } from '../../../interfaces/ipayment-data-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-positive-balance',
  templateUrl: './positive-balance.component.html',
  styleUrls: ['./positive-balance.component.scss']
})
export class PositiveBalanceComponent implements OnInit {
  @Input() paymentModel : IPaymentsModel;

  constructor() { }

  ngOnInit() {
  }

}
