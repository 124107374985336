import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-unitec-logo',
  templateUrl: './unitec-logo.component.html',
  styleUrls: ['./unitec-logo.component.scss']
})
export class UnitecLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
