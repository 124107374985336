import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-reset-field',
  templateUrl: './reset-field.component.html',
  styleUrls: ['./reset-field.component.scss']
})
export class ResetFieldComponent implements OnInit {
  @Input('field') field: FormControl;
  @Output('reset') reset: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  click(event: Event) {
    event.stopPropagation();
    this.field.reset();
    this.reset.emit();
  }
}
