import { Routes } from '@angular/router';
import { RestoreProcedureComponent } from './pages/restore-procedure/restore-procedure.component';

export const RESTORE_PROCEDURE_ROUTES: Routes = [
  {
    path: '',
    data: {
      title: 'Restaurar procedimiento',
    },
    component: RestoreProcedureComponent
  }
];
