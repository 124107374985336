import { IDocumentControl } from '../interfaces/idocument.control';
import { IDocumentMetadataModel } from '../../../interfaces/generic-document/idocument-metadata.model';
import { ISectionModel } from '../../../interfaces/generic-document/isection.model';

import { ISectionControl } from '../interfaces/isection-control';
import { DocumentParam } from './document.param';
import { IDocumentParam } from '../interfaces/idocument.param';
import { IDocumentSection } from '../interfaces/idocument.section';

import { IDocumentPrice } from '../interfaces/idocument.prices';

export class DocumentControl implements IDocumentControl {
    prices: IDocumentPrice[];
    

    metadata: IDocumentMetadataModel<ISectionModel>;
    documentParams: IDocumentParam[];
    //sectionParams: IDocumentParam[];
    selectedSections: IDocumentSection[] = [];
    //sectionIds: string[];
    //parameters: IDocumentParam[];
    sectionControls: ISectionControl[];

    get sectionParams() {
        return this.selectedSections.reduce((params, section) => [...params, ...section.parameters], []);
    }

    get parameters() {
        return [...this.documentParams, ...this.sectionParams];
    }

    get sectionIds() {
        return this.selectedSections.map(section => section.sectionId);
    }

    getSelectedSectionsByOrder(): IDocumentSection[] {
        return this.selectedSections.sort((a, b) => {
            return a.order - b.order;
        });
    }

    setDefaultSections(sections: IDocumentSection[]) {
        sections.forEach(section => this.addSection(section));
    }

    hasParamConfig(id: string) {
        return !!this.getParamConfig(id).parameterId;
    }

    getParamConfig(id: string) {
        const parameter = [...this.documentParams, ...this.sectionParams].find(param => param.parameterId === id);
        return !!parameter ? parameter : new DocumentParam();
    }

    removeMultipleSections(sections: IDocumentSection[]) {
        sections.map(section => this.removeSection(section));
    }

    addSection(section: IDocumentSection) {
        const found = this.selectedSections.find(selectedSection => selectedSection.sectionId === section.sectionId);
        if (!found) {
            this.selectedSections = [...this.selectedSections, section];
        }
    }

    removeSection(section: IDocumentSection) {
        const found = this.selectedSections.find(selectedSection => selectedSection.sectionId === section.sectionId);
        if (found) {
            const index = this.selectedSections.indexOf(found);
            this.selectedSections.splice(index, 1);
        }
    }

    setPrices(prices: IDocumentPrice[]): void {
        prices.forEach(p => {
            this.setPrice(p);
        })
    }

    getPrices(): IDocumentPrice[] {
        throw new Error("Method not implemented.");
    }

    setPrice(price: IDocumentPrice) {
        this.getParamConfig(price.parameter).addPrice(price);
    }

}