import { ActionReducer, INIT, MetaReducer, UPDATE } from "@ngrx/store";
import { IMainState } from "./interfaces/imain-state";

export const APP_STATE_KEY = "_app_state";

export const hydrationMetareducer = (
	reducer: ActionReducer<IMainState>
): ActionReducer<IMainState> => {
	return (state, action) => {
		//console.log(action);
		if (action.type === INIT || action.type === UPDATE) {
			const storageValue = localStorage.getItem(APP_STATE_KEY);
			//console.log('storageValue', storageValue);
			if (storageValue) {
				try {
					return JSON.parse(storageValue);
				} catch {
					localStorage.removeItem(APP_STATE_KEY);
				}
			}
		}
		const nextState = reducer(state, action);
		//console.log('nextState', nextState);
		localStorage.setItem(APP_STATE_KEY, JSON.stringify(nextState));
		return nextState;
	};
};

export const metaReducers: MetaReducer[] = [
	hydrationMetareducer
]
