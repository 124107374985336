import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NotificationFacade } from 'src/app/core/data/notification/facades/notification-facade';
import { INotificationData } from 'src/app/core/data/notification/interfaces/inotification-data';
import { UserFacade } from 'src/app/core/data/user/facades/user-facade';
import { IUserData } from 'src/app/core/data/user/interfaces/iuser-data';
import { IMinResponse, IResponse } from 'src/app/core/http/interfaces/iresponse';
import { MessageService } from 'src/app/core/services/message/message.service';
import { PAYMENT_CHANNELS_CAT } from 'src/app/modules/payment/catalogs/payment-channels';
import { PaymentService } from 'src/app/modules/payment/services/payment.service';
import { IEventPyment } from '../../../bank-bridge/interfaces/ievent-payment';
import { IFlywireEventModel, IFlywireStudenData, IMercadoPagoInit } from '../../interfaces/imercadopago';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';
declare var BBMercadoPago: any;

export interface INotificationMercadoPagoData {
  mercadopagoRequestId: string;
  request: string;
  additionalData: string;
}

@UntilDestroy()
@Component({
  selector: 'app-request-mercadopago-payment',
  templateUrl: './mp-request-payment.component.html',
  styleUrls: ['./mp-request-payment.component.scss']
})
export class MpRequestPaymentComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input('mpRequestId') mpRequestId: IMercadoPagoInit;
  @Input('paymentId') paymentId: string;
  @Input('amount') amount: number;
  @Output() complete: EventEmitter<IEventPyment> = new EventEmitter();

  isEventEmitted = false;

  BBMercadoPago = BBMercadoPago;

  notificationSubscriptor: Subscription = null;
  mercadoPagoNotifications$: Observable<INotificationData> = null;
  timeOut: NodeJS.Timer = null;

  profile: IUserData;

  fatalError = false;
  private destroyed$: Subject<{}> = new Subject();
  isAsking = false;

  isMercadoPagoNotificationHere = false;

  constructor(
    private messageService: MessageService,
    private notificationFacade: NotificationFacade,
    private paymentService: PaymentService,
    private userFacade: UserFacade
  ) {
    super();
    this.mercadoPagoNotifications$ = this.notificationFacade.getLastNotificationFor('payment.mercadopago.notification');
    this.userFacade.profile$.subscribe(profile => this.profile = profile);
  }

  ngOnInit(): void {
    this.init();
    this.handleNotifications(this.mercadoPagoNotifications$, "notificationCompleted");
  }

  ngOnDestroy(): void {
    this.onDestroying();
  }

  init() {
    console.log('RequestPaymentComponent: ', this.BBMercadoPago);
    this.clearLocalStorage();
    if (this.mpRequestId == null || typeof this.mpRequestId === 'undefined' || this.mpRequestId.orderId == null) {
      this.complete.emit({ success: false, event: 'error', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago });
      return;
    }
    this.continueWithPayment();
  }

  clearLocalStorage() {
    this.isEventEmitted = false;
  }

  notificationCompleted(notificationData: INotificationMercadoPagoData) {
    console.log('MercadoPago notificationCompleted: ', notificationData);
    //return;
    this.isMercadoPagoNotificationHere = true;
    const onComplete: IEventPyment = {
      success: true, event: 'complete',
      data: <IMinResponse<any, any>>{ data: notificationData.request, additionalData: notificationData.additionalData },
      paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago
    };
    this.complete.emit(onComplete);
  }

  continueWithPayment() {
    this.pay();
  }

  pay() {
    this.BBMercadoPago.checkout(this.mpRequestId, this.mpRequestId.orderId);
    this.BBMercadoPago.onEventComplete((event: IFlywireEventModel) => {
      console.log('onEventComplete data: ', event);

      if (event.action == "BBMPCANCELLED") {
        const onCancel: IEventPyment = {
          success: false, event: 'cancel',
          data: <IMinResponse<any, any>>{ data: event.data.paymentId, additionalData: event.type },
          paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago
        };
        this.emitEvent(onCancel);
      } else if (event.action == "BBMPCOMPLETED") {
        this.timeOut = setTimeout(() => this.initRequestHandler(), 10000);
        /*const onComplete: IEventPyment = {
          success: true, event: 'complete',
          data: <IMinResponse<any, any>>{ data: event.data.paymentId, additionalData: event.type },
          paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago
        };*/
        //this.emitEvent(onComplete);
      } else if (event.action == "BBMPINCOMPLETED" || event.action == "BBMPPENDING") {
        this.initRequestHandler();
        /*const onComplete: IEventPyment = {
          success: true, event: 'complete',
          data: <IMinResponse<any, any>>{ data: event.data.paymentId, additionalData: event.type },
          paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago
        };*/
        //this.emitEvent(onComplete);
      }
    });
  }

  emitEvent(event: IEventPyment) {
    if (!this.isEventEmitted) {
      this.isEventEmitted = true;
      this.clearLocalStorage();
    }
    if (event) {
      this.complete.emit(event);
    }
  }

  initRequestHandler() {
    if (this.isAsking) {
      return;
    }
    this.isAsking = true;
    this.handleRequest('isMercadoPagoNotificationHere', this.paymentId, this.profile.id, 'notificationCompleted');
  }

  handleNotifications(notificationObserver$: Observable<INotificationData>, callback: any) {
    this.handleNotification('isMercadoPagoNotificationHere', notificationObserver$, `${this.mpRequestId.orderId}`, callback, 'Ocurrio un error en la notificación de pago');
  }

  handleNotification(flag: string, notificationObserver$: Observable<INotificationData>, requestId: string | number, cbFname: any, errorMsg: string = '') {
    this.notificationSubscriptor = notificationObserver$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((notification: INotificationData) => {
      if (!this[flag]) {
        if (notification != null && typeof notification !== 'undefined') {
          if (`${notification.data.channelId}` === `${requestId}`) {
            if (notification.type == 'success') {
              this[cbFname]({
                mercadopagoRequestId: this.mpRequestId.orderId,
                request: notification.data.request,
                additionalData: notification.data
              });
            }
            else if (notification.type == 'error') {
              this.messageService.error(errorMsg, [notification.message]);
              this[flag] = true;
              this.fatalError = true;
              this.onDestroying();
            }
          }
        }
      }
    });
  }

  handleRequest(flag: string, requestId: string, profileId: string, cbFname: string) {
    if (!this[flag]) {
      let isSuccess = true;
      this.paymentService.requestPaymentChannelStatus(requestId, profileId, `${this.mpRequestId.orderId}`, 'mercadopago')
        .pipe(untilDestroyed(this),
          finalize(() => {
            if (!isSuccess) {
              this.timeOut = setTimeout(() => this.handleRequest(flag, requestId, profileId, cbFname), 30000);
            }
            else {
              if (this.timeOut) {
                this.clearTimeout();
                this.timeOut = null;
              }
            }
          }))
        .subscribe((data: IResponse<any>) => {
          isSuccess = false;
          if (data) {
            if (data.httpStatusCode != 200) {
              if (data.message) {
                this.messageService.info(`${data.message} Si cancelaste la operación haz caso omiso de este mensaje.`, null, () => {
                  const onCancel: IEventPyment = {
                    success: false, event: 'cancel',
                    data: <IMinResponse<any, any>>{ data: '', additionalData: 'NOMESSAGE' },
                    paymentMethode: PAYMENT_CHANNELS_CAT.mercadopago
                  };
                  this.emitEvent(onCancel);
                })
              }
            } else {
              if (data.data) {
                if (Guid.isGuid(data.data)) {
                  if (!Guid.parse(data.data).isEmpty()) {
                    isSuccess = true;
                    this[cbFname]({
                      mercadopagoRequestId: this.mpRequestId.orderId,
                      request: data.data,
                      additionalData: data.additionalData
                    });
                  }
                }
              }
            }
          }


        }, (error: any) => {
          isSuccess = false;
        });
    }
  }

  private onDestroying() {
    this.destroyed$.next({});
    this.clearTimeout();
  }

  private clearTimeout() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

}
