import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configURL = 'assets/data/config.json';
  private configuration = environment;
  constructor(private http: HttpClient) { }

  getConfigUrl() {
    return this.configURL;
  }

  getAllConfig() {
    return this.configuration;
  }

  get(key: string, defaultValue: any = null): any {
    let configuration = this.configuration;
    let value = defaultValue;

    if (key.indexOf('.') !== -1) {
      const segments = key.split('.');
      segments.forEach((segment) => {
        if (configuration.hasOwnProperty(segment)) {
          configuration = configuration[segment];
          value = configuration;
        } else {
          value = defaultValue;
        }
      });
    } else {
      value = configuration.hasOwnProperty(key)
        ? configuration[key]
        : defaultValue;
    }

    return value;
  }

  load() {
    let response: string = window.localStorage.getItem('__config') as string;
    this.configuration = Object.assign({}, this.configuration, JSON.parse(response));
    Object.seal(this.configuration);
    this.loadIP(this.get('ip.url'));

    return;
    // return this.http
    //   .get(this.getConfigUrl()).toPromise().then(response => {
    //     this.configuration = Object.assign({}, this.configuration, response);
    //     Object.seal(this.configuration);
    //     this.loadIP(this.get('ip.url'));
    //   });
  }

  loadIP(url: string, cb: any = null) {
    return firstValueFrom(this.http
      .get(url)).then(response => {
        window.localStorage.setItem('__current_ip', JSON.stringify(response));
        if (cb) {
          cb();
        }
      });
  }
}
