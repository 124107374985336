import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppHttp } from './services/app-http/app-http.service';
import { EndpointsService } from './services/endpoints/endpoints.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    AppHttp,
    EndpointsService
  ]
})
export class HttpModule { }
