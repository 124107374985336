import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  protected syncEndpoints: {
    update: Endpoint,
    clean: Endpoint,
    verify: Endpoint
  };

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService) {
      this.syncEndpoints = this.endpoints.users.sync;
    }  

  public updateStudent(profileUserId: string) {
    this.syncEndpoints.update.useWildcardParams({ profileUserId });
    return this.http.post<IResponse<boolean>>(this.syncEndpoints.update, {})
      .pipe(map((response:IResponse<boolean>) => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar la actualización de datos del estudiante.')));
  }

  public cleanCacheStudent(profileUserId: string) {
    this.syncEndpoints.clean.useWildcardParams({ profileUserId });
    return this.http.get<IResponse<any[]>>(this.syncEndpoints.clean)
      .pipe(map(response => response.data));
  }

  public verifyUpdate(profileUserId: string) {
    this.syncEndpoints.verify.useWildcardParams({ profileUserId });
    return this.http.get<IResponse<boolean>>(this.syncEndpoints.verify)
      .pipe(map(response => response));
  }
}
