import { ScriptElementModel } from '../../interfaces/icreate-script';


export const ScriptBanamex: ScriptElementModel = {
  id: 'ScriptBanamex',
  src: 'bbBanamex.js',
  attributes: [
    {
      attribute: 'data-complete',
      value: 'banamexHcGlobal.completeCallback'
    },
    {
      attribute: 'data-error',
      value: 'banamexHcGlobal.errorCallback'
    },
    {
      attribute: 'data-cancel',
      value: 'banamexHcGlobal.cancelCallback'
    },
  ],
  timestampQuery: false
}

export const ScriptChatbot: ScriptElementModel = {
  id: 'ScriptChatbot',
  src: 'chatbot.js',
  attributes: [],
  timestampQuery: false
}

export const ScriptBankbridgeV2: ScriptElementModel = {
  id: 'ScriptBankbridgeV2',
  src: 'bankbridge.js',
  attributes: [],
  timestampQuery: true
}

export const ScriptFlywire: ScriptElementModel = {
  id: 'ScriptFlywire',
  src: 'flywire.js',
  attributes: [],
  timestampQuery: true
}

export const ScriptMercadoPago: ScriptElementModel = {
  id: 'ScriptMercadoPago',
  src: 'mercadopago.js',
  attributes: [],
  timestampQuery: true
}
