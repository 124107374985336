import { createAction, props } from '@ngrx/store';
import { IPaymentData, IProcessInfo } from '../interfaces/ipayment-status';

export const SET_PAYMENT = createAction('[PAYMENT] set payment data', props<{ payload: IPaymentData, active: boolean, paymentOrderId: string, process: IProcessInfo }>());

export const ADD_PAYMENT = createAction('[PAYMENT] add payment data', props<{ payload: IPaymentData }>());

export const REMOVE_PAYMENT = createAction('[PAYMENT] remove payment data', props<{ payload: IPaymentData }>());

export const ADD_PROCESS = createAction('[PAYMENT] add process data', props<{ payload: IProcessInfo }>());

export const REMOVE_PROCESS = createAction('[PAYMENT] remove process data', props<{ payload: IProcessInfo }>());
