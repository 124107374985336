import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetadataFactory } from './factories/metadata-factory';
import { SectionFactory } from './factories/section-factory';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    MetadataFactory,
    SectionFactory,
  ]
})
export class SupportingDocumentsModule { }
