import { createReducer, on } from "@ngrx/store";
import { IReportReenrollmentState } from "../interfaces/ireport-reenrollment-status";
import { REMOVE_DATA, SET_TOKEN_DATA } from "./report-reenrollment.actions";
import { REPORT_REENROLLMENT_STATE } from "./report-reenrollment.state";

const _reportReenrollmentReducer = createReducer(
  REPORT_REENROLLMENT_STATE,
  on(SET_TOKEN_DATA, (state: IReportReenrollmentState, { report }) => ({
    ...state,
    tokenData: report,
  })),
  on(REMOVE_DATA, (state: IReportReenrollmentState, { report }) => ({
    ...REPORT_REENROLLMENT_STATE,
    active: false,
  }))
);

export function ReportReenrollmentReducer(state, action) {
  return _reportReenrollmentReducer(state, action);
}
