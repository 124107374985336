import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { IReEnrollmentModel } from 'src/app/modules/school-services/pages/interfaces/ire-rnrollment-model';

@Injectable({
  providedIn: 'root'
})
export class ReRegistrationService {
  registrationListEndPoints: {
    data: Endpoint
  };

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService) {
      this.registrationListEndPoints = this.endpoints.registration;
    }

    getData(userId): Observable<any> {
      this.registrationListEndPoints.data.useWildcardParams({userId});

      return this.http.get<IResponse<IReEnrollmentModel>>(this.registrationListEndPoints.data)
        .pipe(map(response => response.data))
        .pipe(catchError(response => this.http.handleError(response, 'No fué posible cargar los datos para el registro')));
    }
}
