import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserFacade } from '../../data/user/facades/user-facade';
import { UserService } from '../../data/user/services/user.service';
import { ImpersonateFacade } from '../../data/impersonate/facades/impersonate-facade';
import { firstValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@UntilDestroy()
@Injectable()
export class UserInitializer {

  session$ = this.userFacade.session$;

  constructor(
    private userFacade: UserFacade,
    private userService: UserService,
    private impersonateFacade: ImpersonateFacade,
    private spinner: NgxSpinnerService,
  ) { }

  init() {
    this.session$
      .pipe(untilDestroyed(this)).subscribe(auth => this.loadProfile(auth.username));
  }

  loadProfile(username: string, reloadPage = false) {
    this.impersonateFacade.isActive$.subscribe((active: boolean) => {
      if (active) {
        firstValueFrom(this.userFacade.profile$).then(profile => {
          this.load(profile.id, reloadPage);
        });
        return;
      } else {
        this.load(username, reloadPage);
      }
    });
  }

  private load(username: string, reloadPage = false) {
    //console.log(`-> loadProfile(${username}, ${reloadPage})`);
    this.userFacade.setProfile(null);
    this.userService.getProfile(username)
      .pipe(untilDestroyed(this)).subscribe({
        next: profile => {
          //this.loadProfilePicture(username);
          this.userFacade.setProfile(profile);
          if (reloadPage) {
            setTimeout(() => {
              window.location.reload();
            }, 5);
          }
        }, 
        error: err =>{
          this.spinner.hide();
        }
      });
  }

  loadProfilePicture(userId: string) {
    this.userService.getProfilePicture(userId).pipe(untilDestroyed(this)).subscribe((picture: string) => {
      if (picture != null && typeof picture !== 'undefined' && picture != '') {
        this.userFacade.setProfilePicture(picture);
      }
    });
  }

  reloadProfile() {
    console.log('-> reloadProfile()');
    this.session$.pipe(untilDestroyed(this)).subscribe(auth => this.loadProfile(auth.username, true));
  }
}
