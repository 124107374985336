import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../core/data/user/services/user.service';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from '../../../../core/services/message/message.service';
import { Spinkit } from 'ng-http-loader';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  spinkit = Spinkit;
  captcha = {
    lang: 'es',
    size: 'Normal',
    theme: 'light',
    type: 'image',
  };
  form: FormGroup;
  credentials = null;
  userIcon = faUserCircle;
  trySubmit: boolean = false;
  isSubmitting: boolean = false;
  @ViewChild('captchaElem') captchaElem;
  constructor(
    private formBuilder: FormBuilder,
    private user: UserService,
    private messenger: MessageService,
  ) {
    this.form = this.formBuilder.group({
      SisAccount : new FormControl('', [Validators.required]),
      Password : new FormControl('', [Validators.required]),
      CaptchaReq : new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
  }

  handleCaptchaExpire() {}

  handleCaptchaLoad() {}

  handleCaptchaSuccess(event= null) {}

  onSubmit() {
    this.trySubmit = true;
    if (this.form.invalid) {
      return;
    }
    this.isSubmitting = true;
    this.user.requestMyUnitecAccountReset(this.form.getRawValue()).pipe(untilDestroyed(this)).subscribe(response => {
      this.handleSuccessResponse(response);
    }, response => {
      this.handleErrorResponse(response);
    });
    this.resetCaptcha();
  }

  resetCaptcha() {
    this.trySubmit = false;
    this.captchaElem.reloadCaptcha();
    this.form.get('CaptchaReq').reset();
  }

  handleSuccessResponse(response) {
    this.isSubmitting = false;
    this.credentials = response.data;
  }

  handleErrorResponse(response) {
    this.isSubmitting = false;
    (response.error && response.error.message)
      ? this.messenger.error(`${response.error.message}, clic en continuar para reintentar` ,
      response.error.error,
      () => this.retry(),
      () => {}
      )
      : this.messenger.error('No se pudo obtener los datos de la cuenta, clic en continuar para reintentar',
      [],
      () => this.retry(),
      () => {}
      );
  }

  retry() {
    this.onSubmit();
  }
}
