import { createFeatureSelector, createSelector } from "@ngrx/store";
import { INavigationState } from "../interfaces/inavigation-state";

export const selectNavigationState = createFeatureSelector<INavigationState>('navigation');

export const getState = createSelector(selectNavigationState, (state: INavigationState) => state);

export const getConfigState = createSelector(selectNavigationState, (state: INavigationState) => state.config);

export const getCurrentState = createSelector(selectNavigationState, (state: INavigationState) => state.current);
