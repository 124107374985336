export interface IPagination {
  skip: number;
  take: number;
  total?: number;
}

export const DEFAULT_PAGINATION: IPagination = {
  skip: 0,
  take: 20,
};
