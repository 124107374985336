import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { GenericDocumentFactory } from '../../document/factories/generic-document-factory';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { UserFacade } from '../../user/facades/user-facade';
import { Observable, firstValueFrom } from 'rxjs';
import { IUserData } from '../../user/interfaces/iuser-data';
import { DocumentFacade } from '../../document/facades/document-facade';
import { IDocumentState } from '../../document/interfaces/idocument-status';
import { isRouteCorrect } from '../functions/is-route-correct.function';
import { getDocumentIdFunction } from '../functions/get-document-id.function';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PROFILE_URL } from 'src/app/core/session/services/auth/auth.service';

@UntilDestroy()
@Injectable()
export class GenericDocumentCacheResolve implements Resolve<any> {

    _urlRedirect: string = PROFILE_URL;
    profile$: Observable<IUserData> = this.userFacde.profile$;
    document$: Observable<IDocumentState> = this.documentFacade.document$;
    documentActive: boolean = null;
    fromCache: boolean = false;

    constructor(
        private genericDocumentFactory: GenericDocumentFactory,
        private router: Router,
        private navigationService: NavigationService,
        private userFacde: UserFacade,
        private documentFacade: DocumentFacade
    ) {
        
    }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
        const documentTypeParam = route.params['documentType'];
        const sessionIdParam = route.params['session'];

        let isCorrect = this.isCorrect(documentTypeParam);
        if (!isCorrect) {
            this.documentFacade.setSession(false, '');
            this.documentFacade.removeDocument();
            this.router.navigate([this._urlRedirect, documentTypeParam]);
            return false;
        }

        this.document$.pipe(untilDestroyed(this)).subscribe((document: IDocumentState) => {
            this.documentActive = document.active;
            if (document.idSession != null && typeof document.idSession !== 'undefined' && document.idSession != '') {
                this.fromCache = document.idSession = sessionIdParam;
            }
        });

        if (!this.fromCache) {
            this.router.navigate([PROFILE_URL]);
            return false;
        }

        return firstValueFrom(this.document$).then((document: IDocumentState) => {
            let documentData = document.metadata.metadata;
            if (documentData) {
                return { metadata: this.genericDocumentFactory.create(documentData), fromCache: this.fromCache };
            } else {
                this.router.navigate([PROFILE_URL]);
                return false;
            }
        });
    }

    isCorrect(documentId: string): boolean {
        let result: { isCorrect: boolean, redirect: string } = isRouteCorrect(documentId, this.navigationService.getCurrentUrl());
        this._urlRedirect = result.redirect;
        return result.isCorrect;
    }

    resolverDocumentId(documentType: string): number {
        return getDocumentIdFunction(documentType);
    }
}
