import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MercadoPagoRoutingModule } from './mercadopago-routing.module';
import { MpRequestPaymentComponent } from './pages/request-payment/mp-request-payment.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    MpRequestPaymentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MercadoPagoRoutingModule
  ],
  exports: [
    MpRequestPaymentComponent
  ]
})
export class MercadoPagoModule { }
