import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonateFacade } from './facades/impersonate-facade';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ImpersonateFacade,
  ]
})
export class ImpersonateModule { }
