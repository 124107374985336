import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingFacade } from './facades/billing-facade';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    BillingFacade
  ]
})
export class BillingStoreModule { }
