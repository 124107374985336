import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UnitecLogoComponent } from './components/unitec-logo/unitec-logo.component';
import { UnitecFooterComponent } from './components/unitec-footer/unitec-footer.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { BrowserUnsupportedComponent } from './components/browser-unsupported/browser-unsupported.component';
import { IFrameComponent } from './components/iframe/iframe.component';
import { RIELIFrameComponent } from './components/riel-iframe/riel-iframe.component';
import { UIModule } from '../../ui/ui.module';
import { LandingLayoutComponent } from './pages/landing-layout/landing-layout.component';
import { RouterModule } from '@angular/router';
import { NavListComponent } from './components/nav-list/nav-list.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { SiteLayoutComponent } from './pages/site-layout/site-layout.component';
import { ImpersonateBannerComponent } from './components/impersonate-banner/impersonate-banner.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { PdfDialogComponent } from './components/pdf-dialog/pdf-dialog.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { NotificationComponent } from './components/notification/notification.component';
import { BlockUIModule } from 'ng-block-ui';
import { TransactionTrackerComponent } from './components/transaction-tracker/transaction-tracker.component';
import { TransactionHistoryCenterComponent } from './components/transaction-history-center/transaction-history-center.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { ProceduresComponent } from './components/transaction-tracker/procedures/procedures.component';
import { PublicLayoutComponent } from './pages/public-layout/public-layout.component';
import {ImageEditorComponent} from './components/image-editor/image-editor.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { OtpConfirmationComponent } from './components/otp-confirmation/otp-confirmation.component';
import { TranslateComponent } from './components/translate/translate.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PreloadTrackComponent } from './components/preload-track/preload-track.component';
import { PreloadFormComponent } from './components/preload-form/preload-form.component';
import { PreloadPreviewComponent } from './components/preload-preview/preload-preview.component';
import { UnitecShieldComponent } from './components/unitec-shield/unitec-shield.component';
import { MenuNestedComponent } from './components/menu-nested/menu-nested.component';
import { DocumentViewerHtmlComponent } from '../../modules/service-window/document-generator/components/document-viewer-html/document-viewer-html.component';
import { DocumentViewerPdfComponent } from '../../modules/service-window/document-generator/components/document-viewer-pdf/document-viewer-pdf.component';
import { DocumentViewerWordComponent } from '../../modules/service-window/document-generator/components/document-viewer-word/document-viewer-word.component';
import { SafeHtmlPipe } from '../pipes/safeHtml.pipe';
import { SafePdfPipe } from '../pipes/safePdf.pipe';
import { base64ToHtml } from '../pipes/base64ToHtml.pipe';
import { FilesCampusComponent } from 'src/app/modules/admin/informative-files/components/files-campus/files-campus.component';
import { FilesSchoolComponent } from 'src/app/modules/admin/informative-files/components/files-school/files-school.component';
import { FilesCareerComponent } from 'src/app/modules/admin/informative-files/components/files-career/files-career.component';
import { FilesLevelComponent } from 'src/app/modules/admin/informative-files/components/files-level/files-level.component';
import { TicketComponent } from 'src/app/modules/payment/summary/components/ticket/ticket.component';
import { MenuMessageComponent } from './components/menu-message/menu-message.component';
import { BillingDownloadComponent } from 'src/app/modules/financial/billing/components/billing-download/billing-download.component';
import { LoadingItemComponent } from './components/loading-item/loading-item.component';
import { SecuredImageComponent } from './components/secured-image/secured-image.component';
import { DigitOnlyDirective } from '../directives/digit-only.directive';
import { LineClampComponent } from './components/line-clamp/line-clamp.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    BlockUIModule.forRoot(),
    ImageCropperModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [
    /**
     * Common components for layouts
     */
    UnitecLogoComponent,
    UnitecShieldComponent,
    UnitecFooterComponent,
    LoginButtonComponent,
    MessageDialogComponent,
    BrowserUnsupportedComponent,
    IFrameComponent,
    RIELIFrameComponent,
    NavListComponent,
    NavItemComponent,
    MenuNestedComponent,
    ImpersonateBannerComponent,
    AvatarComponent,
    ToolBarComponent,
    NotificationCenterComponent,
    NotificationComponent,
    TransactionTrackerComponent,
    OtpConfirmationComponent,
    /**
     * Layouts
     */
    LandingLayoutComponent,
    SiteLayoutComponent,
    PdfDialogComponent,
    TransactionHistoryCenterComponent,
    TransactionComponent,
    ProceduresComponent,
    PublicLayoutComponent,
    ImageEditorComponent,
    TranslateComponent,
    PreloadTrackComponent,
    PreloadFormComponent,
    PreloadPreviewComponent,

    DocumentViewerPdfComponent,
    DocumentViewerHtmlComponent,
    DocumentViewerWordComponent,

    SafeHtmlPipe,
    SafePdfPipe,
    base64ToHtml,
    DigitOnlyDirective,

    FilesCampusComponent,
    FilesSchoolComponent,
    FilesCareerComponent,
    FilesLevelComponent,

    TicketComponent,

    MenuMessageComponent,
    BillingDownloadComponent,
    LoadingItemComponent,
    SecuredImageComponent,
    LineClampComponent
  ],
  exports: [
    /**
     * Common components for layouts
     */
    UnitecLogoComponent,
    UnitecShieldComponent,
    UnitecFooterComponent,
    LoginButtonComponent,
    MessageDialogComponent,
    BrowserUnsupportedComponent,
    IFrameComponent,
    RIELIFrameComponent,
    NavListComponent,
    NavItemComponent,
    ImpersonateBannerComponent,
    AvatarComponent,
    PdfDialogComponent,
    ToolBarComponent,
    NotificationCenterComponent,
    NotificationComponent,
    TransactionTrackerComponent,
    TranslateComponent,
    LoadingItemComponent,

    /**
     * Layouts
     */
    LandingLayoutComponent,
    SiteLayoutComponent,
    PublicLayoutComponent,
    ImageEditorComponent,

    PreloadTrackComponent,
    PreloadFormComponent,
    PreloadPreviewComponent,

    DocumentViewerPdfComponent,
    DocumentViewerHtmlComponent,
    DocumentViewerWordComponent,

    SafeHtmlPipe,
    SafePdfPipe,
    base64ToHtml,
    DigitOnlyDirective,
    FilesCampusComponent,
    FilesSchoolComponent,
    FilesCareerComponent,
    FilesLevelComponent,
    TicketComponent,


    MenuMessageComponent,
    BillingDownloadComponent,
    SecuredImageComponent,
    LineClampComponent
  ]
})
export class LayoutsModule { }
