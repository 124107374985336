import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatbotFacade } from './facades/chatbot-facade';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    ChatbotFacade
  ]
})
export class ChatbotModule { }
