import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { INavigationNode } from '../../../../core/data/navigation/interfaces/inavigation-node';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { Observable, of } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent implements OnInit, AfterViewInit {

  @Input('nodes') nodes: INavigationNode[];

  extend$ = this.sidenavExtendService.extend$;

  isHandset$: Observable<boolean> =
    this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.TabletPortrait,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset])
      .pipe(map(result => result.matches));

  isMobile: Observable<boolean>;

  public constructor(
    private sidenavExtendService: SidenavExtendService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isHandset$.pipe(untilDestroyed(this)).subscribe(isHandset => {

    });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  toggle() {
    this.sidenavExtendService.toggle();
  }

}
