import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from './message/message.service';
import { StringService } from './string/string.service';
import { ImagesService } from './images/image.service';
import { NavigationService } from './navigation/navigation.service';
import { ConfirmationTokenService } from './confirmation-token/confirmation-token.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    MessageService,
    StringService,
    ImagesService,
    NavigationService,
    ConfirmationTokenService
  ]
})
export class ServicesModule { }
