import { Routes } from '@angular/router';
import { RequestBankPaymentComponent } from './pages/request-payment/request-bank-payment.component';

export const BANK_BRIDGE_ROUTES: Routes = [
  {
    path: '',
    data: {
      title: 'Bank Bridge',
    },
    component: RequestBankPaymentComponent
  }
];
